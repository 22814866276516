import Header from './common/Header';
import SideNavbar from './common/SideNavbar';
// import { useSelector } from 'react-redux';


function Layout(props: any) {
  // const isSideNavBarVisible: any = useSelector((state: any) => state.sideNavbarVisibilty);
  const showSideNavBar =()=>{
    
    // if(isSideNavBarVisible){
      return (
        <>
          <SideNavbar finalValue={props.finalValue} teamInfo={props.teamInfo} createOrUpdateContext={props.createOrUpdateContext} locationIdAndGameId={props.locationIdAndGameId}/>
        </>
      )
    // }
    
  }
  return (
 <div id="navMain" className="inactive">
   <Header/>
{showSideNavBar()}
 </div>
  );
}

export default Layout;
