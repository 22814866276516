// export const BASE_URL ="https://api.sharedeks-devtest.skycloud.co.nz/bion/"
export const BASE_URL = process.env.REACT_APP_BION_BASE_URL
export const APIS_URL = "http://127.0.0.1:"
export const PORT_GAME_SERVICE = "8080/"
export const PORT_SCORE_SERVICE = "8081/"
export const PORT_USER_SERVICE = "8082/"
export const GAME_APIS_URL =BASE_URL+"bion-game/v1"
export const SCORE_APIS_URL=BASE_URL+"bion-scores/v1"
export const USER_APIS_URL =BASE_URL+"bion-user/v1"
///question dev

export const QUES_APIS_URL =BASE_URL+"bion-question/v1/keywords"
export const QUESTION_SEARCH_APIS_URL =BASE_URL+"bion-question/v1"
export const QUESTION_TYPE_APIS_URL =BASE_URL+"bion-question/v1/questionTypes"
export const CREATE_QUES_APIS_URL =BASE_URL+"bion-question/v1"
export const QUESTION_DETAIL_APIS_URL = BASE_URL+"bion-question/v1";
export const IMPORT_QUIZ_URL=BASE_URL+"bion-datamigration/v1";
export const FINALIZE_GAME_APIS_URL =BASE_URL+"bion-game/v1/gameFinalized"
export const END_GAME_APIS_URL =BASE_URL+"bion-game/v1/gameEnded"
export const ALL_QUIZ_APIS_URL =BASE_URL+"bion-game/v1/getAllQuiz"
export const QUIZ_GET_QUES_ANS_URL =BASE_URL+"bion-question/v1/quizData"
export const PREV_TEAMS_API_URL = BASE_URL+"bion-game/v1/getTeams"


export const VALIDATE_QUIZ_CODE_API_URL = BASE_URL + "bion-question/v1/validate"
export const DELET_QUIZ_APIS_URL =BASE_URL +"bion-question/v1/deleteQuiz"
export const DELET_QUIZ_CODE_APIS_URL =BASE_URL +"bion-question/v1/deleteQuizCode"
export const GENEARTE_QUIZ_CODE_APIS_URL =BASE_URL +"bion-question/v1/generate"


// export const ALL_QUIZ_APIS_URL ="http://127.0.0.1:8080/"+"bion-game/v1/getAllQuiz"
// export const VALIDATE_QUIZ_CODE_API_URL = "http://127.0.0.1:8085/" + "bion-question/v1/validate"


// export const VALIDATE_QUIZ_CODE_API_URL = "http://127.0.0.1:8085/" + "bion-question/v1/validate"

// export const DELET_QUIZ_APIS_URL ="http://127.0.0.1:8085/"+"bion-question/v1/deleteQuiz"

// export const DELET_QUIZ_CODE_APIS_URL ="http://127.0.0.1:8085/"+"bion-question/v1/deleteQuizCode"

// export const GENEARTE_QUIZ_CODE_APIS_URL ="http://127.0.0.1:8085/"+"bion-question/v1/generate"



// export const APIS_URL ="http://127.0.0.1:8080/"
// export const GAME_APIS_URL = "http://127.0.0.1:8080/" + "bion-game/v1"
// export const PREV_TEAMS_API_URL = "http://127.0.0.1:8080/" + "bion-game/v1/getTeams"
// export const CREATE_QUES_APIS_URL = '';

// export const SCORE_APIS_URL = "http://127.0.0.1:8081/" + "bion-scores/v1"

// export const USER_APIS_URL = "http://127.0.0.1:8082/" + "bion-user/v1"
//export const QUIZ_GET_QUES_ANS_URL ="http://127.0.0.1:8085/"+"bion-question/v1/quizData"
// export const FINALIZE_GAME_APIS_URL ="http://127.0.0.1:8082/" + "bion-game/v1/gameFinalized"
// export const END_GAME_APIS_URL ="http://127.0.0.1:8082/" + "bion-game/v1/gameEnded"
///question local
// export const QUESTION_DETAIL_APIS_URL = "http://localhost:8080/"+"bion-question/v1";
// export const QUES_APIS_URL = "http://localhost:8080/" + "bion-question/v1/keywords"
// export const QUESTION_SEARCH_APIS_URL = "http://localhost:8080/" + "bion-question/v1"
// export const QUESTION_TYPE_APIS_URL = "http://localhost:8080/" + "bion-question/v1/questionTypes"
// export const CREATE_QUES_APIS_URL = "http://localhost:8080/" + "bion-question/v1"
// export const IMPORT_QUIZ_URL="http://localhost:8080/" +"bion-datamigration/v1";
// export const ALL_QUIZ_APIS_URL ="http://127.0.0.1:8080/"+"bion-game/v1/getAllQuiz" 
