import ScoreButton from "./common/ScoreButton";
import useWindowDimensions from '..//hooks/useWindowDimensions'


function ScoreCardAnswerButtons(props:any) {
  const { width, height } = useWindowDimensions();

  let buttonList = [];
  let extrasList = [];
// console.log("In ScaoreCardAnswerButtons");
  const { teamId, roundId, correct_answers, is_pp_active, is_jj_active  } = props.data;
  const {location_id, game_id, onScoreUpdate}=props;
  
  const score={
    location_id, game_id, teamId, roundId, correct_answers, is_pp_active, is_jj_active
  }

  let id = `${teamId}_${roundId}`;
  for (let i = 0; i < props.no_of_rounds; i++) {
    let title = `${(i + 1)}`;
    id = `${i + 1}_${teamId}_${roundId}`;

    buttonList.push(
      <ScoreButton
        key={i}
        id={`btn_${id}`}
        roundId ={roundId}
        title={title}
        answer={props.answer?.questionList[i]?.options[0]?.optionDescription}
        answerId={i+1}
        isMarkedCorrect={correct_answers.includes(title)}
        type="score"
        score={score}
        teamName={props.teamName}
        onScoreUpdate={onScoreUpdate}
        forceUpdateComp={props.forceUpdateComp}
      />
    );
  }
if(roundId !== 5){
  extrasList.push(
    <ScoreButton
      key={`pp_${id}`}
      id={`pp_${id}`}
      roundId ={roundId}
      title="PP"
      answer={"PP: "+props.answer?.ppAnswer}
      isMarkedCorrect={is_pp_active}
      type="0"
      score={score}
      teamName={props.teamName}
      onScoreUpdate={onScoreUpdate}
      forceUpdateComp={props.forceUpdateComp}
    />
  );

  extrasList.push(
    <ScoreButton
      key={`jj_${id}`}
      id={`jj_${id}`}
      roundId ={roundId}
      title="JJ"
      answer="Joker Played"
      isMarkedCorrect={is_jj_active}
      type="1"
      score={score}
      teamName={props.teamName}
      onScoreUpdate={onScoreUpdate}
      forceUpdateComp={props.forceUpdateComp}
    />
  );
}

let firstHalf = buttonList.slice(0, 5);
let secondHalf = buttonList.slice(5);

function interleave(arr1: any, arr2: any) {
  let result = [];
  for (let i = 0; i < arr1.length; i++) {
    result.push(arr1[i]);
    if (arr2[i]) {
      result.push(arr2[i]);
    }
  }
  return result;
}

// Create the final ordered array
const orderedComponents = interleave(firstHalf, secondHalf);


  return <div className="bion-btn row">
            {width > 400 ? 
                <><>{buttonList}</><>{extrasList}</></>
            :
                <><>{orderedComponents}</><>{extrasList}</></>
            }
          </div>
}

export default ScoreCardAnswerButtons;
