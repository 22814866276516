import { Modal } from 'react-bootstrap';
function ModalComponent(props:any) {
    return ( 
        <>
          <Modal show={props.isShowModal} >
        <Modal.Body className="text-center">
            <i className={props.icon}></i>
            <p className="p-txt mb-1">{props.text}</p>
            <div>
                <button className="btn btn-success" style={{"width" : "12%"}} onClick={props.onHandleOkBtn}>Ok</button>
            </div>
        </Modal.Body>
    </Modal>
        </>
      
     );
}

export default ModalComponent;