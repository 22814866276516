import React, {
  useEffect,
  useState,
} from "react";
import Layout from "./Layout";
import ScoreCardAnswerButtons from "./ScoreCardAnswerButtons";
import Calculate from "../utils/AutomaticScoreCalculator";
import updateScore from "../utils/UpdateScore";
import IScore from "../interfaces/IScore";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import IGamesData from "../interfaces/IGamesData";
import Iinfo from "../interfaces/Iinfo";
import _ from "lodash";
import TeamWise from "./TeamWise";
import { useDispatch } from "react-redux";
import { actionCreators } from "../state/index";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  FINALIZE_GAME_APIS_URL,
  END_GAME_APIS_URL,
  SCORE_APIS_URL,
  QUIZ_GET_QUES_ANS_URL,
} from "../config/config";
import { ToastContainer } from "react-toastify";
import LoadingSpinner from "./common/LoadingSpinner";
import ModalComponent from "./common/ModalComponent";
import ModalPipeAnswer from "./common/ModalPipeAnswer";

interface IshowAnsDetail {
  question: String;
  ans: String;
  hostInfo: String;
  quesType: String;
}
//require axios for api calling
const axios = require("axios").default;

function ScoreEditor(props: any) {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const reduxData = useSelector((state: any) => state.pp);
  let gamesData: IGamesData[] = [];
  const [value, setValue] = useState({
    showModal: false,
    showModalOfDataSaved: false,
    messageOFDataSaveModal: "Data has successfully saved",
    gamesData,
  });
  const [, setUpdateComp] = useState(true);
  //state  variable for laoder
  const [gettingData, setgettingData] = useState(true);
  //state variable for handling collapse and expansion of bars
  const [collpaseState, setcollpaseState] = useState(true);
  // accordion-collapse collapse show multi-collapse
  const [collpaseClass, setcollpaseClass] = useState(
    "accordion-collapse collapse show"
  );
  const [expandBtnLabel, setexpandBtnLabel] = useState("Collapse All");
  // const [gData, setgData] = useState(gamesData);
  const [expandBtnClass, setexpandBtnClass] = useState(
    "accordion-button accord-color"
  );
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [round, setRound] = useState("0");
  const [team, setTeam] = useState("0");
  const [teamCondition, setTeamCondition] = useState(false);
  const [roundCondition, setRoundCondition] = useState(false);
  const [teams, setTeams] = useState<Array<String>>([]);
  const [endGameDisabled, setEndGameDisabled] = useState(false);
  const [, setFinalizeGameDisabled] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  //state variable to handle tie break info modal
  const [showTieBreakInfoModal, setShowTieBreakInfoModal] = useState(false);
  const [quizDataFromApi, setQuizDataFromApi] = useState<Array<any>>([]);
  // const [quizDataForModal, setQuizDataForModal] = useState<Array<any>>();
  const [showModalForShowAnsDetail, setShowModalForShowAnsDetail] =
    useState(false);
  const [dataForShowAnsDetailModal, setDataForShowAnsDetailModal] = useState<
    Array<IshowAnsDetail>
  >([]);
  const [, setquizNoOfRounds] = useState(0);
  //state variable to check is game deleted or not
  const [hasGameDeleted, setHasGameDeleted] = useState(false);

  const [jjstate, setJjstate] = useState(true);

  const navigate = useNavigate();
  const location: any = useLocation();

  const onExpandBtnclick = () => {
    if (collpaseState === false) {
      setcollpaseState(true);
      setexpandBtnLabel("Collapse All");
      setcollpaseClass("accordion-collapse collapse show");
      setexpandBtnClass("accordion-button accord-color ");
    } else {
      setcollpaseState(false);
      setexpandBtnLabel("Expand All");
      setexpandBtnClass("accordion-button accord-color collapsed");

      setcollpaseClass("accordion-collapse collapse");
    }
  };
  const forceUpdateComp = () => {
    setUpdateComp((current) => !current);
  };
  const handleScoreUpdate = async (scr: any) => {
    let data: any = updateScore(scr, value.gamesData);

    let newState = { ...value };
    newState.gamesData = data;

    setValue(newState);
    dispatch(actionCreators.scoreEditorState(newState));
    dispatch(actionCreators.ppCheck(data));

    console.log("REDUX");
    console.log(reduxData);
  };

  /**
     * function returns calculated score
    * @returns {int}
     */
  const calculateScore = (score: any) => {
    let scoreObj: IScore = {
      noOfRounds: value.gamesData[0].no_of_rounds,
      roundType: score.roundType,
      roundId: score.roundId,
      correct_answers: score.correct_answers,
      is_jj_active: score.is_jj_active,
      is_pp_active: score.is_pp_active,
    };
    return Calculate(scoreObj);
  };

  let arr = [];
  for (let i = 0; i < location.state.noOfRounds + 2; i++) {
    if (i === 4) {
      arr.push(`${"The List"}`);
    } else if (i > 4) {
      arr.push(`${"Round " + i}`);
    } else {
      arr.push(`${"Round " + (i + 1)}`);
    }
  }
  arr[arr.length - 1] = `${"Tie-Break"}`;

  /**
     * function to display finalize game modal
    * @returns {void}
     */
  const finalizeGame = () => {
    let newState = { ...value };
    newState.showModal = true;
    setValue(newState);
    dispatch(actionCreators.scoreEditorState(newState));
  };

  /**
     * function to open model for ending the game 
    * @returns {void}
     */
  const endGame = () => {
    setShowEndGameModal(true);
  };

  /**
     * function execute on click of save score and saves score of game
    * @returns {void}
     */
  const saveScore = async () => {
    
    let locationId = location.state.locationId;
    let gameId = location.state.gameId;

    let scoreDataPacket = {
      info: value.gamesData[0].info,
    };
    
    
    let newScoreDataPacket = {
      data: scoreDataPacket,
    };
   

    let config = await getHeader();
    console.log(JSON.stringify(newScoreDataPacket));
    setgettingData(true);
    axios
      .put(
        `${SCORE_APIS_URL}/${locationId}/${gameId}`,
        newScoreDataPacket,
        config
      )
      .then(function (response: any) {
        console.log(`Response Received${response}`);
        if (response.data.description === "This game is deleted now") {
          setHasGameDeleted(true);
          return;
        }
        let newState = { ...value };
        newState.showModalOfDataSaved = true;
        setValue(newState);
        dispatch(actionCreators.scoreEditorState(newState));
        dispatch(actionCreators.openModal(false));
        forceUpdateComp();
      })
      .catch(function (error: any) {
        console.log(error)
        let newState = { ...value };
        newState.showModalOfDataSaved = true;
        newState.messageOFDataSaveModal = "Something's not working.<br/><small>Server says: "+ error.message+" stay here and try again in a minute</small>";
        setValue(newState);
        dispatch(actionCreators.scoreEditorState(newState));
        console.log("failure");
      })
      .finally(function(){
        setgettingData(false);
      });
  };

  /**
     * function to set selected round
    * @returns {void}
     */
  const setSelectedRound = (e: string) => {
    if (e === "null") {
      setRound("0");
      setRoundCondition(false);
    } else {
      console.log("e value", e);
      setRound(e);
      setRoundCondition(true);
    }

  };

  /**
     * function se selected team
    * @returns {void}
     */
  const setSelectedTeam = (e: string) => {
    if (e === "null") {
      setTeam("0");
      setTeamCondition(false);
    } else {
      console.log("e value", e);
      setTeam(e);
      setTeamCondition(true);
    }
  };

  const okModal = () => {
    let newState = { ...value };
    newState.showModalOfDataSaved = false;
    setValue(newState);
    // dispatch(actionCreators.scoreEditorState(newState));
  };

  const navigateToLeaderBoard = (state: any) => {
    navigate("/leader-board?jj="+state, {
      state: {
        locationId: location.state.locationId,
        gameId: location.state.gameId,
        gameName: location.state.gameName,
        locationName: location.state.locationName,
      },
    });
  };

  const navigateToDashboard = () => {
    navigate("/layout");
  };

  const proceedModal = async () => {
    setShowEndGameModal(false);
    let newState = { ...value };
    newState.showModal = true;
    setValue(newState);
    dispatch(actionCreators.scoreEditorState(newState));
    // let locationId = location.state.locationId;
    let gameId = location.state.gameId;
    saveScore();

    let deleteKey = {
      data: "",
    };

    let config = await getHeader();

    axios
      .put(`${FINALIZE_GAME_APIS_URL}/${gameId}`, deleteKey, config)
      .then(function (response: any) {
        console.log(`Response Received for end game`, response);
        if (response.data.description === "This game is deleted now") {
          let newState = { ...value };
          newState.showModal = false;
          setValue(newState);
          setHasGameDeleted(true);
          return;
        }
        return navigate("/leader-board", {
          state: {
            locationId: location.state.locationId,
            gameId: location.state.gameId,
            gameName: location.state.gameName,
            locationName: location.state.locationName,
          },
        });
      })
      .catch(function (error: any) {
        console.log("failure", error);
        setShowErrorModal(true);
      });
  };

  const proceedEndGameModal = async () => {
    setShowEndGameModal(false);
    // let locationId = location.state.locationId;
    let gameId = location.state.gameId;
    saveScore();

    let deleteKey = {
      data: "",
    };

    let config = await getHeader();

    axios
      .put(`${END_GAME_APIS_URL}/${gameId}`, deleteKey, config)
      .then(function (response: any) {
        console.log(`Response Received for end game`, response);
        if (response.data.description === "This game is deleted now") {
          setHasGameDeleted(true);
          return;
        }
        return navigate("/leader-board", {
          state: {
            locationId: location.state.locationId,
            gameId: location.state.gameId,
            gameName: location.state.gameName,
            locationName: location.state.locationName,
          },
        });
      })
      .catch(function (error: any) {
        console.log("failure", error);
        setShowErrorModal(true);
      });
  };

  
  const cancelModal = () => {
    let newState = { ...value };
    newState.showModal = false;
    setValue(newState);
    dispatch(actionCreators.scoreEditorState(newState));
  };

  const cancelEndGameModal = () => {
    setShowEndGameModal(false);
    setShowErrorModal(false);
  };


  // function to display modal on click of Tie-Break Info Button
  const onClickOfTieBreakInfoBtn = () => {
    setShowTieBreakInfoModal(true);
  };


  // function to close Tie-Break Info  modal
  const onClickOfOkBtnOfTieBreakModal = () => {
    setShowTieBreakInfoModal(false);
  };

  /**
     * function to set the headers
    * @returns {void}
     */
  const getHeader = async () => {
    const token = await getAccessTokenSilently();
    console.log(token);
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      },
    };
    return config;
  };

  useEffect(() => {
    getDataFromDb();
  }, []);


  async function getDataFromDb() {
    // Set state variabl to start loader
    setgettingData(true);
    let config = await getHeader();
    await axios
      .get(
        `${SCORE_APIS_URL}/${location.state.locationId}/${location.state.gameId}`,
        config
      )
      .then(function (response: any) {
        // handle success
        let scoreListRes = response.data.data;
        let newState = { ...value };
        newState.gamesData = scoreListRes;
        console.log(
          "scoreListRes[0].infoscoreListRes[0].info",
          scoreListRes[0].isGameEnded
        );
        if (scoreListRes[0].isGameFinalized) {
          navigateToDashboard();
        }
        setEndGameDisabled(scoreListRes[0].isGameEnded);
        setFinalizeGameDisabled(scoreListRes[0].isGameEnded);
        let teamIds = _.keys(
          _.countBy(scoreListRes[0].info, (data) => {
            return data.teamName;
          })
        );

        let afterSortTeamName = teamIds.sort((a: any, b: any) =>
          a.localeCompare(b)
        );
        console.log(afterSortTeamName);
        setTeams(afterSortTeamName);
        setValue(newState);
        dispatch(actionCreators.scoreEditorState(newState));
        dispatch(actionCreators.ppCheck(scoreListRes));
      })
      .catch(function (error: any) {
        // handle error
        console.log(error);
      });

    await axios
      .get(`${QUIZ_GET_QUES_ANS_URL}/${location.state.quizId}`, config)
      .then(function (response: any) {
        console.log("QUIZ_DATA", response.data.message.roundWiseQuestionDetail);
        setQuizDataFromApi(response.data.message.roundWiseQuestionDetail);
        setquizNoOfRounds(location.state.noOfRounds);
      })
      .catch(function (error: any) {
        console.log("QUIZ_DATA_ERROR", error);
      })
      .finally(function () {
        setgettingData(false);
      });
  }

  const changeScoreType = (score: Iinfo) => {
    if (
      score.correct_answers.length === 1 &&
      score.correct_answers.includes("")
    ) {
      score.correct_answers = [];
    }
    return score;
  };


  const setSelectedOptionForTieBreak = (tieBreakScore: any, tmId: any) => {
    let obj = {
      correct_answers: [tieBreakScore],
      game_id: value.gamesData[0].gameId,
      is_jj_active: false,
      is_pp_active: false,
      location_id: value.gamesData[0].locationId,
      roundId: arr.length,
      teamId: tmId,
    };
    console.log(obj);

    let data: any = updateScore(obj, value.gamesData);
    let newState = { ...value };
    newState.gamesData = data;

    setValue(newState);
    dispatch(actionCreators.scoreEditorState(newState));
    // dispatch(actionCreators.ppCheck(data));
  };


  /**
   * Function to show data on display answer for other rounds
   * @param {any} data   data of question list
   * @returns {void}
   */
  const showAnswerDetail = (data: any, i: any) => {
    //extract array of question list from object
    let quesList: Array<any> = data.questionList;
    let showAnsDetailArray: Array<IshowAnsDetail> = [];

    if (data.categoryName === "The List") {
      let hostInformation = quesList[0].hostInfo;
      quesList[0].options.map((ele: any, i: any) =>
        showAnsDetailArray.push({
          question: quesList[0].question,
          ans: ele.optionDescription,
          hostInfo: hostInformation,
          quesType: "The List",
        })
      );
    } else {
      let quesType: String;
      for (let index = 0; index < quesList.length; index++) {
        let findQuesType = quesList[index].questionTypes.findIndex(
          (e: any) => e.questionTypeName === "Multimedia"
        );
        let findQuesTypeCC = quesList[index].questionTypes.findIndex(
          (e: any) => e.questionTypeName === "Cryptic Clue"
        );
        if (findQuesType === -1) {
          quesType = "Standard";
        } 
        else {
          quesType = "Multimedia";
        }
        if (findQuesTypeCC === 0) {
          quesType = "Cryptic Clue";
        }

        showAnsDetailArray.push({
          question: quesList[index].question,
          ans: quesList[index].options[0].optionDescription,
          hostInfo: quesList[index].hostInfo,
          quesType: quesType,
        });
      }
      //insert the PP answer at the end of list
      showAnsDetailArray.push({
        question: data.ppAnswer,
        ans: data.ppAnswer,
        hostInfo: "",
        quesType: "pp",
      });
      if (data.categoryName === "Tie-break") {
      }
    }

    setDataForShowAnsDetailModal(showAnsDetailArray);
    setShowModalForShowAnsDetail(true);
  };

  /**
   * Function to show data on display answer Of Tie-break Round
   * @param {any} dataForTieBreak   tie-break question list
   * @param {any} dataForJackpot   Jackpot round list
   * @returns {void}
   */
  const showAnswerDetailForTieBreak = (
    dataForTieBreak: any,
    dataForJackpot: any,
    i: any
  ) => {
    //extract array of question list from object
    let quesListForTieBreak: Array<any> = dataForTieBreak.questionList;
    let quesListForJackpot: Array<any> = dataForJackpot.questionList;
    let showAnsDetailArray: Array<IshowAnsDetail> = [];

    let quesType: String;
    //Iterate over the question list of tie-break round
    for (let index = 0; index < quesListForTieBreak.length; index++) {
      let findQuesType = quesListForTieBreak[index].questionTypes.findIndex(
        (e: any) => e.questionTypeName === "Multimedia"
      );
      if (findQuesType === -1) {
        quesType = "Standard";
      } else {
        quesType = "Multimedia";
      }
      showAnsDetailArray.push({
        question: quesListForTieBreak[index].question,
        ans: quesListForTieBreak[index].options[0].optionDescription,
        hostInfo: quesListForTieBreak[index].hostInfo,
        quesType: quesType,
      });
    }

    //Iterate over the question list of Jackpot round
    for (let index = 0; index < quesListForJackpot.length; index++) {
      let findQuesType = quesListForJackpot[index].questionTypes.findIndex(
        (e: any) => e.questionTypeName === "Multimedia"
      );
      if (findQuesType === -1) {
        quesType = "Standard";
      } else {
        quesType = "Multimedia";
      }
      showAnsDetailArray.push({
        question: quesListForJackpot[index].question,
        ans: quesListForJackpot[index].options[0].optionDescription,
        hostInfo: quesListForJackpot[index].hostInfo,
        quesType: quesType,
      });
    }
    //insert the PP answer at the end of list
    showAnsDetailArray.push({
      question: dataForTieBreak.ppAnswer,
      ans: dataForTieBreak.ppAnswer,
      hostInfo: "",
      quesType: "pp",
    });

    setDataForShowAnsDetailModal(showAnsDetailArray);
    setShowModalForShowAnsDetail(true);
  };


  const okShowAnsDetailModal = () => {
    setShowModalForShowAnsDetail(false);
  };


  const displayDataForTieBreak = (i: any) => {
    let showAnsDetailData: any = quizDataFromApi.find(
      (obj: any) => obj.categoryName === "Tie-break"
    );
    let showAnsDetailDataForJackpot: any = quizDataFromApi.find(
      (obj: any) => obj.categoryName === "Jackpot"
    );
    if (value.gamesData.length > 0) {
      return (
        <div>
          {value.gamesData[0].info
            .sort((a: any, b: any) => a.teamName.localeCompare(b.teamName))
            .filter((item) => item.roundId === i + 1)
            .map((score, index) => (
              <div>
                {!teamCondition && (
                  <tr
                    key={index}
                    className="table-row accordion-item row align-items-center m-0 "
                  >
                    <td className="col-4 text-start py-2">
                      {score.teamName}
                    </td>
                    <td className="col-2 d-flex text-center p-0 gap-4">
                      <select
                        id="selectRound"
                        defaultValue={
                          score.correct_answers[0]
                            ? score.correct_answers[0].toString()
                            : "0"
                        }
                        onChange={(e) =>
                          setSelectedOptionForTieBreak(
                            e.target.value,
                            score.teamId
                          )
                        }
                      >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                      </select>
                    </td>
                    <td className="col-lg-2 col-sm-12 col-xs-12 col-md-1 td-right  fw-bold py-2 pc-1 fw-20">
                      <button
                        type="button"
                        className="m-1 btn btn-sm btn btn-warning rounded-0 col  text-center py-1"
                        onClick={() =>
                          showAnswerDetailForTieBreak(
                            showAnsDetailData,
                            showAnsDetailDataForJackpot,
                            i
                          )
                        }
                        style={{ marginRight: "2px" }}
                      >
                        Show Answers
                      </button>
                    </td>
                    <td className="col-4 td-right fw-bold py-2 pc-1 fw-20 text-end">
                      {score.correct_answers[0]
                        ? score.correct_answers[0]
                        : "0"}
                    </td>
                  </tr>
                )}
                {teamCondition && team === `${"" + (index + 1)}` && (
                  <tr
                    key={index}
                    className="table-row accordion-item row align-items-center m-0 "
                  >
                    <td className="col-4 text-start py-2">
                      {score.teamName}
                    </td>
                    <td className="col-2 d-flex text-center p-0 gap-4">
                      <select
                        id="selectRound"
                        style={{ height: "30px", border: "none" }}
                        defaultValue={
                          score.correct_answers[0]
                            ? score.correct_answers[0].toString()
                            : "0"
                        }
                        onChange={(e) =>
                          setSelectedOptionForTieBreak(
                            e.target.value,
                            score.teamId
                          )
                        }
                      >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                      </select>
                    </td>
                    <td className="col-lg-2 col-sm-12 col-xs-12 col-md-1 td-right fw-bold py-2 pc-1 fw-20" style={{marginLeft: 6}}>
                      <button
                        type="button"
                        className="m-1 btn btn-sm btn btn-warning rounded-0 col  text-center py-1"
                        onClick={() => showAnswerDetail(showAnsDetailData, i)}
                        style={{ marginRight: "2px" }}
                      >
                        Show Answers
                      </button>
                    </td>
                    <td className="col-6 td-right fw-bold py-2 pc-1 fw-20 text-end">
                      {score.correct_answers[0]
                        ? score.correct_answers[0]
                        : "0"}
                    </td>
                  </tr>
                )}
              </div>
            ))}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const displayData = (i: any) => {
    let showAnsDetailData: any =
      i < 4
        ? quizDataFromApi.find((o: any) => o.roundNo === i + 1)
        : i > 4
        ? quizDataFromApi.find((o: any) => o.roundNo === i)
        : quizDataFromApi.find((o: any) => o.categoryName === "The List");
    if (value.gamesData.length > 0) {
      return (
        <div>
          {value.gamesData[0].info
            .sort((a: any, b: any) => a.teamName.localeCompare(b.teamName))
            .filter((item) => item.roundId === i + 1)
            .map((score, index) => (
              <div>
                {!teamCondition && (
                  <tr
                    key={index}
                    className="table-row accordion-item row align-items-center m-0 "
                  >
                    <td className="col-lg-2 col-xs-12 col-sm-12 col-md-2 text-start py-2">
                      {score.teamName}
                    </td>
                    <td className="col-lg-8 col-sm-12 col-xs-12 col-md-9 d-flex text-center p-0 gap-4">
                      <ScoreCardAnswerButtons
                        data={changeScoreType(score)}
                        answer={i < 4 ? quizDataFromApi[i] : i > 4 ? quizDataFromApi[i-1] : null }
                        location_id={value.gamesData[0].locationId}
                        game_id={value.gamesData[0].gameId}
                        no_of_rounds={value.gamesData[0].no_of_questions}
                        teamName={score.teamName}
                        onScoreUpdate={handleScoreUpdate}
                        forceUpdateComp={forceUpdateComp}
                      />
                    </td>
                    <td className="col col-lg-1 col-sm-12 col-xs-12 col-md-1 fw-bold py-2 pc-1 bion-btn" >
                      <button
                        type="button"
                        className="btn btn-sm btn btn-warning rounded-0 col text-center text-score"
                        onClick={() => showAnswerDetail(showAnsDetailData, i)}
                      >
                        Show Info
                      </button>
                    </td>
                    <td className="col td-right py-2 pc-1" style={{marginRight: 8, marginTop: 4, fontSize: 40, lineHeight: '40px', fontFamily: "MarkPro Black"}}>
                      {calculateScore(score)}
                    </td>
                  </tr>
                )}
                {teamCondition && team === `${"" + (index + 1)}` && (
                  <tr
                    key={index}
                    className="table-row accordion-item row align-items-center m-0 "
                  >
                    <td className="col-lg-2 col-xs-12 col-sm-12 col-md-2 text-truncate text-start py-2 hide-small">
                      {score.teamName}
                    </td>
                    <td className="col-lg-8 col-sm-12 col-xs-12 col-md-9 d-flex text-center p-0 gap-4">
                      <ScoreCardAnswerButtons
                        data={changeScoreType(score)}
                        answer={i < 4 ? quizDataFromApi[i] : i > 4 ? quizDataFromApi[i-1] : null }
                        location_id={value.gamesData[0].locationId}
                        game_id={value.gamesData[0].gameId}
                        no_of_rounds={value.gamesData[0].no_of_questions}
                        teamName={score.teamName}
                        onScoreUpdate={handleScoreUpdate}
                        forceUpdateComp={forceUpdateComp}
                      />
                    </td>
                    <td className="col col-lg-1 col-sm-12 col-xs-12 col-md-1 fw-bold py-2 pc-1 bion-btn" >
                      <button
                        type="button"
                        className="btn btn-sm btn btn-warning rounded-0 col text-center text-score"
                        onClick={() => showAnswerDetail(showAnsDetailData, i)}
                      >
                        Show Info
                      </button>
                    </td>
                    <td className="col td-right py-2 pc-1" style={{marginRight: 8, marginTop: 4, fontSize: 40, lineHeight: '40px', fontFamily: "MarkPro Black"}}>
                      {calculateScore(score)}
                    </td>
                  </tr>
                )}
              </div>
            ))}
        </div>
      );
    } else {
      return <></>;
    }
  };


  // const showTeamWise = () => {
  //   forceUpdateComp();
  // };
  

  const displayCategaryName = (i: any) => {
    if (quizDataFromApi.length > 0) {
      return i < 4
        ? quizDataFromApi.find((o: any) => o.roundNo === i + 1).categoryName
        : i > 4
        ? quizDataFromApi.find((o: any) => o.roundNo === i).categoryName
        : quizDataFromApi.find((o: any) => o.categoryName === "The List")
            .categoryName;
    } else {
      <></>;
    }
  };

  const hasGameDeletedOkBtn = () => {
    setHasGameDeleted(false);
    navigate("/dashboard");
  };


  return (
    <>
      <ToastContainer />

      <ModalComponent
        isShowModal={hasGameDeleted}
        text="This Game is deleted now"
        icon="fas fa-window-close team-red-modal-icon"
        onHandleOkBtn={hasGameDeletedOkBtn}
      />

      <Modal show={value.showModal}>
        <Modal.Body className="text-center">
          <i className="fas fa-question-circle quit-modal"></i>
          <p></p>
          <p className="p-txt-initial">
            This will end the game permanently and lock it for any further
            scoring or editing. It should only be used when the quiz is complete
            and all scoring is confirmed and finalised.
          </p>
          <p className="p-txt-initial">Do you wish to continue?</p>
          <div>
            <button
              className="btn btn-success text-left m-2"
              onClick={proceedModal}
            >
              Yes
            </button>
            <button className="btn btn-secondary" onClick={cancelModal}>
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showErrorModal}>
        <Modal.Body className="text-center">
          <i className="fas fa-question-circle quit-modal"></i>
          <p className="p-txt">Something went wrong.</p>
          <div>
            <button
              className="btn btn-success text-left m-2"
              onClick={proceedModal}
            >
              Yes
            </button>
            <button className="btn btn-secondary" onClick={cancelEndGameModal}>
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showEndGameModal}>
        <Modal.Body className="text-center">
          <i className="fas fa-question-circle quit-modal"></i>
          <p></p>
          <p className="p-txt-initial">
            Are you sure you want to end the quiz and show the winners on the
            Scoreboard?
          </p>
          <p className="p-txt-initial">
            You will still be able to edit the results to correct any errors
            after this, but the trophies will remain permanently on the
            Scoreboard.
          </p>
          <div>
            <button
              className="btn btn-success text-left m-2"
              onClick={proceedEndGameModal}
            >
              Yes
            </button>
            <button className="btn btn-secondary" onClick={cancelEndGameModal}>
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={value.showModalOfDataSaved}>
        <Modal.Body className="text-center">
          <p className="">
          <i
            className={
              value.messageOFDataSaveModal === "Data has successfully saved"
                ? "fas fa-check-circle team-reg-modal-icon"
                : "fas fa-exclamation-circle data-failure-modal"
            }
          ></i>
          &nbsp;{value.messageOFDataSaveModal}
          </p>
          <div>
            <button className="btn btn-warning text-left m-2 btn-rounded" onClick={okModal}>
              Stay Here
            </button>
            <button
              className="btn btn-success text-left m-2 btn-rounded"
              onClick={()=>navigateToLeaderBoard(true)}
            >
              Show Scoreboard
            </button>
            <button
              className="btn btn-success text-left m-2 btn-rounded"
              onClick={()=>navigateToLeaderBoard(false)}
            >
              Show Scoreboard - hide jokers
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Display Modal on Click Of Tie-Break Info button */}
      <ModalComponent
        isShowModal={showTieBreakInfoModal}
        text={
          <>
            <ul
              style={{
                fontSize: "22px",
                marginTop: "20px",
                fontWeight: "normal",
                textAlign: "left",
              }}
            >
              <li>
                For tie-breaks, head to the Tie-Break section at the bottom of
                the list of Rounds.
              </li>
              <li>
                Use the dropdown menu next to the team's name to give the team
                that got the closest to the correct answer 10 points.
              </li>
              <li>
                The second closest team should receive 9, the third closest 8,
                and so on
              </li>
              <li>
                The Scoreboard will automatically and invisibly sort any tied
                teams based on which of them is given the most points in this
                section.
              </li>
              <li>
                Do this to ensure the teams and prize winners are reflected
                correctly on the Scoreboard.
              </li>
            </ul>
          </>
        }
        icon="fas fa-info-circle fa-2x info-search-modal-icon"
        onHandleOkBtn={onClickOfOkBtnOfTieBreakModal}
      />
      <ModalPipeAnswer
        data={dataForShowAnsDetailModal}
        showModal={showModalForShowAnsDetail}
        cancelAnswerDetail={okShowAnsDetailModal}
      />
      <Layout finalValue={value} />
      <main id="main" className="withoutSideBarNav">
        <div className="pagetitle">
          {/* <h1>Game Id : #{location.state.gameId}</h1> */}
          <h1 className="fw-normal">{location.state.gameName}</h1>
          <nav>
            <ol className="breadcrumb">
              {/* <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li> */}
              <li className="breadcrumb-item">Dashboard</li>
              <li className="breadcrumb-item">Contest</li>
            </ol>
          </nav>
        </div>

        <div className="scoreboard-header d-flex justify-content-between align-items-center gap-2 scoreEditor-label scoreEditor-label">
              <div className="text-start">
                <h1 className="">Scorecard</h1>
              </div>
              <div className="button-scroller">
                <ul className="button-scroll">
                  <li>
                  <button
                    type="button"
                    className="btn btn-info"
                    style={{ color: "white" }}
                    onClick={onClickOfTieBreakInfoBtn}
                  >
                    Tie-Break Info
                  </button>
                  </li>
                  <li>
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={saveScore}
                  >
                    Save Score
                  </button>
                  </li>
                  <li>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={endGame}

                    disabled={endGameDisabled}
                  >
                    Display Winners
                  </button>
                  </li>
                  <li>
                  <button
                    type="button"
                    className="btn btn-danger btn-size"
                    onClick={finalizeGame}
                    disabled={!endGameDisabled}
                  >
                    Finalise Quiz
                  </button>
                  </li>
                  <li>
                  <button
                    type="button"
                    className="btn btn-primary btn-fixed"
                    data-bs-toggle="collapse"
                    onClick={onExpandBtnclick}
                    data-bs-target=".multi-collapse"
                    aria-expanded="true"
                    aria-controls="collapseOne collapseTwo collapseThree"
                  >
                    {expandBtnLabel}
                  </button>
                  </li>
                  <li>
                  {/* <button
                    type="button"
                    className="btn btn-hollow btn-secondary btn-fixed"
                    data-bs-toggle="collapse"
                    onClick={()=>setJjstate(!jjstate)}
                    data-bs-target=".multi-collapse"
                    aria-expanded="true"
                    aria-controls="collapseOne collapseTwo collapseThree"
                  >
                    {jjstate === true ? "Hide Joker Rounds" :  "Show Joker Rounds"}
                  </button> */}
                  </li>
                </ul>
              </div>
            </div>

        <section className="section-tab">
          <div className="row gx-5 mb-3 ld">
            <div className="card m-0 col-md-6 col-lg-7 mb-1 d2">
              <div className="card-body gap-8 row">
                <div className="col-lg-5 my-auto">
                  <select
                    id="selectRound"
                    className="form-select"
                    onChange={(e) => setSelectedRound(e.target.value)}
                  >
                    <option value="null">Choose Round</option>
                    {[...Array(location.state.noOfRounds + 2)].map((e, i) => (
                      <option key={i} value={i + 1}>
                        {i === 4
                          ? "The List"
                          : i > 4
                          ? i === location.state.noOfRounds + 1
                            ? "Tie-Break"
                            : `R${i}: `+displayCategaryName(i)
                          : `R${i + 1}: `+displayCategaryName(i)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-5 my-auto">
                  <select
                    id="selectTeam"
                    className="form-select"
                    onChange={(e) => setSelectedTeam(e.target.value)}
                  >
                    <option value="null">Select Team</option>
                    {teams.map((ele, i) => (
                      <option key={i} value={i + 1}>Team: 
                        {' '+ele}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* <div className="card m-0 col-md-6 col-lg-5 col-xl-4 mb-1 d4" id="order-first">
              <ul
                className="nav nav-pills col-lg-12"
                id="pills-tab"
                role="tablist"
              >
                <li className="col nav-item" role="presentation">
                  <button
                    className="w-100 nav-link active rounded-0 h-100"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#round"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                     <p className="m-0"><span className="round-icon"></span>Round</p>
                  </button>
                </li>
                <li className="col nav-item" role="presentation">
                  <button
                    className="w-100 nav-link rounded-0 h-100"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#teams"
                    onClick={showTeamWise}
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                      <p className="m-0"><span className="teams-icon"></span>Teams</p>
                  </button>
                </li>
              </ul>
            </div> */}

            
            <div className="tab-content" id="myTabContent">
              <section
                className="section tab-pane fade active show"
                id="round"
                role="tabpanel"
                aria-labelledby="round-tab"
              >
                <div className="row">
                  {gettingData ? (
                    <LoadingSpinner />
                  ) : (
                    <div className="col-lg-12 px-0">
                      <div className="accordion" id="round-data">
                        {arr.map((ele, i) => (
                          // <div>{i+1}{round}</div>
                          <div>
                            {!roundCondition && (
                              <div
                                key={i}
                                className="accordion-item hide"
                                id={`${"Round_" + (i + 1)}`}
                              >
                                <h2
                                  className="accordion-header"
                                  id="headingOne"
                                >
                                  {ele === "Tie-Break" ? (
                                    <button
                                      className={expandBtnClass}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={"#collapse" + i}
                                      aria-expanded="false"
                                      aria-controls={"#collapse" + i}
                                    >
                                      {ele} &#40;If Required&#41;
                                    </button>
                                  ) : (
                                    <button
                                      className={expandBtnClass}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={"#collapse" + i}
                                      aria-expanded="false"
                                      aria-controls={"#collapse" + i}
                                    >
                                      {ele} &#40;{displayCategaryName(i)}&#41;
                                    </button>
                                  )}
                                </h2>
                                <div
                                  id={"collapse" + i}
                                  className={collpaseClass}
                                  aria-labelledby="headingOne"
                                >
                                  <table className="accordion-body w-100">
                                    <tbody>
                                      {ele !== "Tie-Break"
                                        ? displayData(i)
                                        : displayDataForTieBreak(i)}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                            {roundCondition && round === `${"" + (i + 1)}` && (
                              <div
                                key={i}
                                className="accordion-item hide"
                                id={`${"Round_" + (i + 1)}`}
                              >
                                <h2
                                  className="accordion-header hide-small"
                                  id="headingOne"
                                >
                                  {ele === "Tie-Break" ? (
                                    <button
                                      className={expandBtnClass}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={"#collapse" + i}
                                      aria-expanded="false"
                                      aria-controls={"#collapse" + i}
                                    >
                                      {ele} &#40;If Required&#41;
                                    </button>
                                  ) : (
                                    <button
                                      className={expandBtnClass}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={"#collapse" + i}
                                      aria-expanded="false"
                                      aria-controls={"#collapse" + i}
                                    >
                                      {ele} &#40;{displayCategaryName(i)}&#41;
                                    </button>
                                  )}
                                </h2>
                                <div
                                  id={"collapse" + i}
                                  className={collpaseClass}
                                  aria-labelledby="headingOne"
                                >
                                  <table className="accordion-body w-100">
                                    <tbody>
                                      {ele !== "Tie-Break"
                                        ? displayData(i)
                                        : displayDataForTieBreak(i)}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </section>
              <section
                className="tab-pane fade mb-1"
                id="teams"
                role="tabpanel"
                aria-labelledby="team-tab"
              >
                <TeamWise
                  locationId={location.state.locationId}
                  gameId={location.state.gameId}
                  expandBtn={expandBtnClass}
                  gameDatalist={value.gamesData}
                  collpaseClass={collpaseClass}
                  round={round}
                  team={team}
                  teamCondition={teamCondition}
                  roundCondition={roundCondition}
                />
              </section>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default React.forwardRef(ScoreEditor);
