import { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default class TextEditorForCreatePp extends Component<any> {
  // constructor(props: any) {
  //   super(props);
  // }

  state = {

    editorState: EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(this.props.opt).contentBlocks,
        htmlToDraft(this.props.opt).entityMap
      )
    )
  };

  updateFocus = () => {
    this.setState({
      editorState: EditorState.moveSelectionToEnd(this.state.editorState), // EditorState imported from draft-js
    });
  }

  
  updateAndNotify = () => {

    const editorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(this.props.opt).contentBlocks,
        htmlToDraft(this.props.opt).entityMap
      )
    );
    const editorStateWithFocusOnTheEnd = EditorState.moveFocusToEnd(editorState)
    this.setState({ editorState: editorStateWithFocusOnTheEnd });

  }


  componentDidUpdate(prevProps: any) {
    if (prevProps.opt !== this.props.opt) {
      this.updateAndNotify();

    }

  }

    /**
     * function execute on editor state change
     * @returns {void}
    */
  onEditorStateChange = (editorState: any) => {
    this.setState({
      editorState,
    });
    let val = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    this.props.onQuestionInputHandler(val, this.props.id);
  };

  render() {
    const { editorState } = this.state;
    const htmlData: any = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    console.log(htmlData);
    return (
      <div>
        <div className="border-textEditor clueInfo">
          
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={this.onEditorStateChange}
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'textAlign', 'history'],
              fontFamily: {
                options: ['Arial', 'AlternateGothic', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
              }
            }}
          />
          
        </div>
        
      </div>

    );
  }
}