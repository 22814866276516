import { useState, useEffect } from 'react';
import { ValidationResponse } from "../../interfaces/error";
import { Validate } from "../../utils/validation";
import { useSelector } from 'react-redux';


function TagsInput(props: any) {

    const [tags, setTags] = useState<Array<string>>([]);
    const [keywords, setKeywords] = useState<Array<string>>(["hii", "hello", "rishabh", "aman", "geography", "science", "maths", "isthmus", "year"]);
    const [suggestions, setSuggestions] = useState<Array<string>>([]);
    const [inputBoxKeywordValue, setInputBoxKeywordValue] = useState<string>();
    // To show error
    const keywordRedux: any = useSelector((state: any) => state.keywords);

    const [keywordErr, setKeywordErr] = useState<ValidationResponse>({
        key: 'keyword',
        errors: []
    });

    /**
       * function to handle key down
      * @returns {void}
       */
    const handlekeyDown = (e: any) => {
        if (e.key !== 'Enter') {
            return
        }
        let keywordErrorResp: ValidationResponse = Validate('keyword', e.target.value);
        if (keywordErrorResp.errors.length) {
            setKeywordErr(keywordErrorResp);
            return;
        }
        const value = e.target.value;

        if (!value.trim()) return
        let newTags = [...tags, value];
        setTags([...tags, value])
        props.onChangeKeywords(newTags);
        e.target.value = "";
        setInputBoxKeywordValue('');


    }

    /**
       * function to remove keyword
      * @returns {void}
       */
    const removeTag = (index: number) => {
        let newTags = tags.filter((el, i) => i !== index)
        setTags(tags.filter((el, i) => i !== index));
        props.onChangeKeywords(newTags);
    }

    /**
       * function to change keyword
      * @returns {void}
       */
    const onchangekeywordHandler = (text: any) => {
        let keywordErrorResp: ValidationResponse = Validate('keyword', text);
        if (keywordErrorResp.errors.length) {
            setKeywordErr(keywordErrorResp);
            return;
        }
        setKeywordErr({
            key: 'keyword',
            errors: []
        });
        let matches: Array<string> = [];
        if (text.length > 0) {
            matches = keywords.filter(word => {
                const regex = new RegExp(`${text}`, "gi");
                return word.match(regex);
            })
        }
        // console.log(matches);
        setSuggestions(matches);
        setInputBoxKeywordValue(text);

    }

    /**
   * function to set keyword from suggested keyword array
  * @returns {void}
   */
    const onClickOfSuggestedKeyowrd = (suggestedKeyword: string) => {
        let newTags = [...tags];
        newTags.push(suggestedKeyword);
        setTags(newTags);
        props.onChangeKeywords(newTags);
        setInputBoxKeywordValue('');
        setSuggestions([]);
    }

    useEffect(() => {
        // console.log("props.tags", props.tags)

        setTags([...tags, ...props.tags])
        let keywordArr: Array<string> = [];
        for (let i = 0; i < keywordRedux.length; i++) {
            keywordArr.push(keywordRedux[i].keywordName);
        }
        setKeywords(keywordArr);
        // console.log("props come tags", tags)

    }, [props.tags])


    return (
        // Parent container
        <div>
            <div className="tags-input-container-search w-100">
                {
                    tags.map((tags, index) => (
                        <div className="tag-item" key={index}>
                            <span className="text">
                                {tags}
                            </span>
                            {/* <span className="close" onClick={() => removeTag(index)}>
                                &times;
                            </span> */}
                            <i className="fas fa-times-circle close" onClick={() => removeTag(index)}></i>
                        </div>

                    ))
                }
                <input onKeyDown={(e) => handlekeyDown(e)} onChange={(e) => onchangekeywordHandler(e.target.value)} type="text"
                    className='tags-input' placeholder='Type Something' value={inputBoxKeywordValue} />
                {/* Error to be shown */}
                {keywordErr.errors.length ? <span className="text-danger">{keywordErr.errors[0].message}</span> : <></>}
            </div>
            {suggestions && suggestions.map((suggestion, i) =>
                <div key={i} id="input-box-keyword" className="suggestions" onClick={() => onClickOfSuggestedKeyowrd(suggestion)}>{suggestion}</div>
            )
            }
        </div>
    );
}

export default TagsInput; 