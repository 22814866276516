import  { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


export default class OptionWithModal extends Component<any> {

    componentDidMount() {
        this.setState({
            editorState: EditorState.moveFocusToEnd(this.state.editorState), // EditorState imported from draft-js
        });
    }

    state = {
        editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
                htmlToDraft(this.props.opt).contentBlocks,
                htmlToDraft(this.props.opt).entityMap
            )
        ),
    };

    onEditorStateChange = (editorState: any) => {
        this.setState({
            editorState,
        });
        this.props.getOptionInputHandler(draftToHtml(
            convertToRaw(this.state.editorState.getCurrentContent())
        ));


    };

    
    render() {
        const { editorState } = this.state;
        // const htmlData: any = draftToHtml(
        //     convertToRaw(this.state.editorState.getCurrentContent())
        // );
        
        return (
            <div>
                <div className="border-textEditor">
                   <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                            options: ['inline', 'fontSize', 'fontFamily', 'textAlign', 'history', 'list'],
                            fontFamily: {
                                options: ['Arial', 'AlternateGothic', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                            }
                        }}
                    />
                    
                </div>
            </div>

        );
    }

}