import Layout from "./Layout";
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import TagsInput from "./TagsInput";
import { ValidationResponse } from "../interfaces/error";
import { Validate } from "../utils/validation";
import { IPpClue, IQuesDetails } from "../interfaces/create-new-questions/PP/IQuesDetails";
import { IKeywordReq, IOptions, IQuesId } from "../interfaces/create-new-questions/IQuesDetails";
import { useAuth0 } from "@auth0/auth0-react";
import ModalComponent from "./common/ModalComponent";
import TextEditorForCreatePp from "./TextEditorForCreatePp";
import TextEditorForPpHostInfo from "./TextEditorForPpHostInfo";
import Ikeyword from "../interfaces/IKeyword";
import { ICreatePpQuesReqBody } from "../interfaces/create-new-questions/PP/ICreatePpQuesReqBody";
import { ISaveQues } from "../interfaces/others/ISaveQues";
import { CREATE_QUES_APIS_URL } from "../config/config";
import LoadingSpinner from "./common/LoadingSpinner";
import TextEditorForCreateQuestionPp from "./TextEditorForCreateQuestionPp";
import SaveKeywords from "../utils/SaveKeywords";
import { useDispatch } from 'react-redux';


interface IOption {
    key: number;
    option: string;
}
//axios for api calling
const axios = require('axios').default;

function CreateNewPPQuestion() {
    const dispatch = useDispatch();

    const { getAccessTokenSilently } = useAuth0();
    // const mainClass = "mainWithoutNav";;
    const [textAreaNo, setTextAreaNo] = useState(1);
    const [options,] = useState<Array<IOption>>([]);
    const [questText, setOuesText] = useState("");
    const [hostInfo, setHostInfo] = useState<Array<IOption>>([]);
    const [clueInfo, setClueInfo] = useState<Array<IOption>>([]);
    const [keywords, setKeywords] = useState<Array<string>>([]);
    const [quesErr, setQuesErr] = useState<ValidationResponse>({
        key: 'question',
        errors: []
    });
    const [optionsErr, setOptErr] = useState<ValidationResponse>({
        key: 'clue',
        errors: []
    });

    const [, setRefresh] = useState(false);
    const keywordRedux: any = useSelector((state: any) => state.keywords);
    //get username from userDetail Redux Data
    const userDetailReduxdata: any = useSelector((state: any) => state.userDetailReducer);
    //state to show modal on save button click
    const [saveQuesModal, setSaveQuesModal] = useState<ISaveQues>({
        showModal: false,
        message: 'Question not saved in database',
        icon: 'fas fa-window-close team-red-modal-icon fa-2x'
    });
    const [showLoader, setShowLoader] = useState(false);
    const addOptions = (e: any) => {
        e.preventDefault();

    }


    const onChangeKeywords = (tags: Array<string>) => {
        setKeywords(tags);
    }


    const onDeleteClue = (id: number) => {
        let oldClues = [...clueInfo];
        let updatedClues = oldClues.filter((item, i) => i !== id);
        setClueInfo(updatedClues);
        let oldHostInfos = [...hostInfo];
        let updatedHostinfos = oldHostInfos.filter((item, i) => i !== id);
        setHostInfo(updatedHostinfos);
        let newTextAreaCount = textAreaNo;
        newTextAreaCount--;
        if (newTextAreaCount !== 0) {
            setTextAreaNo(newTextAreaCount);
        }
        setRefresh(c => !c);
    }


    const onQuestionInputHandler = (data: any) => {
        let questionErrorResp: ValidationResponse = Validate('ppAnswer', data);

        setQuesErr(questionErrorResp);

        setOuesText(data);
    }

    const onHostInfoChange = (val: string, id: any) => {

        let newHostArr = [...hostInfo];
        newHostArr[id] = {
            key: id,
            option: val
        }
        setHostInfo(newHostArr);
    }

    const onClueInfoChange = (val: string, id: any) => {
        let clueErrorResp: ValidationResponse = Validate('singleClue', val);
        setOptErr(clueErrorResp);
        let newClueArr = [...clueInfo];
        newClueArr[id] = {
            key: id,
            option: val
        }
        setClueInfo(newClueArr);
    }

    const onSaveQuestion = async () => {
        let questionErrorResp: ValidationResponse = Validate('ppAnswer', questText);
        let optionsErrorResp: ValidationResponse = Validate('clue', clueInfo);
        if (questionErrorResp.errors.length && optionsErrorResp.errors.length) {
            setQuesErr(questionErrorResp);
            setOptErr(optionsErrorResp);
        }
        //question-text-validation
        if (questionErrorResp.errors.length) {
            setQuesErr(questionErrorResp);
            return;
        }
        //option validation
        if (optionsErrorResp.errors.length) {
            setOptErr(optionsErrorResp);
            return
        }

        let ansArr: Array<string> = [];
        for (let i = 0; i < options.length; i++) {
            ansArr.push(options[i].option);
        }


        //get keywords from redux 
        let keywordReq: Array<IKeywordReq> = [];
        //iterate over the keywords store in the redux to get the id
        //if keyword found then set its id ,otherwise set id is -1
        for (let i = 0; i < keywords.length; i++) {
            let keywordFound = keywordRedux.find((o: Ikeyword) => o.keywordName === keywords[i]);
            if (keywordFound) {
                keywordReq.push({
                    keywordId: keywordFound.keywordId,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
            } else {
                keywordReq.push({
                    keywordId: -1,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
            }
        }

        let optionsForReq: Array<IOptions> = [];
        optionsForReq.push({
            optionNo: 1,
            optionDescription: questText,
            correctOption: true,
            createdBy: userDetailReduxdata,
            updatedBy: userDetailReduxdata
        })

        let quesTypeForReq: Array<IQuesId> = [];
        quesTypeForReq.push({
            questionTypeId: 4
        })
        let ppClues: Array<IPpClue> = [];
        clueInfo.map((value, idx) => {
            ppClues.push({
                clueNo: `${idx + 1}`,
                clueDescription: value.option,
                hostInfo: hostInfo[idx] ? hostInfo[idx].option : '',
                createdBy: userDetailReduxdata,
                updatedBy: userDetailReduxdata

            })
            return null
        });
        let quesDetails: IQuesDetails = {
            question: questText,
            createdBy: userDetailReduxdata,
            updatedBy: userDetailReduxdata,
            questionTypes: quesTypeForReq,
            options: optionsForReq,
            keywords: keywordReq,
            ppClueMaster: ppClues

        }
        let createNewQuesPpReqBody: ICreatePpQuesReqBody = {
            questionDetails: quesDetails,
        };

        console.log("PP_QUES", createNewQuesPpReqBody);
        let config = await getHeader();
        setShowLoader(true);
        //api calling to post data
        axios.post(`${CREATE_QUES_APIS_URL}`, createNewQuesPpReqBody, config)
            .then(async function (response: any) {
                setShowLoader(false);
                //get the response
                console.log("Create APi Result Succesfully Received", response);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                saveModal.icon = 'fas fa-check-square fa-2x team-reg-modal-icon';
                saveModal.message = response.data.description;
                setSaveQuesModal(saveModal);

                const token = await getAccessTokenSilently();
                // Function to store keyword data in redux
                const keywordsData = await SaveKeywords(token);

                dispatch(keywordsData);

            })
            .catch(function (error: any) {
                setShowLoader(false);
                //catch the response
                console.log("CREATE_API_ERROR", error.message);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                setSaveQuesModal(saveModal);
            })


    }

    const getHeader = async () => {
        // const user: any = localStorage.getItem('items');//user name from local storage
        const token = await getAccessTokenSilently();
        console.log(token);
        let config = {
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            }
        }
        return config;
    }

    const onClickOkSaveBtnValidModal = () => {
        let saveModal = { ...saveQuesModal };
        saveModal.showModal = false;
        setSaveQuesModal(saveModal);
        window.location.reload();
    }


    const showData = () => {
        return (
            <div className="card-body">
                <div className="col-12 mt-2">
                    <div className="row ">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Ponderous Puzzle Answer:</label>
                        <div className="col-lg-11 col-sm-12">
                            <TextEditorForCreateQuestionPp onQuestionInputHandler={onQuestionInputHandler} id={-1} opt={'<p></p>'} />
                            {quesErr.errors.length ? <span className="text-danger">{quesErr.errors[0].message}</span> : <></>}
                        </div>
                    </div>
                </div>


                {[...Array(textAreaNo)].map((e, i) => (
                    <>

                        <div className="row mt-4">

                            <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">{`Clue${i + 1}:`}</label>
                            <div className="col-lg-6 col-sm-12" onChange={e => addOptions(e)}>
                                <TextEditorForCreatePp onQuestionInputHandler={onClueInfoChange} id={i} opt={clueInfo[i] ? clueInfo[i].option : '<p></p>'} />
                            </div>


                            <div className="col-lg-5 col-sm-12">
                                <div className="row gx-3">
                                    <label className="col-lg-2 col-sm-12 text-start fs-6 col-form-label">{`Host Info:`}</label>
                                    <div className="col-lg-9 col-sm-12">
                                        <TextEditorForPpHostInfo onQuestionInputHandler={onHostInfoChange} id={i} opt={hostInfo[i] ? hostInfo[i].option : '<p></p>'} />
                                    </div>
                                    <div className="col-lg-1 py-2 px-2" >
                                        {/* <i className="fas fa-times text-center" onClick={() => onDeleteClue(i)}></i> */}
                                        <i className="fas fa-trash trash-hover" onClick={() => onDeleteClue(i)}></i>
                                    </div>
                                </div>
                            </div>

                        </div>




                    </>
                ))}

                <div className="row mt-4">
                    <div className="col-lg-1 col-sm-12"></div>
                    <div className="col-lg col-sm-12">
                        {optionsErr.errors.length ? <span className="text-danger">{optionsErr.errors[0].message}</span> : <></>}
                    </div>
                </div>

                <div className="col-12">
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-11">
                            <button className="btn btn-success" onClick={() => setTextAreaNo(textAreaNo + 1)}>Add Clue</button>
                        </div>
                    </div>
                </div>

                <div className="col-12 mt-2">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Keyword:</label>
                        <div className="col-lg-11 col-sm-12">
                            <TagsInput onChangeKeywords={onChangeKeywords} />
                        </div>
                    </div>

                </div>



                <div className="text-end">
                    <button className="btn btn-success" onClick={onSaveQuestion}>Save Question</button>
                </div>
            </div>
        )
    }


    useEffect(() => {

    }, [textAreaNo, options, quesErr, keywords])


    return (
        <div id="main"  className="withoutSideBarNav">
            <ModalComponent isShowModal={saveQuesModal.showModal} onHandleOkBtn={onClickOkSaveBtnValidModal} text={saveQuesModal.message} icon={saveQuesModal.icon} />
            <div className="pagetitle">
                <h1>Create Question</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                        <li className="breadcrumb-item active">Ponderous Puzzle</li>
                    </ol>
                </nav>
            </div>

            <Layout></Layout>
            <div className="card p-2">
                {showLoader ? <LoadingSpinner /> : showData()}
            </div>

        </div>

    );


}

export default CreateNewPPQuestion;