import {combineReducers} from "redux";
import modalReducer from "./modalReducer";
import ppreducer from "./ppReducer";
import ppVisibilityReducer from "./ppBtnVisibility";
import activeCompReducer from "./activeCompReducer";
import scoreEditorState from "./scoreEditorReducer";
import gameInfoReducer from "./gameInfoReducer";
import createContestStateReducer from "./createContestStateReducer";
import mainClassReducer from "./mainClassReducer";
import sideNavbarVisibilityReducer from "./sideNavbarVisibilityReducer";
import userDetailReducer from "./userDetailReducer";
import headerConfigreducer from "./headerConfig";
import questionSearchState from "./questionSearchReducer";
import keywords from "./keywordReducer";
import questionTypesReducer from "./questionTypeReducer";
import populateOldTeamReducer from "./populateOldTeamReducer";


const reducers = combineReducers({
    modal:modalReducer,
    pp:ppreducer,
    ppVisibility:ppVisibilityReducer,
    activeComp:activeCompReducer,
    scoreEditorState:scoreEditorState,
    gameInfo:gameInfoReducer,
    createContestState:createContestStateReducer,
    sideNavbarVisibilty:sideNavbarVisibilityReducer,
    mainClassReducer:mainClassReducer,
    userDetailReducer:userDetailReducer,
    headerConfigreducer:headerConfigreducer,
    questionSearchState:questionSearchState,
    keywords:keywords,
    questionTypesReducer:questionTypesReducer,
    populateOldTeamReducer:populateOldTeamReducer
})
export default reducers;