import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { USER_APIS_URL } from "../../config/config";
// import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../state/index';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../common/LoadingSpinner";
const axios = require('axios').default;


const Auth0Login = () => {
  const { user, loginWithRedirect, isAuthenticated,getAccessTokenSilently,isLoading,error} = useAuth0();

  const [items,] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const display =()=>{
    
return (
  <>
  
  <div  className="d-flex align-items-center justify-content-center">

        <Button className="mt-5 btn-block success col-md-4" onClick={() => loginWithRedirect({login_hint: "",
        prompt: 'login',})}>
          Login
        </Button>
        </div>
        <br/>
        {error && error.name ? <span className="d-flex align-items-center justify-content-center text-danger">Please verify your email first</span>:null}
        </>
)
  }
  const showPage = () => {
    if (isLoading) {
      return <div className="d-flex align-items-center justify-content-center">
        {<LoadingSpinner/>}
      </div>
    }
    console.log(isAuthenticated);
    
    if (!isAuthenticated) {

      return <div>
        {display()}

      </div>
    }
    else {

      const email = user?.email;
      const nickName = user?.nickname;
      localStorage.setItem('items', JSON.stringify(nickName));
      localStorage.setItem('email', JSON.stringify(email));
      dispatch(actionCreators.userDetail(nickName));

      let role = user ? Object.values(user)[0] : "";

      onUserLogin(email, role);
      

      redirect();
    }
  }

  const redirect = () => {
    return navigate("/dashboard");
  }

  const onUserLogin = async(email: any, role: any) => {

    let userData = {
      email,
      role
    }


    const token = await getAccessTokenSilently();
    console.log(token);
    let config = {
      headers: {
        Authorization: "Bearer "+token,
        "content-type": "application/json",
      }
    }
    dispatch(actionCreators.configForHeader(config));


    axios.post(`${USER_APIS_URL}`, userData,config)
      .then(function (response: any) {
        console.log(`Response Received${response}`);
      })
      .catch(function (error: any) {
        console.log('failure');
      });
  }


  useEffect(() => {

    localStorage.setItem('items', JSON.stringify(items));
  }, [items]);


  return (
    <div>
      {showPage()}
    </div>
  )
};

export default Auth0Login;
