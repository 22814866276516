import "react-bootstrap";
import Layout from "../Layout";
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { ValidationResponse } from "../../interfaces/error";
import { Validate } from "../../utils/validation";
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../state/index';
import { QUESTION_SEARCH_APIS_URL, QUESTION_DETAIL_APIS_URL, CREATE_QUES_APIS_URL } from "../../config/config";
import { useAuth0 } from "@auth0/auth0-react";
import { Modal } from 'react-bootstrap';
import QuestionEdit from "./QuestionEdit";
import PpAnswerEdit from "./ppAnswerEdit";
import TLAnswerEdit from './TLAnswerEdit';
import LoadingSpinner from "../common/LoadingSpinner";
import ModalComponent from "../common/ModalComponent";
import { Table } from 'antd';
import { ISaveQues } from "../../interfaces/others/ISaveQues";
import type { PaginationProps } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import type { SizeType } from 'antd/es/config-provider/SizeContext';


import 'antd/dist/reset.css'; // or 'antd/dist/antd.less'

const axios = require('axios').default;




function QuestionSearch() {

    const dispatch = useDispatch();

    interface IKeywordsData {
        keywordId: number;
        keywordName: string;
        createdBy: string;
    }

    interface IClueMasterData {
        clueId: number;
        questionId: number;
        clueNo: number;
        clueDescription: string;
        hostInfo: string;
        createdBy: string;
        updatedBy: string;
    }

    interface IQuestionsData {
        questionId: number;
        question: string;
        difficulty: string;
        hostInfo: string;
        createdOn: string,
        updatedOn: string,
        questionTypes: Array<IQuestionTypeData>;
        options: Array<IOptionsData>;
        ppClueMaster: Array<IClueMasterData>;
        keywords: Array<IKeywordsData>;
        appearedInQuiz: Array<appearedInQuiz>;
    }


    interface appearedInQuiz {

        quizId: number;
        quizName: string;
        createdBy: string;

    }


    interface IQuestionTypeData {

        questionTypeId: number;
        questionTypeName: string;
    }

    interface IOptionsData {
        optionId: number,
        questionId: number,
        optionNo: number,
        optionDescription: string,
        correctOption: boolean,
        createdBy: string,
        updatedBy: string
    }


    interface ISuggestedKeyword {
        keywordId: number;
        keywordName: string;
    }

    interface selectedQuizData {
        quizId: number;
        quizText: string;
        quizQuestionId: number;
    }

    let initialDetail: Array<IQuestionsData> = [];

    const keywordRedux: any = useSelector((state: any) => state.keywords);
    const questionTypesRedux: any = useSelector((state: any) => state.questionTypesReducer);

    const { getAccessTokenSilently } = useAuth0();
    const [showModalOfSearchFailed, setshowModalOfSearchFailed] = useState(false);
    const [showModalOfDeleteQuestion, setshowModalOfDeleteQuestion] = useState(false);
    const [showModalOfDeleteQuestionDescription, setshowModalOfDeleteQuestionDescription] = useState("");

    // const mainClass = "mainWithoutNav";;
    const [tags, setTags] = useState<Array<string>>([]);
    const [tagIds, setTagIds] = useState<Array<number>>([]);

    const [questionError, SetquestionError] = useState<ValidationResponse>({
        key: 'question',
        errors: []
    });
    const [searchDisable, setDisable] = useState(false);
    const [showHideComp, setshowHideComp] = useState(false);
    const [DisplayData, setDisplayData] = useState(initialDetail);

    const [currentquestionId, setCurrentquestionId] = useState(1);


    const [DisplayDetail, setDisplayDetail] = useState({});

    const [suggestions, setSuggestions] = useState<Array<ISuggestedKeyword>>([]);

    const [suggestedKeywords, setSuggestedKeywords] = useState<Array<ISuggestedKeyword>>([]);

    const type = ["Single Choice", "Multiple Choice", "Round 1", "Jackpot", "Tie-break", "Last Man Standing", "Multimedia", "Cryptic Clue"];
    const PPType = ["Ponderous Puzzle"];
    const TLType = ["The List"];
    const [question, setQuestion] = useState("");
    const [difficulty, setDifficulty] = useState("");
    const [questionType, setQuestionType] = useState<Array<String>>([]);
    const [, setshowHideCompTL] = useState(false);
    const [ShowPPComp, setShowPPComp] = useState(false);
    const [showTLComp, setShowTLComp] = useState(false);

    const [inputBoxKeywordValue, setInputBoxKeywordValue] = useState<string>();

    const [saveQuesModal, setSaveQuesModal] = useState<ISaveQues>({
        showModal: false,
        message: 'Question detail not found from database',
        icon: 'fas fa-window-close team-red-modal-icon fa-2x'
    });
    const [, setQuizID] = useState(null);
    const [quizName, setQuizName] = useState("");
    const [, setQuizQuestionID] = useState(null);
    const [quizSelectedId, setQuizSelectedId] = useState(null);
    const [selectedQuizData, setSelectedQuizData] = useState<Array<selectedQuizData>>([])
    const [showLoader, setShowLoader] = useState(false);
    const [showLoaderSearch, setShowLoaderSearch] = useState(false);
    const [showNoticeModal, setShowNoticeModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showHardDeleteModal, setShowHardDeleteModal] = useState(false);
    const [bordered,] = useState(false);
    const [loading, setLoading] = useState(false);
    const [size, ] = useState<SizeType>('large');
    const [showHeader, ] = useState(true);
    const [tableLayout, ] = useState(undefined);
    const [top, ] = useState<TablePaginationPosition | 'none'>('none');
    const [bottom, ] = useState<TablePaginationPosition>('bottomRight');
    const [page, setPage] = useState(1);
    const [pageSizeCount, setPageSize] = useState(10);
    const [selectedQuestionId, setSelectedQuestionId] = useState(0);
    const [selectedQuestionType, setSelectedQuestionType] = useState<Array<String>>([]);




    type TablePaginationPosition =
        | 'topLeft'
        | 'topCenter'
        | 'topRight'
        | 'bottomLeft'
        | 'bottomCenter'
        | 'bottomRight';


    /**
    * function to set table for showing all question data
    * @returns {void}
    */
    const columns: ColumnsType<IQuestionsData> = [
        {
            title: 'Question',
            dataIndex: 'question',
            width: '45%',
            render: (text: string) => <div className="text-start">
                <label className="question-label" dangerouslySetInnerHTML={{ __html: text }}></label></div>,
        },
        {
            title: 'Question Type',
            dataIndex: 'questionTypes',
            width: '15%',
            render: (questionTypes: string[]) => (
                <>

                    <select className="form-select m-1 pc-1 labels text-truncate" id="questionType">
                        {
                            questionTypes.map((Value: any) => (

                                <option selected>{Value.questionTypeName}</option>

                            ))
                        }

                    </select>
                </>
            )
        },
        {
            title: 'Created Date',
            dataIndex: 'createdOn',
            sorter: (a: any, b: any) => b.createdOn < a.createdOn ? 1 : -1,
            width: '15%',
        },
        {
            title: 'Last Edited Date',
            dataIndex: 'updatedOn',
            width: '15%',
            sorter: (a: any, b: any) => b.updatedOn < a.updatedOn ? 1 : -1,
        },
        {
            title: 'Appeared in Quiz',
            dataIndex: 'appearedInQuiz',
            width: '15%',
            render: (appearedInQuiz: Array<appearedInQuiz>, record) => (
                <>
                    <select className="form-select m-1 pc-1 labels text-truncate" name="appearedInQuiz" id="appearedInQuiz" onChange={(e) => setQuiz(e.target, record)}>


                        {appearedInQuiz.length > 0 ? <option value=''>Select Quiz</option> : ''}

                        {appearedInQuiz.map((Value: any) => (

                            <option value={Value.quizId}>{Value.quizName}</option>

                        ))}

                    </select>
                </>
            )
        },
        {
            title: '',
            dataIndex: 'questionId',
            width: '5%',
            render: (text: number, record) => (
                <>
                    <div className="col-1 py-2">
                        <i className="fas fa-trash trash-hover text-center" onClick={() => removeQuestion(text, record)}></i>
                    </div>
                </>
            )
        },
    ];


    const tableProps: TableProps<IQuestionsData> = {
        bordered,
        loading,
        size,
        showHeader,
        tableLayout,
    };


    /**
   * function execute on size change
  * @returns {void}
   */
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        console.log(current, pageSize);
        setPageSize(pageSize);
        setPage(current);
    };



    const showTotal: PaginationProps['showTotal'] = total => `Total ${total} items`;

    /**
       * function execute on page change
      * @returns {void}
       */
    const onChange: PaginationProps['onChange'] = pageNumber => {
        console.log('Page: ', pageNumber);
        setPage(pageNumber);
    };



    /**
       * function to show all question related to the search
      * @returns {void}
       */
    const showData = () => {
        let tableDash =
            <div>

                <Table
                    {...tableProps}

                    rowKey={(record) => record.questionId}
                    pagination={{
                        position: [top as TablePaginationPosition, bottom],
                        onShowSizeChange,
                        showTotal,
                        onChange,
                        defaultCurrent: page,
                        defaultPageSize: pageSizeCount
                    }}
                    columns={columns}
                    dataSource={DisplayData}
                    scroll={{
                        y: 300,
                    }}

                    rowSelection={{
                        type: 'radio',
                        ...rowSelection,
                    }}
                    summary={() => (
                        <Table.Summary>
                            <Table.Summary.Row>


                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                    sticky
                />

            </div>
        return (
            tableDash
        )
    }


    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: IQuestionsData[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            handleQuestionDetail(selectedRows[0].questionId, selectedRows[0].questionTypes)
        },
        getCheckboxProps: (record: IQuestionsData) => ({

            disabled: record.question === 'Disabled User', // Column configuration not to be checked
            name: record.question,
        }),

    };


    /**
   * function to get value from question input field
  * @returns {void}
   */
    const getValueFromInputquestion = (val: any) => {
        var e = val.trim()
        if (e !== '' && e.length > 3) {

            setDisable(false);

            SetquestionError({
                key: 'question',
                errors: []
            });


        } else {
            let validationResponse: ValidationResponse = Validate('questionSearch', e);
            SetquestionError(validationResponse);
            dispatch(actionCreators.questionSearchState({
                question: e,
            }));
            // dispatch(actionCreators.openModal(true));

            setDisable(true);

            if (validationResponse.errors.length === 0) {
                setDisable(false);

            }

        }

    }


    /**
   * function to set quiz
   * @returns {void}
   */
    const setQuiz = (targerContent: any, record: any) => {

        var quizId = targerContent.value;

        var quizText = targerContent.options[targerContent.selectedIndex].text;

        var id = selectedQuizData.length;

        if (selectedQuizData.length > 0) {
            selectedQuizData.map((val: any, i: number) => {
                if (val.quizQuestionId === record.questionId && quizId !== '' && quizId !== null) {
                    selectedQuizData[i] = {
                        quizId: quizId,
                        quizText: quizText,
                        quizQuestionId: record.questionId,
                    }
                } else if (quizId === '') {

                    var selectedQuizDataArr = selectedQuizData.filter((val, i) => val.quizQuestionId !== record.questionId)
                    setSelectedQuizData(selectedQuizDataArr);

                }
                else {


                    let selectedQuizDataArr = [...selectedQuizData];
                    selectedQuizDataArr[id] = {
                        quizId: quizId,
                        quizText: quizText,
                        quizQuestionId: record.questionId,
                    }
                    setSelectedQuizData(selectedQuizDataArr);
                }
                return null
            })




        } else {
            let selectedQuizDataArr = [...selectedQuizData];
            selectedQuizDataArr[id] = {
                quizId: quizId,
                quizText: quizText,
                quizQuestionId: record.questionId,
            }
            setSelectedQuizData(selectedQuizDataArr);

        }

    }


    /**
   * function to remove question
  * @returns {void}
   */
    const removeQuestion = async (questionId: number, record: any) => {
        setShowNoticeModal(false);
        setQuizSelectedId(null);

        setCurrentquestionId(questionId);


        if (record.appearedInQuiz.length === 0) {
            console.log("1111")

            setQuizID(null);
            setQuizName("");
            setQuizSelectedId(null);

            setShowHardDeleteModal(true);
        } else if (selectedQuizData.length > 0) {
            var count = 0;

            selectedQuizData.map((val: any, i: number) => {
                if (val.quizQuestionId === questionId) {
                    count++;
                    setQuizSelectedId(val.quizId);
                    setQuizName(val.quizText);
                    setShowDeleteModal(true);
                }
                return null
            })

            if (count > 0) {

                setShowDeleteModal(true);
            } else {

                setQuizQuestionID(null)
                setQuizSelectedId(null);
                setShowNoticeModal(true);
            }



        } else if (quizSelectedId === null) {
            console.log("444444")

            setQuizQuestionID(null)
            setQuizSelectedId(null);
            setShowNoticeModal(true);
        } else {
            setQuizQuestionID(null)
            setQuizSelectedId(null);
            setShowNoticeModal(true);
        }
    }


    /**
   * function execute on delete question
  * @returns {void}
   */
    const proceedModal = async () => {

        let questionId = currentquestionId;
        setShowDeleteModal(false);
        setShowHardDeleteModal(false);
        setShowNoticeModal(false);
        const token = await getAccessTokenSilently();
        let config = {
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            },
            params: {
                questionId: questionId,
                quizId: quizSelectedId,
            }
        }


        axios.delete(`${CREATE_QUES_APIS_URL}`, config)
            .then(function (response: any) {
                console.log('Response Received', response);
                console.log('Response Description Received', response.data.description);

                setshowModalOfDeleteQuestion(true);
                setshowModalOfDeleteQuestionDescription(response.data.message);
                handleSearchData();

            })
            .catch(
                function (error: any) {

                    console.log(`Response Failure ${error}`);

                }
            )
    }

    /**
       * function execute on cancel of modal
      * @returns {void}
       */
    const cancelModal = () => {
        setShowDeleteModal(false);
        setShowHardDeleteModal(false);
        setShowNoticeModal(false);
        setshowModalOfDeleteQuestion(false);

    }


    /**
   * function execute on clear the search request
  * @returns {void}
   */

    function handleClearFilter() {
        setQuestionType([]);
        setQuestion("");
        setDifficulty("");
        setTags([]);
        setDisplayData(initialDetail);
        setshowHideComp(false);
        setShowPPComp(false)
        setShowTLComp(false)
    }

    /**
       * function execute on key down while filling the search form
      * @returns {void}
       */
    const handlekeyDown = (e: any) => {
        if (e.key !== 'Enter') {
            return
        }
    }

    /**
       * function execute to remove keyword
      * @returns {void}
       */
    const removeTag = (index: number) => {
        setDisable(false);

        setTags(tags.filter((el, i) => i !== index));
        setTagIds(tagIds.filter((el, i) => i !== index));

        if (question.length < 3 && question.length > 0) {
            setDisable(true);
        }
    }


    useEffect(() => {
        let newOptionArr = [...suggestedKeywords];

        for (let i = 0; i < keywordRedux.length; i++) {
            newOptionArr[i] = {
                keywordId: keywordRedux[i].keywordId,
                keywordName: keywordRedux[i].keywordName,
            }

        }
        setSuggestedKeywords(newOptionArr)

    }, [])

    /**
   * function execute to set the difficulty
  * @returns {void}
   */
    const setDifficultyFn = (val: any) => {

        setDifficulty("")

        if (val) {
            setDifficulty(val)

        }

    }

    /**
       * function to set the headers
      * @returns {void}
       */
    const getHeader = async () => {
        const email: any = localStorage.getItem('email');
        const token = await getAccessTokenSilently();

        let config = {
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            }
            ,
            params: {
                createdByEmail: JSON.parse(email?.toString())
            }
        }
        return config;
    }

    /**
       * function to set the headers for search api
      * @returns {void}
       */
    const getHeaderForSearch = async (question: string, difficulty: string, keywordFilter: number[], questionTypeFilter: String[]) => {

        const token = await getAccessTokenSilently();
        let config = {
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            }
            ,
            params: {
                searchString: question,
                difficulty: difficulty,
                keywordFilter: keywordFilter?.toString(),
                questionTypeFilter: questionTypeFilter?.toString()
            }
        }
        return config;
    }


    /**
     * function to close registration modal
     * @returns {void}
   */
    const closeRegistartionFailModal = () => {
        setshowModalOfSearchFailed(false);
    }

    /**
       * function to handle search
       * @returns {void}
    */
    const handleSearch = async () => {

        setshowHideComp(false);
        setShowPPComp(false);
        setDisplayData(initialDetail);
        setShowTLComp(false);
        setLoading(true);

        setSelectedQuizData([]);
        if (question !== '') {
            let questionErrorResponse: ValidationResponse = Validate('questionSearch', question);
            if (questionErrorResponse.errors.length) {
                SetquestionError(questionErrorResponse);
                return;
            }
        }

        let config = await getHeaderForSearch(question, difficulty, tagIds, questionType);
        console.log("config", config)

        axios.get(`${QUESTION_SEARCH_APIS_URL}`, config)
            .then(function (response: any) {

                console.log(JSON.stringify(response.data));
                var resDtata = response.data.message
                if (resDtata.length > 0) {
                    setLoading(false);

                    setDisplayData(response.data.message);
                }
                else {
                    let saveModal = { ...saveQuesModal };
                    saveModal.showModal = true;
                    saveModal.icon = 'fas fa-info-circle fa-2x info-search-modal-icon';
                    saveModal.message = "No data found";
                    setSaveQuesModal(saveModal);
                    setLoading(false);
                    setDisplayData(initialDetail);

                }
            })
            .catch(function (error: any) {
                // for design only 
                console.log("search api error", error);
                setshowModalOfSearchFailed(true);
                setLoading(false);
            });
    }

    /**
       * function to set all result after search
       * @returns {void}
    */
    const handleSearchData = async () => {

        setshowHideComp(false);
        setShowPPComp(false);
        setDisplayData(initialDetail);
        setShowTLComp(false);
        setShowLoaderSearch(true);
        setSelectedQuizData([]);
        if (question !== '') {
            let questionErrorResponse: ValidationResponse = Validate('questionSearch', question);
            if (questionErrorResponse.errors.length) {
                SetquestionError(questionErrorResponse);
                return;
            }
        }

        let config = await getHeaderForSearch(question, difficulty, tagIds, questionType);
        console.log("config", config)

        axios.get(`${QUESTION_SEARCH_APIS_URL}`, config)
            .then(function (response: any) {

                console.log(JSON.stringify(response.data));
                var resDtata = response.data.message
                if (resDtata.length > 0) {
                    setShowLoaderSearch(false);
                    console.log("DisplayDataDisplayDataDisplayDataDisplayData", page);
                    setDisplayData(response.data.message);
                }
                else {
                    let saveModal = { ...saveQuesModal };
                    saveModal.showModal = true;
                    saveModal.icon = 'fas fa-info-circle fa-2x info-search-modal-icon';
                    saveModal.message = "No data found";
                    setSaveQuesModal(saveModal);
                    setShowLoaderSearch(false);

                    setDisplayData(initialDetail);

                }
            })
            .catch(function (error: any) {
                // for design only 
                console.log("search api error", error);
                setshowModalOfSearchFailed(true);
                setShowLoaderSearch(false);
            });
    }

    /**
       * function to get type of question
       * @returns {void}
       */
    const getQuestionType = (e: any) => {
        // to find out if it's checked or not; returns true or false
        const checked = e.target.checked;
        setDisable(false);

        // to get the checked value
        const checkedValue = e.target.value;
        if (checked) {

            if (!questionType.includes(checkedValue)) {
                setQuestionType([...questionType, checkedValue]);
            }

            if (question.length < 3 && question.length > 0) {
                setDisable(true);
            }

            // console.log("questionType.length", questionType.length)
            // if (questionType.length >= 0 && (question.length === 0 || question.length > 3)) {
            //     setDisable(false);
            // } else 
            // if (questionType.length < 1 && (question.length === 0 || question.length > 3) && tags.length < 1 && difficulty !== '') {
            //     setDisable(true);
            // }

        } else {

            let newState = questionType;
            let newArr = newState.filter((i) => i !== checkedValue);

            setQuestionType(newArr);

            console.log("questionTypequestionType deselect", questionType);

            if (question.length < 3 && question.length > 0) {
                setDisable(true);
            }

            // if (questionType.length === 1 && (question.length === 0 || question.length > 3) && tags.length < 1 && difficulty === '') {
            //     setDisable(true);
            // }

        }
    }

    /**
       * function to get data related to a particular question
       * @returns {void}
    */
    const handleQuestionDetail = async (id: number, question_type_detail: any) => {
        setSelectedQuestionId(id);
        setSelectedQuestionType(question_type_detail);
        setshowHideComp(false);
        setShowPPComp(false);
        setDisplayDetail([{}]);
        setshowHideCompTL(false)
        setShowTLComp(false)
        let config = await getHeader();
        setShowLoader(true);
        axios.get(`${QUESTION_DETAIL_APIS_URL}/${id}`, config)
            .then(function (response: any) {

                console.log(JSON.stringify(response.data));
                var resData = response.data.message;
                let found = false;
                let TLTypefound = false;
                let PPTypefound = false;
                setDisplayDetail(resData);

                if (question_type_detail.length > 0) {
                    found = type.includes(question_type_detail[0].questionTypeName);
                    TLTypefound = TLType.includes(question_type_detail[0].questionTypeName);
                    PPTypefound = PPType.includes(question_type_detail[0].questionTypeName);
                    setshowHideComp(true);
                    setShowLoader(false);
                    setShowPPComp(false);
                    setshowHideCompTL(false)
                    setShowTLComp(false);
                    if (found) {
                        setshowHideComp(true);
                        setShowLoader(false);
                        setShowPPComp(false);
                        setshowHideCompTL(false)
                        setShowTLComp(false);
                    }

                    else if (TLTypefound) {

                        setshowHideComp(false);
                        setShowPPComp(false);
                        setshowHideCompTL(false)
                        setShowLoader(false);
                        setShowTLComp(true)
                    }

                    else if (PPTypefound) {

                        setShowPPComp(true);
                        setshowHideComp(false);
                        setshowHideCompTL(false)
                        setShowLoader(false);
                        setShowTLComp(false)


                    }
                } else {
                    setshowHideComp(true);
                    setShowLoader(false);
                    setShowPPComp(false);
                    setshowHideCompTL(false)
                    setShowTLComp(false);
                }

            })
            .catch(function (error: any) {
                // for design only
                setshowModalOfSearchFailed(true);
                setShowLoader(false);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                setSaveQuesModal(saveModal);
            });


    }


    const onClickSaveBtnValidModal = () => {
        let saveModal = { ...saveQuesModal };
        saveModal.showModal = false;
        setSaveQuesModal(saveModal);
        // window.location.reload();
    }

    /**
      * function to set keyword on clicking suggested keyword list
      * @returns {void}
    */
    const onClickOfSuggestedKeyowrd = (suggestedKeyword: string, keywordId: number) => {
        let newTags = [...tags];
        newTags.push(suggestedKeyword);
        setTags(newTags);

        let newTagIds = [...tagIds];
        newTagIds.push(keywordId);
        setTagIds(newTagIds);

        setInputBoxKeywordValue("");
        setSuggestions([]);

    }


    /**
      * function returns list of keywords related to the input given by user
      * @returns {Array}
    */
    const onchangekeywordHandler = (text: any) => {

        let matches: Array<ISuggestedKeyword> = [];

        if (text.length > 0) {
            matches = suggestedKeywords.filter(word => {
                const regex = new RegExp(`${text}`, "gi");
                return word.keywordName.match(regex);
            })
        }
        // console.log("matches matches matches matches", matches);
        setSuggestions(matches);
        setInputBoxKeywordValue(text);
    }

    /**
     * function to update question
     * @returns {void}
    */
    const onQuestionUpdate = () => {
        handleSearch();
        handleQuestionDetail(selectedQuestionId, selectedQuestionType)
    }


    return (
        <>


            <Modal show={showDeleteModal} >
                <Modal.Body className="text-center">
                    <i className="fas fa-question-circle quit-modal"></i>
                    <p className="p-txt">Are you sure you want to delete this question from the quiz <strong>{quizName}</strong> ? </p>
                    <div>
                        <button className="btn btn-success text-left m-2" onClick={proceedModal}>Yes</button>
                        <button className="btn btn-secondary" onClick={cancelModal}>No</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showHardDeleteModal} >
                <Modal.Body className="text-center">
                    <i className="fas fa-question-circle quit-modal"></i>
                    <p className="p-txt">Are you sure you want to delete this question permanently? </p>
                    <div>
                        <button className="btn btn-success text-left m-2" onClick={proceedModal}>Yes</button>
                        <button className="btn btn-secondary" onClick={cancelModal}>No</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showNoticeModal} >
                <Modal.Body className="text-center">
                    {/* <i className="fas fa-question-circle quit-modal"></i> */}
                    <i className="fas fa-solid fa-exclamation-circle team-red-modal-icon"></i>
                    <p className="p-txt">Please select a quiz to delete the question </p>
                    <div>
                        <button className="btn btn-success text-left m-2" onClick={cancelModal}>Ok</button>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={showModalOfSearchFailed} >
                <Modal.Body className="text-center">
                    <i className="fas fa-window-close team-red-modal-icon"></i>
                    <p className="p-txt">Something went wrong!</p>
                    <div>
                        {/* <button className="btn bg-primary text-white text-left m-2" onClick={OnTryAgain}>Try Again</button> */}
                        {/* <button className="btn btn-secondary" onClick={closeRegistartionFailModal}>Cancel</button> */}
                        <button className="btn bg-primary text-white text-left m-2" onClick={closeRegistartionFailModal}>ok</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showModalOfDeleteQuestion} >
                <Modal.Body className="text-center">
                    <i className="fas fa-check-square fa-2x team-reg-modal-icon"></i>
                    <p className="p-txt">{showModalOfDeleteQuestionDescription}</p>
                    <div>
                        {/* <button className="btn bg-primary text-white text-left m-2" onClick={OnTryAgain}>Try Again</button> */}
                        {/* <button className="btn btn-secondary" onClick={closeRegistartionFailModal}>Cancel</button> */}
                        <button className="btn btn-success text-white text-left m-2 " onClick={cancelModal}>ok</button>
                    </div>
                </Modal.Body>
            </Modal>



            <Layout />


            <div id="main"  className="withoutSideBarNav">

                <ModalComponent isShowModal={saveQuesModal.showModal} onHandleOkBtn={onClickSaveBtnValidModal} text={saveQuesModal.message} icon={saveQuesModal.icon} />

                <div className="pagetitle">

                    <div>
                        <h1>Search</h1>

                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li className="breadcrumb-item active">Search</li>
                            </ol>
                        </nav>
                    </div>
                    {/* <div className="d-flex gap-8 justify-content-end action-button">
                        <button type="button" className="btn btn-warning py-1" >create</button>
                    </div> */}
                </div>

                {/* <!-- End Page Title --> */}
                <section className="section">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div className="card m-0">
                                <div className="card-body">


                                    <div className="mb-4 row">
                                        <label htmlFor="KeywordName" className="col-sm-12 col-lg-1 col-form-label text-left">Search:</label>
                                        <div className="col-sm-12 col-lg-11">
                                            <div className="form-group">
                                                <input id="" type="text" className="form-control" placeholder="Enter Question" value={question}
                                                    onChange={(e) => setQuestion(e.target.value)} onKeyUp={e => getValueFromInputquestion(e.currentTarget.value)} />
                                                {questionError.errors.length ? <span className="text-danger">{questionError.errors[0].message}</span> : <></>}

                                            </div>
                                        </div>

                                    </div>


                                    <div className="mb-4 row">
                                        <label htmlFor="QuestionName" className="col-sm-12 col-lg-1 col-form-label">Keyword:</label>
                                        <div className="col-sm-12 col-lg-11">
                                            <div className="form-group">
                                                <div className="tags-input-container-search w-100 " style={{ "padding": ".375rem .75rem" }}>
                                                    {
                                                        tags.map((tags, index) => (
                                                            <div className="tag-item-search" key={index}>
                                                                <span className="text">
                                                                    {tags}
                                                                </span>
                                                                <i className="fas fa-times-circle keyword-search-ques" onClick={() => removeTag(index)}></i>
                                                            </div>

                                                        ))
                                                    }
                                                    {/* <input onKeyDown={handlekeyDown} type="text" className='form-group tags-input-search p-0' placeholder=' Type Something' /> */}
                                                    <input onKeyDown={(e) => handlekeyDown(e)} onChange={(e) => onchangekeywordHandler(e.target.value)} type="text" className='tags-input' placeholder='Type Something' value={inputBoxKeywordValue}
                                                    />
                                                </div>
                                                {suggestions && suggestions.map((suggestion, i) =>
                                                    <div key={i} id="input-box-keyword" className="suggestions" onClick={() => onClickOfSuggestedKeyowrd(suggestion.keywordName, suggestion.keywordId)}>{suggestion.keywordName}</div>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </div>




                                    <div className="mb-4 row">
                                        <label htmlFor="questionType" className="col-sm-12 col-lg-1 col-form-label">Difficulty:</label>
                                        <div className="col-sm-12 col-lg-5">
                                            <select className="form-select" id="questionType" onChange={(e) => setDifficultyFn(e.target.value)} defaultValue={difficulty}>
                                                <option value="">Select Difficulty Level</option>
                                                <option value="Easy" selected={difficulty === "Easy"}>Easy</option>
                                                <option value="Medium" selected={difficulty === "Medium"}>Medium</option>
                                                <option value="Hard" selected={difficulty === "Hard"}>Hard</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="mb-4 row">
                                        <div className="col-sm-12 col-lg-1 col-form-label">
                                            <label htmlFor="QuestionName">Question Type:</label>
                                        </div>

                                        <div className="col-lg-11 col-sm-12 d-flex">
                                            <div className="radio-btn-create-question">

                                                {questionTypesRedux.map((questionTypesRedux: any, i: number) => (

                                                    <div className="me-2" key={i}>
                                                        <input type="checkbox" className="me-1" id={questionTypesRedux.questionTypeName} name="standard" value={questionTypesRedux.questionTypeId}
                                                            checked={questionType.includes(questionTypesRedux.questionTypeId.toString())} onChange={(e) => getQuestionType(e)} />
                                                        <label htmlFor={questionTypesRedux.questionTypeName}>{questionTypesRedux.questionTypeName}</label>
                                                    </div>

                                                ))}

                                            </div>


                                        </div>

                                    </div>


                                    <div className="mb-4 row">
                                        <div className="col-md-6 col-lg-6 d-flex justify-content-end action-button text-right">

                                        </div>
                                        <div className="col-md-6 col-lg-6 d-flex justify-content-end action-button text-right">
                                            <button type="button" className="m-1 btn btn-success" onClick={handleClearFilter}>Clear Filters</button>

                                            <button type="button" className="m-1 btn btn-success" disabled={searchDisable} onClick={handleSearch}>Search</button>
                                        </div>
                                    </div>

                                    {showLoaderSearch ? <LoadingSpinner /> :

                                        <div className="mb-4 row">
                                            <div className="col-sm-12 col-lg-1 col-form-label">
                                                <label htmlFor="KeywordName">Results:</label>
                                            </div>
                                            <div className="col-sm-12 col-lg-11">
                                                <div className=" form-group">

                                                    {showData()}


                                                </div>
                                            </div>
                                        </div>

                                    }
                                </div>

                            </div>
                            {showLoader ? <div className="mt-3 card m-0">
                                <div className="card-body">
                                    <LoadingSpinner />
                                </div>
                            </div> : <></>}
                            {showHideComp && <QuestionEdit DisplayDetail={DisplayDetail} onQuestionUpdate={onQuestionUpdate} />}
                            {ShowPPComp && <PpAnswerEdit DisplayDetail={DisplayDetail} onQuestionUpdate={onQuestionUpdate} />}
                            {showTLComp && <TLAnswerEdit DisplayDetail={DisplayDetail} onQuestionUpdate={onQuestionUpdate} />}

                        </div>


                    </div>



                </section>
            </div>
        </>
    );
}

export default QuestionSearch;
