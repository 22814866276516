import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ALL_QUIZ_APIS_URL, DELET_QUIZ_CODE_APIS_URL, DELET_QUIZ_APIS_URL, GENEARTE_QUIZ_CODE_APIS_URL } from "../../config/config";
import Layout from "../Layout";
import { Modal } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
import LoadingSpinner from "../common/LoadingSpinner";
import type { PaginationProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import moment from "moment";


const axios = require('axios').default;
function QuizList() {



  interface IQuestionsData {
    quizId: number;
    quizName: string;
    quizCode: number;
    createdAt: string;
    associatedGame: number;
  }

  let initialDetail: Array<IQuestionsData> = [];


  type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';


  // const mainClass = "mainWithoutNav";;
  let initState = "Currently there are no active quizzes";
  const [state, setState] = useState(initState);
  const [btnClass, setBtnClass] = useState({
    btn1: "btn btn-primary w-80 rounded-0 display-none",
    btn2: "btn btn-primary w-80 rounded-0"
  });
  const { getAccessTokenSilently } = useAuth0();
  const [gettingData, setgettingData] = useState(true);
  const [refresh, setRefresh] = useState(false);



  const [DisplayData, setDisplayData] = useState(initialDetail);

  const [top,] = useState<TablePaginationPosition | 'none'>('none');
  const [bottom,] = useState<TablePaginationPosition>('bottomRight');
  const [page, setPage] = useState(1);
  const [pageSizeCount, setPageSize] = useState(10);
  const [showDeleteQuizCodeModal, setShowDeleteQuizcodeModal] = useState(false);
  const [quizIdForDelete, setQuizIdForDelete] = useState<number>();
  const [showDeleteQuizModal, setShowDeleteQuizModal] = useState(false);


  /**
    * function execute on click of delete quiz button
   * @returns {void}
    */
  const onBtnClickOfdeleteQuiz = (quizId: number) => {
    setShowDeleteQuizModal(true);
    setQuizIdForDelete(quizId);

  }

  /**
    * function execute on click of yes button of modal of delete quiz
   * @returns {void}
    */
  const proceedDeleteQuizModal = async () => {
    const token = await getAccessTokenSilently();
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      }
    }
    setShowDeleteQuizModal(false);
    setgettingData(true);
    axios.delete(`${DELET_QUIZ_APIS_URL}/${quizIdForDelete}`, config)
      .then(function (response: any) {

        console.log(`Response Received${response} from delete quiz api`);
        console.log(JSON.stringify(response.data));

      })
      .catch(function (error: any) {
        console.log(`Response Failure for delete quiz api:${error}`);
      })
      .finally(function () {
        setgettingData(false);
        setRefresh(c => !c);
      });
  }

  /**
    * function execute on click of No button of modal of delete quiz
   * @returns {void}
    */
  const cancelDeleteQuizModal = () => {
    setShowDeleteQuizModal(false);
  }



  /**
   * function execute on click of generate code button
  * @returns {void}
   */
  const onBtnClickOfGenerateCode = async (quizId: number) => {
    //setgettingData(true);
    const token = await getAccessTokenSilently();
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      }
    }

    await axios.put(`${GENEARTE_QUIZ_CODE_APIS_URL}/${quizId}`, {}, config)
      .then(function (response: any) {
        console.log(`Response Received${response}`);
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error: any) {
        console.log(`Response Failure ${error}`);
      })
      .finally(function () {
        //setgettingData(false);
        setRefresh(c => !c);
      });

  }

  /**
   * function execute on click of yes button of modal of delete quiz code
  * @returns {void}
   */
  const proceedDeleteQuizCodeModal = async () => {
    const token = await getAccessTokenSilently();
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      }
    }
    setShowDeleteQuizcodeModal(false);
    setgettingData(true);
    axios.delete(`${DELET_QUIZ_CODE_APIS_URL}/${quizIdForDelete}`, config)
      .then(function (response: any) {

        console.log(`Response Received${response} from delete quiz code api`);
        console.log(JSON.stringify(response.data));

      })
      .catch(function (error: any) {
        console.log(`Response Failure for delete quiz api:${error}`);
      })
      .finally(function () {
        setgettingData(false);
        setRefresh(c => !c);
      });
  }

  /**
     * function execute on click of No button of modal of delete quiz Code
    * @returns {void}
     */
  const cancelDeleteQuizCodeModal = () => {
    setShowDeleteQuizcodeModal(false);
  }



  /**
   * function to fetch all quiz data
  * @returns {Array}
   */
  async function apiCalling() {
    const token = await getAccessTokenSilently();
    const email: any = localStorage.getItem('email');
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      },
      params: {
        createdByEmail: JSON.parse(email?.toString())
      }
    }
    setgettingData(true);
    axios.get(`${ALL_QUIZ_APIS_URL}`, config)
      .then(function (response: any) {

        console.log(`Response Received${response}`);
        console.log(JSON.stringify(response.data));
        // let newState ={...state};
        // newState.data = response.data;
        if (response.data.message) {

          // if (response.data.message.length != 0) {
          console.log("data", response.data.message);
          setState(JSON.stringify(response.data.message));
          setDisplayData(response.data.message);

          setBtnClass({
            btn1: "btn btn-primary w-80 rounded-0",
            btn2: "btn btn-primary w-80 rounded-0 display-none"
          });

        } else {
          //  setRefresh(c=>!c);
          setState(initState);
          setBtnClass({
            btn1: "btn btn-primary w-80 rounded-0 display-none",
            btn2: "btn btn-primary w-80 rounded-0"
          });
        }
        // setgettingData(false);

      })
      .catch(function (error: any) {

        console.log(`Response Failure ${error}`);

      })
      .finally(function () {

        setgettingData(false);
      });

  }

  useEffect(() => {
    apiCalling();
  }, [refresh]);



  /**
     * function to show list of quizzes
     * @returns {HtmlHTMLAttributes}
     */
  const showData = () => {
    if (state === "Currently there are no active quizzes") {
      return <p>{state}</p>
    } else {
      // let arr = JSON.parse(state);
      let tableDash =

        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="d-flex justify-content-between pt-3 pb-1">
              <h1>List of Quiz</h1>
              <div className="search-bar">
                <Link to="/file-upload" state={{ isNewGame: true }}>
                  <button type="button" className={btnClass.btn1}>
                    Import Quiz
                  </button>
                </Link>
              </div>
            </div>
            <div className="section">
              <div className="row">
                <div className="col-lg-12 p-0">

                  <div className="col-sm-12 col-lg-12">
                    <div className=" form-group">

                      {showQuizData()}


                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>




      return (
        tableDash
      )
    }
  }


  /**
   * function to determine whether the quizcode is null or not
   * @returns {boolean}
   */
  const isQuizCodeNullOrNot = (record: any) => {
    return record.quizCode === null ? true : false;
  };


  /**
   * function to check whether the associated game is null or not 
   * @returns {Table}
   */
  const isAssociatedGameNullOrNot = (record: any) => {
    return record.gamesCount === 0 ? true : false;
  };


  /**
   * function to show modal on clicking delete quiz button
   */
  const onBtnClickOfdeleteQuizCode = (quizId: number) => {
    // props.onBtnClickOfdeleteQuizCode(quizId);
    setShowDeleteQuizcodeModal(true);
    setQuizIdForDelete(quizId);
  };



  /**
   * function to make component of table structure
   * @returns {Table}
   */
  const columns: ColumnsType<IQuestionsData> = [
    {
      title: 'Quiz Name',
      dataIndex: 'quizName',
      width: '25%',
      render: (text: string) => <div className="text-start">{text}</div>,
      sorter: (a: any, b: any) => b.quizName < a.quizName ? 1 : -1,
    },
    {
      title: 'Quiz Code',
      dataIndex: 'quizCode',
      render: (text: number) => <div className="text-center">{text}</div>,
      width: '20%',
    },
    {
      title: 'Created At',
      dataIndex: 'createdOn',
      width: '20%',
      render: (text: number) => <div className="text-center">{moment(text).format("YYYY-MM-DD")}</div>,
      sorter: (a: any, b: any) => b.createdOn < a.createdOn ? 1 : -1,
    },
    {
      title: 'Action',
      dataIndex: 'quizId',
      width: '25%',
      render: (text: number, record) => (
        <>
          <div className="g-0 h-100 actionIcons d-flex gap-8 text-end">
            <button className="btn btn-danger" disabled={isQuizCodeNullOrNot(record)}
              onClick={() => onBtnClickOfdeleteQuizCode(text)}>
              Delete Code
            </button>
            <button className="btn btn-primary" disabled={!isQuizCodeNullOrNot(record)}
              onClick={() => onBtnClickOfGenerateCode(text)}>
              Generate Code
            </button>
            <div className="tooltipQuizes">
              <button className="btn btn-danger" onClick={() => onBtnClickOfdeleteQuiz(text)} disabled={!isAssociatedGameNullOrNot(record)}>
                Delete Quiz
              </button>
              {!isAssociatedGameNullOrNot(record) ? <span className="tooltiptext">Quiz can be deleted only when no game is associated.</span> : <></>}
            </div>
          </div>
        </>
      )
    }
  ];


  /**
   * function to change size of current page in pagination
   * @returns {Table}
   */
  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    console.log(current, pageSize);
    setPageSize(pageSize);
    setPage(current);
  };

  /**
     * function to show total count of data in pagination
     * @returns {Table}
     */
  const showTotal: PaginationProps['showTotal'] = total => `Total ${total} items`;

  /**
     * function to switch on different page in pagination
     * @returns {Table}
     */
  const onChange: PaginationProps['onChange'] = pageNumber => {
    console.log('Page: ', pageNumber);
    setPage(pageNumber);
  };


  /**
   * function to show all quizzes
   * @returns {table}
   */
  const showQuizData = () => {
    let tableDash =
      <div>
        <Table
          rowKey={(record) => record.quizId}
          pagination={{
            position: [top as TablePaginationPosition, bottom],
            onShowSizeChange,
            showTotal,
            onChange,
            defaultCurrent: page,
            defaultPageSize: pageSizeCount
          }}
          columns={columns}
          dataSource={DisplayData}
          summary={() => (
            <Table.Summary>
              <Table.Summary.Row>


              </Table.Summary.Row>
            </Table.Summary>
          )}
          sticky
        />
      </div>
    return (
      tableDash
    )
  }




  return (
    <>
      <Layout />
      <div id="main"  className="withoutSideBarNav">
        <Modal show={showDeleteQuizModal} >
          <Modal.Body className="text-center">
            <i className="fas fa-question-circle quit-modal"></i>
            <p className="p-txt">Are you sure you want to delete this quiz from your records? </p>
            <div>
              <button className="btn btn-success text-left m-2" onClick={proceedDeleteQuizModal}>Yes</button>
              <button className="btn btn-secondary" onClick={cancelDeleteQuizModal}>No</button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={showDeleteQuizCodeModal} >
          <Modal.Body className="text-center">
            <i className="fas fa-question-circle quit-modal"></i>
            <p className="p-txt">Are you sure you want to delete quiz code? </p>
            <div>
              <button className="btn btn-success text-left m-2" onClick={proceedDeleteQuizCodeModal}>Yes</button>
              <button className="btn btn-secondary" onClick={cancelDeleteQuizCodeModal}>No</button>
            </div>
          </Modal.Body>
        </Modal>
        <div className="pagetitle">
          <h1>Manage Quizzes</h1>
          <nav>
            <ol className="breadcrumb">
              {/* <li className="breadcrumb-item"><a href="/dashboard">Home</a></li> */}
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item active">Manage Quizzes</li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="p-0 col-lg-12 text-center gap">
              <div className="ms-auto action-button">

              </div>
              {gettingData ? <LoadingSpinner /> : showData()}
              <div className="col-lg-2 col-md-6 col-sm-12 py-1 m-auto action-button">
                <Link to="/file-upload" state={{ isNewGame: true }}>
                  <button type="button" className={btnClass.btn2}>
                    Import Quiz
                  </button>
                </Link>
              </div>

            </div>

          </div>
        </section>
      </div>
    </>
  );
}

export default QuizList;