import Layout from "./Layout";
import TextEditor from "./TextEditor";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Options from "./Options";
import TagsInput from "./TagsInput";
import { ValidationResponse } from "../interfaces/error";
import { Validate } from "../utils/validation";
import { IQuesDetails } from "../interfaces/create-new-questions/IQuesDetails";
import { useAuth0 } from "@auth0/auth0-react";
import ModalComponent from "./common/ModalComponent";
import { ICreateTheListQuesReqBody, IKeywordReq, IOptions, IQuesId } from "../interfaces/create-new-questions/TheList/ICreateTheListQuesReqBody";
import Ikeyword from "../interfaces/IKeyword";
import { ISaveQues } from "../interfaces/others/ISaveQues";
import { CREATE_QUES_APIS_URL } from "../config/config";
import LoadingSpinner from "./common/LoadingSpinner";
import SaveKeywords from "../utils/SaveKeywords";
import { useDispatch } from 'react-redux';

interface IOption {
    key: number;
    option: string;
}
//axios for api calling
const axios = require('axios').default;

function CreateNewTheListQuestion() {
    const dispatch = useDispatch();

    const { getAccessTokenSilently } = useAuth0();
    // const mainClass = "mainWithoutNav";;
    const [textAreaNo, setTextAreaNo] = useState(1);
    const [options, setOptions] = useState<Array<IOption>>([]);
    const [questText, setOuesText] = useState("");
    const [hostInfo, setHostInfo] = useState("");
    const [diffculty, setDifficulty] = useState("");
    const [keywords, setKeywords] = useState<Array<string>>([]);
    const [quesErr, setQuesErr] = useState<ValidationResponse>({
        key: 'question',
        errors: []
    });
    const [optionsErr, setOptErr] = useState<ValidationResponse>({
        key: 'options',
        errors: []
    });

    //get data of keywords From Redux
    const keywordRedux: any = useSelector((state: any) => state.keywords);

    //get username from userDetail Redux Data
    const userDetailReduxdata: any = useSelector((state: any) => state.userDetailReducer);
    //state to show modal on save button click
    const [saveQuesModal, setSaveQuesModal] = useState<ISaveQues>({
        showModal: false,
        message: 'Question not saved in database',
        icon: 'fas fa-window-close team-red-modal-icon fa-2x'
    });
    const [showLoader, setShowLoader] = useState(false);
    const addOptions = (e: any) => {
        e.preventDefault();

    }
    const onChangeKeywords = (tags: Array<string>) => {
        setKeywords(tags);
    }

    const onDeleteItemFromOptions = (id: number) => {
        let oldOptions = [...options];
        let updatedOptions = oldOptions.filter((item, i) => i !== id);
        setOptions(updatedOptions);
        let newTextAreaCount = textAreaNo;
        newTextAreaCount--;
        if (newTextAreaCount !== 0) {
            setTextAreaNo(newTextAreaCount);
        }

    }


    const handleInputChange = (val: string, id: any) => {

        let optionsErrorResp: ValidationResponse = Validate('listopt', val);
        setOptErr(optionsErrorResp);
        let newOptionArr = [...options];
        newOptionArr[id] = {
            key: id,
            option: val
        }
        setOptions(newOptionArr);
    }


    const onQuestionInputHandler = (data: any) => {
        let questionErrorResp: ValidationResponse = Validate('question', data);

        setQuesErr(questionErrorResp);

        setOuesText(data);
    }


    const onHostInfoChange = (e: any) => {
        setHostInfo(e);
    }


    const setSelectedDifficultyLevel = (e: string) => {
        if (e === "Select Difficulty level") {
            setDifficulty("");
        } else {
            setDifficulty(e);
        }

    }


    const onSaveQuestion = async () => {
        let questionErrorResp: ValidationResponse = Validate('question', questText);
        let optionsErrorResp: ValidationResponse = Validate('listoptions', options);
        if (questionErrorResp.errors.length && optionsErrorResp.errors.length) {
            setQuesErr(questionErrorResp);
            setOptErr(optionsErrorResp);
        }
        //question-text-validation
        if (questionErrorResp.errors.length) {
            setQuesErr(questionErrorResp);
            return;
        }
        //option validation
        if (optionsErrorResp.errors.length) {
            setOptErr(optionsErrorResp);
            return
        }

        let ansArr: Array<string> = [];
        for (let i = 0; i < options.length; i++) {
            ansArr.push(options[i].option);
        }
        //get keywords from redux 
        let keywordReq: Array<IKeywordReq> = [];
        //iterate over the keywords store in the redux to get the id
        //if keyword found then set its id ,otherwise set id is -1
        for (let i = 0; i < keywords.length; i++) {
            let keywordFound = keywordRedux.find((o: Ikeyword) => o.keywordName === keywords[i]);
            if (keywordFound) {
                keywordReq.push({
                    keywordId: keywordFound.keywordId,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
            } else {
                keywordReq.push({
                    keywordId: -1,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
            }
        }

        let optionsForReq: Array<IOptions> = [];
        //iterate over the options array and prepare options for req body
        for (let index = 0; index < ansArr.length; index++) {
            optionsForReq.push({
                optionNo: index + 1,
                optionDescription: ansArr[index],
                correctOption: true,
                createdBy: userDetailReduxdata,
                updatedBy: userDetailReduxdata
            })
        }

        let quesTypeForReq: Array<IQuesId> = [];
        quesTypeForReq.push({
            questionTypeId: 7
        })
        let quesDetails: IQuesDetails = {
            question: questText,
            difficulty: diffculty,
            hostInfo: hostInfo,
            createdBy: userDetailReduxdata,
            updatedBy: userDetailReduxdata,
            questionTypes: quesTypeForReq,
            keywords: keywordReq,
            options: optionsForReq,
        }

        let createNewQuesReqBody: ICreateTheListQuesReqBody = {
            questionDetails: quesDetails
        };
        console.log("CREATE_THE_LIST QUES_REQ", createNewQuesReqBody);
        let config = await getHeader();
        setShowLoader(true);
        //api calling to post data
        axios.post(`${CREATE_QUES_APIS_URL}`, createNewQuesReqBody, config)
            .then(async function (response: any) {
                setShowLoader(false);
                //get the response
                console.log("Create The List APi Result Succesfully Received", response);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                saveModal.icon = 'fas fa-check-square fa-2x team-reg-modal-icon';
                saveModal.message = response.data.description;
                setSaveQuesModal(saveModal);

                const token = await getAccessTokenSilently();
                // Function to store keyword data in redux
                const keywordsData = await SaveKeywords(token);

                dispatch(keywordsData);

            })
            .catch(function (error: any) {
                setShowLoader(false);
                //catch the response
                console.log("CREATE_API_ERROR", error.message);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                setSaveQuesModal(saveModal);
            })



    }

    /**
       * function to set the headers
      * @returns {void}
       */
    const getHeader = async () => {
        // const user: any = localStorage.getItem('items');//user name from local storage
        const token = await getAccessTokenSilently();
        console.log(token);
        let config = {
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            }
        }
        return config;
    }

    const onClickSaveBtnValidModal = () => {
        let saveModal = { ...saveQuesModal };
        saveModal.showModal = false;
        setSaveQuesModal(saveModal);
        window.location.reload();
    }
    const showData = () => {
        return (
            <div className="card-body">

                <div className="col-12 mt-2">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Question:</label>
                        <div className="col-lg-11 col-sm-12">
                            <TextEditor onQuestionInputHandler={onQuestionInputHandler} quesErr={quesErr} />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-2 ">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Answers</label>
                        <div className="col-lg-11 col-sm-12" onChange={e => addOptions(e)}>
                            {[...Array(textAreaNo)].map((e, i) => (

                                <Options elementId={i} onDeleteItemFromOptions={onDeleteItemFromOptions} handleInputChange={handleInputChange} opt={options[i] ? options[i].option : ""} />
                            ))}
                            {optionsErr.errors.length ? <span className="text-danger">{optionsErr.errors[0].message}</span> : <></>}
                        </div>

                    </div>

                </div>

                <div className="col-12 mt-2">
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-11">
                            <button className="btn btn-success" onClick={() => setTextAreaNo(textAreaNo + 1)}>Add Answer</button>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-2 ">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Host Info:</label>
                        <div className="col-lg-11 col-sm-12">

                            <TextEditor onQuestionInputHandler={onHostInfoChange} />
                        </div>
                    </div>

                </div>
                <div className="col-12 mt-2">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Keyword:</label>
                        <div className="col-lg-11 col-sm-12">
                            <TagsInput onChangeKeywords={onChangeKeywords} />
                        </div>
                    </div>

                </div>
                <div className="col-12 mt-2">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Difficulty:</label>
                        <div className="col-lg-11 col-sm-12">
                            <select id="selectRound" className="form-select w-50" onChange={e => setSelectedDifficultyLevel(e.target.value)}>
                                <option value="">Select Difficulty level</option>
                                <option value="Easy">Easy</option>
                                <option value="Medium">Medium</option>
                                <option value="Hard">Hard</option>
                            </select>
                        </div>
                    </div>

                </div>

                <div className="text-end">
                    <button className="btn btn-success" onClick={onSaveQuestion}>Save Question</button>
                </div>
            </div>
        )
    }
    return (
        <div id="main"  className="withoutSideBarNav">
            <ModalComponent isShowModal={saveQuesModal.showModal} onHandleOkBtn={onClickSaveBtnValidModal} text={saveQuesModal.message} icon={saveQuesModal.icon} />
            <div className="pagetitle">
                <h1>Create Question</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                        <li className="breadcrumb-item active">The List</li>
                    </ol>
                </nav>
            </div>

            <Layout></Layout>
            <div className="card p-2">
                {showLoader ? <LoadingSpinner /> : showData()}
            </div>

        </div>

    );


}

export default CreateNewTheListQuestion;