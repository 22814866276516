import { useState, useEffect } from 'react';
import "react-bootstrap";
import TextEditor from "./TextEditor";
import Options from "./Options";
import { useAuth0 } from "@auth0/auth0-react";
import { ValidationResponse } from "../../interfaces/error";
import { Validate } from "../../utils/validation";
import { IKeywordReq, IOptions, IQuesId, IQuesDetails } from "../../interfaces/update_question/IQuesDetails";
import TagsInput from './TagsInput';
import TextEditorHost from './TextEditorHost';
import { useSelector } from 'react-redux';
import Ikeyword from "../../interfaces/IKeyword";
import { IUpdateQuesReqBody } from "../../interfaces/update_question/IUpdateQuesReqBody";
import { QUESTION_SEARCH_APIS_URL } from "../../config/config";
import { ISaveQues } from "../../interfaces/others/ISaveQues";
import ModalComponent from "../common/ModalComponent";

const axios = require('axios').default;


function TLAnswerEdit(props: any) {

    interface IOption {
        key: number;
        option: string;
        isActive: boolean;
        createdBy: string;
    }

    interface IDelOption {
        key: number;
        option: string;
        isActive: boolean;
        createdBy: string;
    }


    const { getAccessTokenSilently } = useAuth0();
    // const [type, setType] = useState<Array<string>>([
    //     "Single Choice", "Multiple Choice", "Round 1", "Jackpot", "Tie-break", "Last Man Standing", "Multimedia", "Cryptic Clue"
    // ]);
    // const [PPType, setPPType] = useState<Array<string>>([
    //     "Ponderous Puzzle"
    // ]);
    // const [TLType, setTLType] = useState<Array<string>>([
    //     "The List"
    // ]);

    const userDetailReduxdata: any = useSelector((state: any) => state.userDetailReducer);
    const keywordRedux: any = useSelector((state: any) => state.keywords);


    const [textAreaNo, setTextAreaNo] = useState(1);
    const [options, setOptions] = useState<Array<IOption>>([]);
    const [deletedOptions, setDeletedOptions] = useState<Array<IDelOption>>([]);

    const [tags, setTags] = useState<Array<string>>([]);
    const [questText, setQuesText] = useState("");
    const [hostInfo, setHostInfo] = useState("");
    const [difficulty, setDifficulty] = useState("");
    const [keywords, setKeywords] = useState<Array<string>>([]);
    const [tagIds, setTagIds] = useState<Array<string>>([]);

    const [quesErr, setQuesErr] = useState<ValidationResponse>({
        key: 'question',
        errors: []
    });
    const [saveQuesModal, setSaveQuesModal] = useState<ISaveQues>({
        showModal: false,
        message: 'Question not updated in database',
        icon: 'fas fa-window-close team-red-modal-icon fa-2x'
    });
    const [optionsErr, setOptErr] = useState<ValidationResponse>({
        key: 'options',
        errors: []
    });
    const [, setShowLoader] = useState(false);


    const addOptions = (e: any) => {
        e.preventDefault();

    }



    const handleInputChange = (val: string, elementId: number, id: any, createdBy: any) => {

        let optionsErrorResp: ValidationResponse = Validate('answer', val);
        setOptErr(optionsErrorResp);

        let newOptionArr = [...options];
        newOptionArr[id] = {
            key: elementId,
            option: val,
            isActive: true,
            createdBy: createdBy,
        }


        setOptions(newOptionArr);
    }


    const onDeleteItemFromOptions = (elementId: number, id: number, val: string, createdBy: any) => {
        let oldOptions = [...options];
        let updatedOptions = oldOptions.filter((item, i) => i !== id);
        setOptions(updatedOptions);
        if (elementId !== -1) {
            let delOptionArr = [...deletedOptions];
            delOptionArr[deletedOptions.length] = {
                key: elementId,
                option: val,
                isActive: false,
                createdBy: createdBy,
            }

            setDeletedOptions(delOptionArr);
        }


        let newTextAreaCount = textAreaNo;
        newTextAreaCount--;
        if (newTextAreaCount !== 0) {
            setTextAreaNo(newTextAreaCount);
        }

    }


    useEffect(() => {
        console.log("props.DisplayDetail", props.DisplayDetail)

        setQuesText(props.DisplayDetail.question);
        let tagArray: Array<string> = []
        props.DisplayDetail.keywords.map((val: any, i: number) => {
            tagArray.push(val.keywordName)
            return null
        })

        setTags([...tags, ...tagArray]);

        let keywordArr: Array<string> = [];
        for (let i = 0; i < keywordRedux.length; i++) {
            keywordArr.push(keywordRedux[i].keywordName);
        }
        setKeywords([...keywords, ...tagArray])
        setHostInfo(props.DisplayDetail.hostInfo);

        setDifficulty(props.DisplayDetail.difficulty)

        let tagIdArray: Array<string> = []

        props.DisplayDetail.keywords.map((val: any, i: number) => (
            tagIdArray.push(val.keywordId)
        ))
        setTagIds([...tagIds, ...tagIdArray])


        let newOptionArr = [...options];

        props.DisplayDetail.options.map((val: any, i: number) => {
            newOptionArr[i] = {
                key: val.optionId,
                option: val.optionDescription,
                isActive: val.isActive,
                createdBy: val.createdBy,
            }
            return null
        }
        )

        let oldOptions = [...newOptionArr];
        let updatedOptions = oldOptions.filter((item, i) => item.isActive === true);
        setOptions(updatedOptions);

        let delOptions = [...newOptionArr];
        let delOptionArr = delOptions.filter((item, i) => item.isActive === false);
        setDeletedOptions(delOptionArr);
        setTextAreaNo(updatedOptions.length);
    }, [])


    /**
       * function to set the headers
      * @returns {void}
       */
    const getHeader = async () => {
        // const user: any = localStorage.getItem('items');//user name from local storage
        const token = await getAccessTokenSilently();

        let config = {
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            }
        }
        return config;
    }


    const onUpdateQuestion = async () => {

        let questionErrorResp: ValidationResponse = Validate('question', questText);


        let optionsErrorResp: ValidationResponse = Validate('answer', options);
        setOptErr(optionsErrorResp);

        // let alloptionsErrorResp: ValidationResponse = Validate('options', options);
        // setOptErr(alloptionsErrorResp);

        if (questionErrorResp.errors.length && optionsErrorResp.errors.length) {
            setQuesErr(questionErrorResp);
            setOptErr(optionsErrorResp);
            return
        }
        //question-text-validation
        if (questionErrorResp.errors.length) {
            setQuesErr(questionErrorResp);
            return;
        }
        //option validation
        if (optionsErrorResp.errors.length) {
            setOptErr(optionsErrorResp);
            return;
        }
        //   if (alloptionsErrorResp.errors.length) {
        //     setOptErr(optionsErrorResp);
        //     return;
        //   }
        let ansArr: Array<string> = [];
        for (let i = 0; i < options.length; i++) {
            ansArr.push(options[i].option);
        }


        console.log("options submit", options);
        console.log("deletedOptions", deletedOptions);

        const combinedArray = [...options, ...deletedOptions]
        console.log("combinedArray", combinedArray);


        let optionsForReq: Array<IOptions> = [];
        //iterate over the options array and prepare options for req body
        for (let index = 0; index < combinedArray.length; index++) {
            if (combinedArray[index] !== undefined) {
                optionsForReq.push({
                    optionId: combinedArray[index].key,
                    questionId: props.DisplayDetail.questionId,
                    optionNo: index + 1,
                    optionDescription: combinedArray[index].option,
                    correctOption: true,
                    createdBy: combinedArray[index].createdBy,
                    updatedBy: userDetailReduxdata,
                    isActive: combinedArray[index].isActive,
                })

            }
        }
        let quesTypeForReq: Array<IQuesId> = [{
            questionTypeId: 7
        }];



        let keywordReq: Array<IKeywordReq> = [];
        //iterate over the keywords store in the redux to get the id
        //if keyword found then set its id ,otherwise set id is -1
        for (let i = 0; i < keywords.length; i++) {
            let keywordFound = keywordRedux.find((o: Ikeyword) => o.keywordName === keywords[i]);
            console.log("keywordFound", keywordFound);
            if (keywordFound) {
                keywordReq.push({
                    keywordId: keywordFound.keywordId,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
            } else {
                keywordReq.push({
                    keywordId: -1,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
            }
        }


        let quesDetails: IQuesDetails = {
            questionId: props.DisplayDetail.questionId,
            question: questText,
            hostInfo: hostInfo,
            difficulty: difficulty,
            updatedBy: userDetailReduxdata,
            questionTypes: quesTypeForReq,
            options: optionsForReq,
            keywords: keywordReq,

        }
        let updateQuesPpReqBody: IUpdateQuesReqBody = {
            updateQuestionDetails: quesDetails,
        };

        console.log("TL_QUES_update", updateQuesPpReqBody);

        let config = await getHeader();
        setShowLoader(true);
        //api calling to post data
        axios.put(`${QUESTION_SEARCH_APIS_URL}`, updateQuesPpReqBody, config)
            .then(function (response: any) {
                setShowLoader(false);
                //get the response
                console.log("Update APi Result Succesfully Received", response);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                saveModal.icon = 'fas fa-check-square fa-2x team-reg-modal-icon';
                saveModal.message = response.data.description;
                setSaveQuesModal(saveModal);
                props.onQuestionUpdate(true);


            })
            .catch(function (error: any) {
                setShowLoader(false);
                //catch the response
                console.log("CREATE_API_ERROR", error.message);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                setSaveQuesModal(saveModal);
            })

    }

    const setDifficultyFn = (val: any) => {

        setDifficulty("")

        if (val) {
            setDifficulty(val)
        }

    }


    const onHostInfoInputHandler = (data: any) => {
        setHostInfo(data);
    }

    const onQuestionInputHandler = (data: any) => {

        let questionErrorResp: ValidationResponse = Validate('question', questText);
        setQuesErr(questionErrorResp);

        setQuesText(data);
    }

    const onChangeKeywords = (tags: Array<string>) => {
        setKeywords(tags);
    }


    const onClickOkBtnOfSaveBtnValidModal = () => {
        let saveModal = { ...saveQuesModal };
        saveModal.showModal = false;
        setSaveQuesModal(saveModal);
        // window.location.reload();
    }



    return (

        <div className="mt-3 card m-0">
            <div className="card-body">
                <ModalComponent isShowModal={saveQuesModal.showModal} onHandleOkBtn={onClickOkBtnOfSaveBtnValidModal} text={saveQuesModal.message} icon={saveQuesModal.icon} />

                <div className="mb-4 row">
                    <div className="col-sm-12 col-lg-1 col-form-label">
                        <label htmlFor="QuestionName">Question:</label>
                    </div>
                    <div className="col-sm-12 col-lg-11">
                        <div className="form-group res-border">
                            <TextEditor question={props.DisplayDetail} onQuestionInputHandler={onQuestionInputHandler} quesErr={quesErr} />

                        </div>
                        {quesErr.errors.length ? <span className="text-danger">{quesErr.errors[0].message}</span> : <></>}

                    </div>
                </div>



                <div className="mb-4 row">
                    <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Answers</label>
                    <div className="col-lg-11 col-sm-12" onChange={e => addOptions(e)}>
                        {[...Array(textAreaNo)].map((e, i) => (

                            <Options id={i} elementId={options[i] ? options[i].key : -1} onDeleteItemFromOptions={onDeleteItemFromOptions}
                                createdBy={options[i] ? options[i].createdBy : userDetailReduxdata} handleInputChange={handleInputChange} opt={options[i] ? options[i].option : ""} />
                        ))}
                        {optionsErr.errors.length ? <span className="text-danger">{optionsErr.errors[0].message}</span> : <></>}
                    </div>

                </div>


                <div className="mb-4 row">
                    <div className="col-sm-12 col-lg-1  col-form-label"></div>
                    <div className="col-lg-11">
                        <button className="btn btn-success" onClick={() => setTextAreaNo(textAreaNo + 1)}>Add Answer</button>
                    </div>
                </div>


                <div className="mb-4 row">
                    <label htmlFor="KeywordName" className="col-sm-12 col-lg-1 col-form-label">Host Info:</label>
                    <div className="col-sm-12 col-lg-11">
                        <div className="form-group">
                            <TextEditorHost hostInfo={props.DisplayDetail.hostInfo} onHostInfoInputHandler={onHostInfoInputHandler} />

                        </div>
                    </div>

                </div>

                <div className="mb-4 row">
                    <label htmlFor="QuestionName" className="col-sm-12 col-lg-1 col-form-label">Keyword:</label>
                    <div className="col-sm-12 col-lg-11">
                        <div className="form-group">

                            <TagsInput onChangeKeywords={onChangeKeywords} tags={tags} />

                        </div>
                    </div>
                </div>



                <div className="mb-4 row">
                    <label htmlFor="difficulty" className="col-sm-12 col-lg-1 col-form-label">Difficulty:</label>
                    <div className="col-sm-12 col-lg-5">
                        <select className="form-select" id="difficulty" name="difficulty" onChange={(e) => setDifficultyFn(e.target.value)} defaultValue={difficulty}>
                            <option selected>Select Difficulty Level</option>
                            <option selected={difficulty === "Easy"} value="Easy">Easy</option>
                            <option selected={difficulty === "Medium"} value="Medium">Medium</option>
                            <option selected={difficulty === "Hard"} value="Hard">Hard</option>
                        </select>
                    </div>
                </div>


                <div className="mb-4 row">
                    <div className="col-lg-12 d-flex justify-content-end action-button">
                        <button type="button" className="m-1 btn btn-success" onClick={onUpdateQuestion}>
                            Update Question
                        </button>
                    </div>
                </div>



            </div>

        </div>

    );
}

export default TLAnswerEdit; 