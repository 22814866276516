import { SCORE_APIS_URL } from "../config/config";
import IGamesData from "../interfaces/IGamesData"
// import IScore from "../interfaces/IScore";
import _ from "lodash";
const axios = require('axios').default;

export function getGamesData(locationId: any, gameId: any) {
  return axios.get(`${SCORE_APIS_URL}/${locationId}/${gameId}`)
}
export //Get leaderboard
  function GetLeaderBoardData(gameData: IGamesData[]) {
  let tieBreakRoundId = gameData[0].no_of_rounds + 2;
  //Filter records based on game_id & location_id
  let scores: any = [];
  for (let i = 0; i < gameData[0].no_of_rounds + 2; i++) {
    const data = gameData[0].info.filter(item => item.roundId === (i + 1));

    data.map((d) => {
      const index = scores.findIndex((e: any) => e.teamId === d.teamId);

      if (index < 0) {
        if (d.roundId === tieBreakRoundId) {
          scores.push({
            teamId: d.teamId,
            teamName: d.teamName,
            scores: [AddRoundWiseScoreForTieBreak(d)],
            total: ClaculateScoreForTieBreak(d),
          });
        } else {
          scores.push({
            teamId: d.teamId,
            teamName: d.teamName,
            scores: [AddRoundWiseScore(d)],
            total: ClaculateScore(d),
          });
        }

      } else {
        if (d.roundId === tieBreakRoundId) {
          scores[index].scores.push(AddRoundWiseScoreForTieBreak(d));
          scores[index].total = CalculateNewTotalScore(scores[index].scores);
          scores[index].totalWithTieBreak = CalculateTotalScoreForTieBreak(scores[index].scores);
        } else {
          scores[index].scores.push(AddRoundWiseScore(d));
          scores[index].total = CalculateNewTotalScore(scores[index].scores);
          scores[index].totalWithTieBreak = CalculateTotalScoreForTieBreak(scores[index].scores);
        }

      }
      return null
    });
  }

  scores = _.orderBy(scores, ["total"], ['desc']) //old list
  let listOfScores:any=[];// desired list
  let arrayForCustomSorting:any=[]; // array used for sort with totaltiebreak
  listOfScores.push(scores[0]);

    let i=1;
    let flag=0;
    while(i<scores.length){
    if(scores[i].total === scores[i-1].total){
      listOfScores.pop();
      let val =scores[i-1].total;
      arrayForCustomSorting.push(scores[i-1]);
     
      while(i<scores.length &&scores[i].total === val ){
      arrayForCustomSorting.push(scores[i]);

        i++;
        flag=1;
  
    
      }
     let newSortedArray= sortAccordingToTieBreak(arrayForCustomSorting);
     for(let j=0;j<newSortedArray.length;j++){
      listOfScores.push(newSortedArray[j]);
     }
    
     arrayForCustomSorting=[];

    }else{
      listOfScores.push(scores[i]);
    }
   if(flag === 0){
    i++;
   }else{
    flag=0;
   }
  }
  // scores = _.orderBy(scores, ["totalWithTieBreak"], ['desc'])
  //remove the tie-break round from the array of scores
 listOfScores.map((ele:any,idx:any)=>(
  ele.scores.pop()
 ))
  return listOfScores;
}
function sortAccordingToTieBreak(score:any){
return _.orderBy(score, ["totalWithTieBreak"], ['desc'])
}
function AddRoundWiseScore(d: any) {
  //correct_answers: d.correct_answers.length,
  let correct_answers = d.correct_answers.length === 1 && d.correct_answers.includes('') ? 0 : d.correct_answers.length;
  let total = 0;
  if (d.is_pp_active) {
    switch (d.roundId) {
      case 1: total += 10;
        break;
      case 2: total += 9;
        break;
      case 3: total += 8;
        break;
      case 4: total += 6;
        break;
      case 5 + 1: total += 5;
        break;
      case 6 + 1: total += 4;
        break;
      case 7 + 1: total += 2;
        break;
      case 8 + 1: total += 1;
        break;
      default: total += 0;
    }
  }
  // return {
  //   roundId: d.roundId,
  //   correct_answers,
  //   pp: d.is_pp_active ? 10 - d.roundId + 1 : 0,
  //   jj: d.is_jj_active ? d.correct_answers.length * 2 : 0,
  //   total: ClaculateScore(d),
  // };
  return {
    roundId: d.roundId,
    correct_answers,
    pp: total,
    jj: d.is_jj_active ? d.correct_answers.length * 2 : 0,
    total: ClaculateScore(d),
  };
}
function AddRoundWiseScoreForTieBreak(d: any) {
  //correct_answers: d.correct_answers.length,
  let correct_answers = (d.correct_answers.length === 1 && d.correct_answers.includes('')) || d.correct_answers.length === 0 ? 0 : parseInt(d.correct_answers[0]);
  let total = 0;
  if (d.is_pp_active) {
    switch (d.roundId) {
      case 1: total += 10;
        break;
      case 2: total += 9;
        break;
      case 3: total += 8;
        break;
      case 4: total += 6;
        break;
      case 5 + 1: total += 5;
        break;
      case 6 + 1: total += 4;
        break;
      case 7 + 1: total += 2;
        break;
      case 8 + 1: total += 1;
        break;
      default: total += 0;
    }
  }
  // return {
  //   roundId: d.roundId,
  //   correct_answers,
  //   pp: d.is_pp_active ? 10 - d.roundId + 1 : 0,
  //   jj: d.is_jj_active ? d.correct_answers.length * 2 : 0,
  //   total: ClaculateScore(d),
  // };
  return {
    roundId: d.roundId,
    correct_answers,
    pp: total,
    jj: d.is_jj_active ? d.correct_answers.length * 2 : 0,
    total: ClaculateScoreForTieBreak(d),
  };
}
export function ClaculateScore(score: any) {
  let total = 0;
  //check and add number of correct answers
  if (score.correct_answers.length === 1 && score.correct_answers.includes('')) {
    total = 0;
  } else {
    total += score.correct_answers.length;
  }

  //If is JJ=true
  if (score.is_jj_active) {
    total *= 2;
  }

  //If is PP=true
  if (score.is_pp_active) {
    //total += 10 - score.roundId + 1;
    switch (score.roundId) {
      case 1: total += 10;
        break;
      case 2: total += 9;
        break;
      case 3: total += 8;
        break;
      case 4: total += 6;
        break;
      case 5 + 1: total += 5;
        break;
      case 6 + 1: total += 4;
        break;
      case 7 + 1: total += 2;
        break;
      case 8 + 1: total += 1;
        break;
      default: total += 0;
    }
  }
  //The LIst
  if (score.roundType === 1) {
    total = calculateTheListRoundScore(score.correct_answers);
  }
  return total;
}
export function ClaculateScoreForTieBreak(score: any) {
  let total = 0;
  //check and add number of correct answers
  if (score.correct_answers.length === 1 && score.correct_answers.includes('')) {
    total = 0;
  } else {
    total += score.correct_answers[0] ? parseInt(score.correct_answers[0]) : 0;
  }

  //If is JJ=true
  if (score.is_jj_active) {
    total *= 2;
  }

  //If is PP=true
  if (score.is_pp_active) {
    //total += 10 - score.roundId + 1;
    switch (score.roundId) {
      case 1: total += 10;
        break;
      case 2: total += 9;
        break;
      case 3: total += 8;
        break;
      case 4: total += 6;
        break;
      case 5 + 1: total += 5;
        break;
      case 6 + 1: total += 4;
        break;
      case 7 + 1: total += 2;
        break;
      case 8 + 1: total += 1;
        break;
      default: total += 0;
    }
  }
  //The LIst
  if (score.roundType === 1) {
    total = calculateTheListRoundScore(score.correct_answers);
  }
  return total;
}

function CalculateTotalScore(data: any) {
  let sum = 0;
  data.map((d: any) => (sum += d.total));
  return sum;
}
function CalculateNewTotalScore(data: any) {
  let sum = 0;
  for (let i = 0; i < data.length-1; i++) {
    sum += data[i].total
  }
  return sum;
}
function CalculateTotalScoreForTieBreak(data: any) {
  let sum = 0;
 
  data.map((d:any) => (sum += d.total));
  return sum;
}

export function calculateTheListRoundScore(correct_answers: Array<number>) {
  const arrOfNum = correct_answers.map(str => {
    return Number(str);
  });
  let sum = 0;
  if (correct_answers.length === 0) return sum;
  if (arrOfNum[0] === 1) {
    for (let index = 0; index < correct_answers.length; index++) {
      if (arrOfNum[index] === index + 1) sum++;
      else break;
    }
  }
  return sum;
}
export function GetTotalScoreOfAllRoundsOfParticularTeam(gameData: IGamesData[], teamName: any) {
  let scores: any = [];
  const data = gameData[0].info.filter(item => item.teamName === teamName);

  for(let i=0;i<data.length-1;i++){
    const index = scores.findIndex((e: any) => e.roundId === data[i].roundId);

    if (index < 0) {
      scores.push({
        teamId: data[i].teamId,
        teamName: data[i].teamName,
        scores: [AddRoundWiseScore(data[i])],
        total: ClaculateScore(data[i]),
      });
    } else {
      scores[index].scores.push(AddRoundWiseScore(data[i]));
      scores[index].total = CalculateTotalScore(scores[index].scores);
    }
  }
  // data.map((d) => {
  //   const index = scores.findIndex((e: any) => e.roundId === d.roundId);

  //   if (index < 0) {
  //     scores.push({
  //       teamId: d.teamId,
  //       teamName: d.teamName,
  //       scores: [AddRoundWiseScore(d)],
  //       total: ClaculateScore(d),
  //     });
  //   } else {
  //     scores[index].scores.push(AddRoundWiseScore(d));
  //     scores[index].total = CalculateTotalScore(scores[index].scores);
  //   }
  // });
  let total = 0;
  scores.map((item: any) => {
    total += item.total;
    return null
  })
  return total;
}