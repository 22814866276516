// import React from 'react';
import './App.css';
import Login from './components/Login';
import { Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
// import Layout from './components/Layout';
import CreateContest from './components/CreateContest';
import ScoreEditor from './components/ScoreEditor';
import LeaderBoard from './components/LeaderBoard';
// import Scoreboard from './components/ScoreBoard';
import Auth0Login from './components/auth0/Auth0Login';
// import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import CreateNewQuestion from './components/CreateNewQuestion';
import QuestionSearch from './components/question/QuestionSearch';
import FileUpload from './components/FileUpload';
import CreateNewTheListQuestion from './components/CreateNewTheListQuestion';
import CreateNewPPQuestion from './components/CreateNewPPQuestion';
import QuizList from './components/quizs/QuizList';
import Protected from './components/Protected';

function App() {
//   const { user, loginWithRedirect, isAuthenticated,getAccessTokenSilently} = useAuth0();
  const {isAuthenticated} = useAuth0();
  return (
  <Routes>
    <Route index element={<Auth0Login/>} />
    <Route path ="/register" element={<Register/>}/>
    <Route path ="/login" element={<Login/>}/>

    <Route path ="/dashboard" element={
        <Protected isLoggedIn={isAuthenticated}>
            <Dashboard/>
        </Protected>
    }/>
    <Route path ="/create-contest" element={
        <Protected isLoggedIn={isAuthenticated}>
            <CreateContest/>
        </Protected>    
    }/>
    <Route path ="/score-editor" element={
        <Protected isLoggedIn={isAuthenticated}>
            <ScoreEditor/>
        </Protected>              
    }/>
    <Route path ="/leader-board" element={
        <Protected isLoggedIn={isAuthenticated}>
            <LeaderBoard/>
        </Protected>              
    }/>
    <Route path ="/create-new-question" element={
        <Protected isLoggedIn={isAuthenticated}>
            <CreateNewQuestion />
        </Protected>
    }/>
    <Route path ="/create-new-the-list-question" element={
        <Protected isLoggedIn={isAuthenticated}>
            <CreateNewTheListQuestion/>
        </Protected>
    }/>
    <Route path ="/create-new-pp-question" element={
        <Protected isLoggedIn={isAuthenticated}>
            <CreateNewPPQuestion/>
        </Protected>
    }/>
    <Route path ="/search-question" element={
        <Protected isLoggedIn={isAuthenticated}>
            <QuestionSearch/>
        </Protected>
    }/>
    <Route path ="/file-upload" element={
        <Protected isLoggedIn={isAuthenticated}>
            <FileUpload/>
        </Protected>
    }/>
    <Route path ="/layout" element={
        <Protected isLoggedIn={isAuthenticated}>
            <Dashboard/>
        </Protected>
    }/>
    <Route path ="/quiz" element={
        <Protected isLoggedIn={isAuthenticated}>
            <QuizList/>
        </Protected>
    }/>
  </Routes>

  );
}

export default App;
