import { useState } from "react";
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../state/index';
import { useSelector } from 'react-redux';
import { updateScore } from "../../Dummy-data/DummyGamesDataService";
import checkForPpVisibility from "../../utils/checkForPpVisibility";

function ScoreButton(props: any) {
  const dispatch = useDispatch();
  const reduxData = useSelector((state: any) => state.pp);
  const ppVisibilityReduxData = useSelector((state: any) => state.ppVisibility);
  const { isMarkedCorrect, title, id, roundId, type, score, onScoreUpdate } = props;
  const [state, setState] = useState(isMarkedCorrect);
  const [, setVisible] = useState(true);
  let classes = "m-1 btn btn-sm btn-";

  const handleClick = async (e: any, btnType: any) => {
    dispatch(actionCreators.isActiveComponent("score-editor"));

    dispatch(actionCreators.openModal(true));
    e.preventDefault();
    setState(!state);
    if (roundId === 5) {
      await UpdateScoreForTheList(btnType, e);
    } else {
      await UpdateScore(btnType);
    }

  };

  const UpdateScore = async (btnType: any) => {
    //update score locally

    switch (btnType) {
      case "0":
        //is_pp_active
        score.is_pp_active = !state;

        if (state === true && roundId !== 5) {
          const filteredReduxdataArr = ppVisibilityReduxData.filter((item: any) => item.teamName !== props.teamName);
          dispatch(actionCreators.ppVisiblity(filteredReduxdataArr));
        }
        break;
      case "1":
        //is_jj_active
        score.is_jj_active = !state;
        break;

      default:
        if (!state) {
          // let newAnswersList = [... new Set([...score.correct_answers, title])].sort();
          let newAnswersList = [...new Set([...score.correct_answers, title])];
          score.correct_answers = newAnswersList.sort((a, b) => { return a - b });
        }
        else {
          const index = score.correct_answers.indexOf(title);
          if (index > -1) {
            score.correct_answers.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
        break;
    }

    //update score in DummyGamesDataService.js
    // await updateScore(score);
    //return false if pp active is already true in previous index
    let isDisable = checkForPpVisibility(reduxData, props.teamName);

    if (btnType === "0" && isDisable) {
      //disable
      await onScoreUpdate(score);

      let arr = ppVisibilityReduxData;
      arr.push({
        isVisible: false,
        teamName: props.teamName
      });
      await dispatch(actionCreators.ppVisiblity(arr));
      setVisible(current => !current);
    } else {

      await onScoreUpdate(score);
      let arr = ppVisibilityReduxData;
      arr.push({
        isVisible: false,
        teamName: props.teamName
      });

      await dispatch(actionCreators.ppVisiblity(arr));
      setVisible(current => !current);
      props.forceUpdateComp();


    }

  };

  const deSelectListButtonTap =()=>{

    for (let i = parseInt(title); i <= 10; i++) {
      let idButton = id.replace(`btn_${title}`, `btn_${i}`);
      if (document.getElementById(`${idButton}`)?.className.includes('btn-success')) {
        document.getElementById(`${idButton}`)?.classList.remove('btn-success', 'rounded-0');
        document.getElementById(`${idButton}`)?.classList.add('btn-question-box', 'secondary');
      }
      const index = score.correct_answers.indexOf(`${i}`);
      if (title === '1') {
        score.correct_answers = [];
      } else if (index > -1) {
        score.correct_answers.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    
  }

  const UpdateScoreForTheList = async (btnType: any, e: any) => {
    //update score locally

    switch (btnType) {
      case "0":
        //is_jj_active
        score.is_pp_active = !state;
        break;
      case "1":
        //is_jj_active
        score.is_jj_active = !state;
        break;
      default:
        if (!state || document.getElementById(`${id}`)?.className.includes('btn-question-box')) {
          let newAnswersList = [...new Set([...score.correct_answers, title])];
          for (let i = 1; i <= parseInt(title) - 1; i++) {
            let isAnswerListExist = newAnswersList.some((title) => title == i)
            let idButton = id.replace(`btn_${title}`, `btn_${i}`);
            document.getElementById(`${idButton}`)?.classList.add('btn-success', 'rounded-0');
            if (!isAnswerListExist) {
              newAnswersList.push(`${i}`)
            }
          }
          score.correct_answers = newAnswersList.sort((a, b) => { return a - b });
          if (!document.getElementById(`${id}`)?.className.includes('btn-success')) {
            document.getElementById(`${id}`)?.classList.add('btn-success', 'rounded-0');
            document.getElementById(`${id}`)?.classList.remove('btn-question-box', 'secondary');
            setState(true);
          } else {
            document.getElementById(`${id}`)?.classList.remove('btn-success', 'rounded-0');
            document.getElementById(`${id}`)?.classList.add('btn-question-box', 'secondary');
            deSelectListButtonTap();
            setState(false);
          } 
        }
        else {
          deSelectListButtonTap();

        }
        break;
    }
    //update score in DummyGamesDataService.js
    await updateScore(score);//API Call simulation
    onScoreUpdate(score);//Raising event
  };



  switch (type) {
    case "0":
      classes += state ? "danger rounded-0" : "question-box secondary";
      var result = ppVisibilityReduxData.find((obj: any) => {
        return obj.teamName === props.teamName
      })
      if (result !== undefined) {
        if (!result.isVisible && result.teamName === props.teamName && checkForPpVisibility(reduxData, props.teamName)) {
          if (classes !== "m-1 btn btn-sm btn-danger rounded-0") {
            classes = "disabled " + classes;
          }
        }
      }
      else if (score.is_pp_active === false && score.correct_answers.length > 0) {
        classes = "disabled " + classes;
      }
      break;
    case "1":
      if (roundId !== 1) {
        classes += state ? "primary rounded-0" : "question-box secondary";
      } else {
        classes += "question-box secondary disabled";
      }

      break;
    default:
        classes += state ? "success rounded-0" : "question-box secondary";
      break;
  }

  classes += ' col ';
  return (
    <button className={id.startsWith("jj") ? classes + " disabled text-score" : classes + " text-score"} value={type} onClick={(e) => handleClick(e, type)} id={id}>
      <span>{props.answerId}</span>{props.answer !== "" ? props.answer : title}
    </button>
  );
}

export default ScoreButton;
