import { useState, useEffect } from "react";
// import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  GAME_APIS_URL,
  QUESTION_TYPE_APIS_URL,
  PREV_TEAMS_API_URL,
  VALIDATE_QUIZ_CODE_API_URL,
} from "../config/config";
import Layout from "./Layout";
import { useDispatch } from "react-redux";
import { actionCreators } from "../state/index";
// import { useSelector } from "react-redux";
import LoadingSpinner from "./common/LoadingSpinner";
import { Modal } from "react-bootstrap";
import { ValidationResponse } from "../interfaces/error";
import { Validate } from "../utils/validation";
import ValidateQuizModal from "./common/ValidateQuizModal";
import SaveKeywords from "../utils/SaveKeywords";


const axios = require("axios").default;
function Dashboard() {

  // const isSideNavbarVisible = useSelector((state:any)=>state.sideNavbarVisibilty);

  //Initialize Dispatcher to dispatch on redux
  const dispatch = useDispatch();
  //Get main css class from Redux 
  // const mainClass: any = useSelector((state: any) => state.mainClassReducer);
  let initState = "Currently there are no active games";
  //State variable to handle data of component
  const [state, setState] = useState(initState);
  const [btnClass, setBtnClass] = useState({
    btn1: "btn btn-primary w-80 rounded-0 display-none",
    btn2: "btn btn-primary w-80 rounded-0",
  });
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  //State Variable to handle Loader on Screen
  const [gettingData, setgettingData] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [currentGameId, setCurrentGameId] = useState();
  const [currentLocationId, setcurrentLocationId] = useState();
  const [refresh, setRefresh] = useState(false);
  const [validateCodeModal, showValidateCodeModal] = useState(false);
  //State variable to store quiz code
  const [quizCode, setQuizCode] = useState<number>();
  //State variable to handle quiz code error 
  const [quizCodeError, setQuizCodeError] = useState<ValidationResponse>({
    key: "quizCode",
    errors: [],
  });
  const [quizCodeLenError, setQuizCodeLenError] = useState("");
  //State variable to enable or disable validate ok button
  const [validateOkBtnDisable, setValidateOkBtnDisable] = useState(false);

  async function apiCalling() {
    //Get Token for api calling
    const token = await getAccessTokenSilently();
    //Get Email from local storage
    const email: any = localStorage.getItem("email");
    // const loggedInUserName: any = localStorage.getItem("items");
    //Set Header for api calling

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      },
      params: {
        createdByEmail: JSON.parse(email?.toString()),
      },
    };
    // const refresh = true;
    setgettingData(true);
    // Api calling to get data of games
    axios.get(`${GAME_APIS_URL}`, config)
      .then(function (response: any) {
        console.log(`Response Received${response}`);
        console.log(JSON.stringify(response.data));
        // let newState ={...state};
        // newState.data = response.data;
        if (response.data.message) {
          console.log("data", response.data.message);
          setRefresh(true);
          setState(JSON.stringify(response.data.message));
          setBtnClass({
            btn1: "btn btn-primary w-80 rounded-0",
            btn2: "btn btn-primary w-80 rounded-0 display-none",
          });
        } else {
          setState(initState);
          setBtnClass({
            btn1: "btn btn-primary w-80 rounded-0 display-none",
            btn2: "btn btn-primary w-80 rounded-0",
          });
        }
        // setgettingData(false);
      })
      .catch(function (error: any) {
        console.log(`Response Failure ${error}`);
      })
      .finally(function () {
        setgettingData(false);
      });

    //Set Token in header
    let configForQues = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      },
    };


      // Function to store keyword data in redux
      const keywordsData = await SaveKeywords(token);

      dispatch(keywordsData);


    axios.get(`${QUESTION_TYPE_APIS_URL}`, configForQues)
      .then(function (response: any) {
        console.log("QUESTION_TYPES");
        console.log(JSON.stringify(response.data.message));
        dispatch(actionCreators.questionTypes(response.data.message));
      })
      .catch(function (error: any) {
        console.log(error);
      });


    //Set header to get old teams
    let configForPrevTeam = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      },
      params: {
        loggedInUser: JSON.parse(email?.toString()),
      },
    };
    //api calling to retrieve team names to display at the time of game creation
    axios.get(`${PREV_TEAMS_API_URL}`, configForPrevTeam)
      .then(function (response: any) {
        console.log("PREV_TEAMS", JSON.stringify(response.data.message));
        if (
          response.data.description ===
          "No game record found made by the current logged in user"
        ) {
          //dispatch or store the data into redux
          dispatch(actionCreators.populateOldTeams([]));
        } else {
          //dispatch or store the teams into redux
          dispatch(actionCreators.populateOldTeams(response.data.message));
        }
      })
      .catch(function (error: any) {
        dispatch(actionCreators.populateOldTeams([]));
        console.log(error);
      });
  }


  useEffect(() => {
    apiCalling();
  }, [refresh]);

  /**
   * function to navigate to Edit Teams or create Game Screen
   * @returns {void}
   */
  const navigateToTeamRegistrationScreen = (
    locationId: any,
    gameId: any,
    gameName: any,
    locationName: any,
    noOfRounds: any,
    quizId: any
  ) => {
    console.log(locationId);
    console.log(gameId);
    dispatch(actionCreators.isActiveComponent("create-contest"));
    dispatch(
      actionCreators.gameInfo({ locationId, gameId, gameName, locationName })
    );
    navigate("/create-contest", {
      state: { locationId, gameId, gameName, locationName, noOfRounds, quizId },
    });
  };

  /**
   * function to navigate to ScoreEditor Component
   * @returns {void}
   */
  const navigateToActiveGame = (
    locationId: any,
    gameId: any,
    gameName: any,
    locationName: any,
    noOfRounds: any,
    quizId: any
  ) => {
    dispatch(actionCreators.isActiveComponent("score-editor"));
    dispatch(
      actionCreators.gameInfo({ locationId, gameId, gameName, locationName })
    );
    navigate("/score-editor", {
      state: { locationId, gameId, gameName, locationName, noOfRounds, quizId },
    });
  };

  /**
   * function to navigate to Leaderboard
   * @returns {void}
   */
  const navigateToLeaderboard = (
    locationId: any,
    gameId: any,
    gameName: any,
    locationName: any,
    jokers: boolean
  ) => {
    dispatch(actionCreators.isActiveComponent("leader-board"));
    dispatch(
      actionCreators.gameInfo({ locationId, gameId, gameName, locationName })
    );
    navigate("/leader-board?jj="+jokers, {
      state: { locationId, gameId, gameName, locationName },
    });
  };


  // Yamaha Code

  // const toggleSideNavar =()=>{
  //   dispatch(actionCreators.showOrHideSideNavBar(!isSideNavbarVisible));
  //   if(mainClass==="main"){
  //       dispatch(actionCreators.mainClass("mainWithoutSideNavBar"));
  //   } else {
  //       dispatch(actionCreators.mainClass("main")); 
  //   }
  // }

  // const toggleSideNavbar =()=>{
  //   const mainDiv = document.getElementById("main");
  //   const sideBar = document.getElementById("navMain");
  //   if(mainDiv?.classList.contains("main")){
  //       mainDiv?.classList.remove("main");
  //       sideBar?.classList.add("inactive");
  //       mainDiv?.classList.add("mainWithoutSideNavBar");
  //   } else {
  //       mainDiv?.classList.add("main");
  //       sideBar?.classList.remove("inactive");
  //       mainDiv?.classList.remove("mainWithoutSideNavBar");
  //   }
  // }


  /**
   * function to show games on dashboard
   * @returns {Html}
   */
  const showData = () => {
    if (state === "Currently there are no active games") {
      return <p>{state}</p>;
    } else {
      let arr = JSON.parse(state);
      let tableDash = (
        <div className="card-body p-0">
          <ul className="list-group">
            {arr.map((ele: any, idx: any) => (
              <li key={idx} className="list-group-item p-2">
                {/* Richa */}
                <div className="row">
                  <div className="col-md-6 col-lg-7 col-sm-12 text-start p-0">
                    <div className="col-md-7 col-sm-12 text-start p-0 lock-flex">
                      <div className="text-start p-0 lock-img tooltipp">
                        {ele.isGameFinalized ? (
                          <i className="fas fa-solid fa-lock ps-1 fs-2 lock-icon "></i>
                        ) : (
                          <></>
                        )}
                        {ele.isGameFinalized ? (
                          <span className="tooltiptext">Game is Locked.</span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="">
                        <h4 className="m-0 text-truncate">{ele.gameName}</h4>
                        <h6>{ele.locationName}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-5 col-sm-12 p-0 game-actionButton">
                    <button
                      className="btn btn-danger btn-size"
                      onClick={() => deleteGame(ele.locationId, ele.gameId)}
                      disabled={ele.isGameFinalized}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-primary btn-size"
                      onClick={() =>
                        navigateToTeamRegistrationScreen(
                          ele.locationId,
                          ele.gameId,
                          ele.gameName,
                          ele.locationName,
                          ele.noOfRounds,
                          ele.quizId
                        )
                      }
                      disabled={ele.isGameFinalized}
                    >
                      Teams
                    </button>
                    <button
                      className="btn btn-success btn-size"
                      onClick={() =>
                        navigateToActiveGame(
                          ele.locationId,
                          ele.gameId,
                          ele.gameName,
                          ele.locationName,
                          ele.noOfRounds,
                          ele.quizId
                        )
                      }
                      disabled={ele.isGameFinalized}
                    >
                      Scoring
                    </button>
                    <div className="position-relative push-up">
                      <span className="">Show Scoreboard</span>
                    <div className="btn btn-warning btn-size wider">
                      <button
                        className="inner-button"
                        onClick={() =>
                          navigateToLeaderboard(
                            ele.locationId,
                            ele.gameId,
                            ele.gameName,
                            ele.locationName,
                            true
                          )
                        }
                      >
                        JR
                      </button>
                      <button
                        className="inner-button"
                        onClick={() =>
                          navigateToLeaderboard(
                            ele.locationId,
                            ele.gameId,
                            ele.gameName,
                            ele.locationName,
                            false
                          )
                        }
                      >
                        No JR
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
      return tableDash;
    }
  };

  /**
   * function to delete Game
   * @returns {void}
   */
  const deleteGame = (locationId: any, gameId: any) => {
    setCurrentGameId(gameId);
    setcurrentLocationId(locationId);
    setShowDeleteModal(true);
  }


  /**
   * function to proceed pop up
   * @returns {void}
   */
  const proceedModal = async () => {
    const token = await getAccessTokenSilently();
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      },
    };
    let locationId = currentLocationId;
    let gameId = currentGameId;
    //Api Call to delete games from dashboard list
    axios
      .delete(`${GAME_APIS_URL}/${locationId}/${gameId}`, config)
      .then(function (response: any) {
        console.log("Response Received", response);
        setShowDeleteModal(false);
        setRefresh((c) => !c);
      })
      .catch(function (error: any) {
        console.log(`Response Failure ${error}`);
      });
  };

  /**
   * function to remove delete modal
   * @returns {void}
   */
  const cancelModal = () => {
    setShowDeleteModal(false);
  };

  /**
   * function to proceed after entering quiz code
   * @returns {void}
   */
  const proceedWithQuizCode = () => {
    setQuizCodeLenError("");
    showValidateCodeModal(true);
  };

  /**
   * function to Validate Quiz Code
   * @returns {void}
   */
  const validateQuizCode = async () => {
    const token = await getAccessTokenSilently();
    //Set Header Configuration for Api
    let configForQues = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      },
    };
    //api calling to Validate after entering Quiz Code
    axios
      .get(`${VALIDATE_QUIZ_CODE_API_URL}/${quizCode}`, configForQues)
      .then(function (response: any) {
        console.log("quiz code", JSON.stringify(response));
        //Variable to Store Quiz data
        var quizData = response.data.message;
        //If the quiz code is invalid then show error message
        if (response.data.description === "Invalid quiz code") {
          setQuizCodeLenError(response.data.description);
          return;
        }
        //If the quizcode is correctly validated then navigate to create game screen
        if (response.data.description === "QuizCode Validated") {
          setQuizCodeLenError("");
          //Close the Modal By set the value of its state variable
          showValidateCodeModal(false);
          //Navigate to Create Game Screen
          navigate("/create-contest", { state: { quizData } });
        }
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };


  /**
   * function to Remove Validate quiz code pop-up
   * @returns {void}
   */
  const cancelValidateCodeModal = () => {
    setQuizCodeError({
      key: "quizCode",
      errors: [],
    });
    // setInputQuizCode();
    showValidateCodeModal(false);
  }

  /**
    * function to get value of quiz code field
   * @returns {void}
    */

  const getValueFromquizCode = (e: any) => {
    setQuizCodeError({
      key: "quizCode",
      errors: [],
    });
    setQuizCodeLenError("");
    let quizErrorResp: ValidationResponse = Validate(
      "quizCode",
      e.target.value
    );

    console.log(quizErrorResp.errors.length);
    if (quizErrorResp.errors.length) {
      setQuizCodeError(quizErrorResp);
      setValidateOkBtnDisable(false);
      return;
    }

    setValidateOkBtnDisable((c) => !c);
    setQuizCode(e.target.value);
    setQuizCodeLenError("");
  };

  return (
    <>
      <Layout />
      <div id="main" className="withoutSideBarNav">
        <ValidateQuizModal
          isShowModal={validateCodeModal}
          onHandleOkBtn={validateQuizCode}
          onCancelBtn={cancelValidateCodeModal}
          onInputBtn={getValueFromquizCode}
          quizCodeError={quizCodeError}
          quizCodeLenError={quizCodeLenError}
          validateOkBtnDisable={validateOkBtnDisable}
        />
        <Modal show={showDeleteModal}>
          <Modal.Body className="text-center">
            <i className="fas fa-question-circle quit-modal"></i>
            <p className="p-txt">
              Are you sure you want to delete this quiz from your records?{" "}
            </p>
            <div>
              <button
                className="btn btn-success text-left m-2"
                onClick={proceedModal}
              >
                Yes
              </button>
              <button className="btn btn-secondary" onClick={cancelModal}>
                No
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* <div className="dashboard-menu-icon-wrap">
            <span className="dashboard-close-icon" onClick={()=>toggleSideNavbar()}>&times;</span>
        </div> */}

        <div className="pagetitle dashboard-pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row">
            <div className="p-0 col-lg-12 text-center gap">
              <div className="ms-auto action-button page-title-button create-contest-button">

                <button
                  type="button"
                  className={btnClass.btn1}
                  onClick={proceedWithQuizCode}
                >
                  Create New Game
                </button>
              </div>
              {gettingData ? <LoadingSpinner /> : showData()}
              <div className="col-lg-2 col-md-6 col-sm-12 py-1 m-auto action-button page-title-button">

                <button
                  type="button"
                  className={btnClass.btn2}
                  onClick={proceedWithQuizCode}
                >
                  Create New Game
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Dashboard;
