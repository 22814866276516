import { useState, useEffect, useRef } from "react";
import Logo from '../../content/images/logo.png'
import {Nav, ModalHeader} from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
// import {useDispatch} from 'react-redux';
import useScrollDirection from '../../hooks/useScrollDirection'
// import {actionCreators} from '../../state/index';
import {useSelector} from 'react-redux';

function Header() {
    const [items, setItems] = useState([]);
    // const dispatch = useDispatch();
    // const isSideNavbarVisible = useSelector((state:any)=>state.sideNavbarVisibilty);
    const mainClass = useSelector((state:any)=>state.mainClassReducer);
    const { logout } = useAuth0();
    const headerRef = useRef() as any;

    const logoutUser=()=>{
        return logout({ returnTo: window.location.origin })
    }

    // Yamaha Code

    // const toggleSideNavar =()=>{
    //     dispatch(actionCreators.showOrHideSideNavBar(!isSideNavbarVisible));
    //     if(mainClass==="main"){
    //         dispatch(actionCreators.mainClass("mainWithoutSideNavBar"));
    //     } else {
    //         dispatch(actionCreators.mainClass("main")); 
    //     }
    // }

    const toggleSideNavbar =()=>{
        const mainDiv = document.getElementById("main");
        const sideBar = document.getElementById("navMain");
        if(mainDiv?.classList.contains("main")){
            mainDiv?.classList.remove("main");
            sideBar?.classList.add("inactive");
            mainDiv?.classList.add("mainWithoutSideNavBar");
        } else {
            mainDiv?.classList.add("main");
            sideBar?.classList.remove("inactive");
            mainDiv?.classList.remove("mainWithoutSideNavBar");
        }
      }

    useEffect(() => {
        const items:any = localStorage.getItem('items');
        if(items!=null){
            setItems(JSON.parse(items));
        } 
        // dispatch(actionCreators.mainClass("main")); 
    }, []);


    const scrollDirection = useScrollDirection();

    return (
        <div>
            <ModalHeader id="header" className={scrollDirection === "up" ? mainClass + " header fixed-top d-flex align-items-center mt-0" : " header hide"} ref={headerRef}>
                <div className="d-flex align-items-center justify-content-between w-100">
                <div className="menu-icon-wrap">
                    <span className="menu-icon" onClick={()=>toggleSideNavbar()}></span>
                </div>
                    <a href="" className="logo d-flex">
                        <img src={Logo} alt=""/>
                    </a>
                </div>
                <Nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">
                        <li className="nav-item dropdown pe-3">
                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <span className="d-none d-md-block dropdown-toggle ps-2">{items}</span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li onClick={logoutUser}>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        <i className="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Nav>
            </ModalHeader>
        </div>
    );
}

export default Header;
