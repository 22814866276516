import { useState, useEffect } from 'react';
import RoundButton from "./common/RoundButton";
// import { Modal } from 'react-bootstrap';
// import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { actionCreators } from '../state/index';
import 'react-toastify/dist/ReactToastify.css';
function TeamRegistration(props: any) {
    const dispatch = useDispatch();
    let jjlist: String[] = [];
    const initstate = {
        count: 0,
        jjlist: props.jjRounds ? props.jjRounds : jjlist,
        showModal: false
    }
    const [jjCount, setJjcount] = useState(initstate);
    const [count, setCount] = useState<any[]>(props.jjRounds ? props.jjRounds.includes(null) ? [] : props.jjRounds : []);
    
    const handleDeleteItem = () => {
        props.handleItemDelete(props.elementId);
        dispatch(actionCreators.openModal(true));

    }
    const handleDataChange = (evt: any) => {
        props.handleInputChange(evt.target.value, props.elementId);
        dispatch(actionCreators.openModal(true));

    }
    const decJJCount = (id: any) => {

        let newState = { ...jjCount };
        newState.count = jjCount.count - 1;
        let arr: [] = props.jjRounds;
        if (arr !== undefined) {
            let newArr = arr.filter(item => item !== (id + 1))
            setCount(newArr);
            props.updateJJRoundsList(props.elementId, newArr);
        }

        setJjcount(newState);
        dispatch(actionCreators.openModal(true));
    }
    const onJJCount = (id: any) => {
        let newState = { ...jjCount };
        newState.count = jjCount.count + 1;

        if (newState.count < 3) {
            newState.jjlist[jjCount.count] = (id + 1);
            // let arr = [];
            if (props.jjRounds) {
                if(!props.jjRounds.includes((id+1))){
                    count.push((id + 1));
                }
               
            } else {
                count.push((id + 1));
            }
            
            if (newState.count === 2) {
                setJjcount(newState);
                //let newArr = count.filter(item => item !== null);
                let newArr = jjCount.jjlist.filter((item:any) => item !== null);
                newArr=newArr.filter((item:any,
                    index:any) => newArr.indexOf(item) === index);
                props.updateJJRoundsList(props.elementId, newArr);
            } else {
                let arr = jjCount.jjlist.filter((item:any) => item !== null);
            props.updateJJRoundsList(props.elementId, arr);
                setJjcount(newState);
            }
            dispatch(actionCreators.openModal(true));
            return true;
        } else {
            showModal();
        }

        return false;
    }


    const showModal = () => {
        props.showToast();
    }
    
   
    useEffect(() => {
        if (props.jjRounds !== undefined && props.jjRounds.length === 2) {
            let newState = { ...jjCount };
            newState.count = 2;
            newState.jjlist = props.jjRounds;
            setJjcount(newState);
        }
        else if(props.jjRounds !== undefined && props.jjRounds.length === 1){
            let newState = { ...jjCount };
            newState.count = 1;
            newState.jjlist = props.jjRounds;
            setJjcount(newState);
        }
        else if (props.jjRounds !== undefined && props.jjRounds.length === 1 && (props.jjRounds.includes(null) || props.jjRounds.includes(undefined))) {
            let newState = { ...jjCount };
            newState.count = 0;
            newState.jjlist = [];
            setJjcount(newState);
        }
        else if (props.jjRounds === undefined) {
            let newState = { ...jjCount };
            newState.count = 0;
            newState.jjlist = [];
            setJjcount(newState);
        }
        
    }, [props.jjRounds])


    return (<>
        <div className="table-row accordion-item row align-items-center mt-1 m-0">
            <div className="col-lg-1 col-2 py-2"><h3 className="serialNo">{props.serialNo}</h3></div>
            <div className="col-lg-3 col-10 text-truncate py-2">
                <input type="text" className="form-control" placeholder="Team Name" value={props.default?props.default:""} onChange={evt => handleDataChange(evt)} /></div>
            <div className="col-lg-7 col-sm-12 d-flex flex-wrap text-center p-0 gap-3">
                {[...Array(props.noOFRounds)].map((e, i) => <span className="busterCards" key={i}><RoundButton title={`R ${i + 1}`} id={i} teamId={props.elementId} onJJCount={onJJCount} decJJCount={decJJCount} jjlist={jjCount.jjlist} /></span>)}
            </div>
            <div className="col-lg-1 col-sm-10 text-truncate fw-bold py-2 text-center pc-1">
                <i className="fas fa-trash" onClick={handleDeleteItem}></i>
            </div>
        </div>
    </>);
}


export default TeamRegistration;



