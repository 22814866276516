// import IGamesData from "../interfaces/IGamesData";
import IScore from "../interfaces/IScore";

export default function Calculate(score: IScore) {
  let total = 0;
  //round_type=0 : Question Round
  if (score.roundType === 0) {
    //1. Calculate sum of correct answers from Question Round
    total = calculateQuestionRoundScore(score.correct_answers);
    //2. Calculate sum for Joker (JJ) Round if roundId!=1  && score.is_jj_active=true
    if (score.roundId !== 1 && score.is_jj_active) {
      total = calculateJJRoundScore(total);
    }
    //3. Calculate sum for Ponderous Puzzle (PP) Round
    return calculatePPRoundScore(total, score);
  }
  //round_type=1 : The List Round
  if (score.roundType === 1) {
    return calculateTheListRoundScore(score.correct_answers);
  }
}

export function calculateQuestionRoundScore(correct_answers: Array<number>) {
  // if(correct_answers.length ===1){
  //   return 0;
  // }
  const arrOfNum = correct_answers.map(str => {
    return Number(str);
  });
  if(arrOfNum.length===1 && arrOfNum.includes(0)){
    return 0;
  }
  return arrOfNum.length;
}

export function calculatePPRoundScore(total: number, score: IScore) {
  if (score.is_pp_active)
    // return (total += score.noOfRounds - score.roundId + 1);
    // return (total += 10 - score.roundId + 1);
    switch(score.roundId){
      case 1 : total+=10;
      break;
      case 2: total+=9;
      break;
      case 3: total+=8;
      break;
      case 4: total+=6;
      break;
      case 5+1: total+=5;
      break;
      case 6+1: total+=4;
      break;
      case 7+1: total+=2;
      break;
      case 8+1: total+=1;
      break;
      default:total+=0;
    }
  return total;
}

export function calculateJJRoundScore(score: number) {
  return score * 2;
}

export function calculateTheListRoundScore(correct_answers: Array<number>) {
  const arrOfNum = correct_answers.map(str => {
    return Number(str);
  });
  let sum = 0;
  if (correct_answers.length === 0) return sum;
  if (arrOfNum[0] === 1) {
    for (let index = 0; index <correct_answers.length; index++) {
      if (arrOfNum[index] === index + 1) sum++;
      else break;
    }
  }
  return sum;
}

