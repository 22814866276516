var apiResult = {
    gameName: '',
    locationName: '',
    noOfRounds: 10
};

const initialValues = {
    count: 4,
    noOfRounds: 10,
    list: [''],
    gameId: '',
    location: '',
    jjRounds: [[]],
    showModal: false,
    apiResult
}
 const createContestStateReducer =(state=initialValues,action:any)=>{
    if(action.type==='CREATE_CONTEST_STATE'){
        return state = action.payload;
    }
    return state;
    }
    export default createContestStateReducer;