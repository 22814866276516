import { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';


import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// import draftToHtml from "draftjs-to-html";

export default class TextEditor extends Component<any> {
  // constructor(props:any) {
  //   super(props);
  // }
 
  state = {
    editorState: EditorState.createEmpty(),
  };

  onEditorStateChange = (editorState: any) => {
    this.setState({
      editorState,
    });
    console.log( "TD",convertToRaw(editorState.getCurrentContent()).blocks[0].text);
    
    this.props.onQuestionInputHandler(draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    ))

  };

  
render() {
  const { editorState } = this.state;
  
  
  return (
    <div>
<div className="border-textEditor">
  
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          options: ['inline','fontSize', 'fontFamily', 'textAlign', 'history'],
          fontFamily: {
            options: ['Arial','AlternateGothic', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          }
        }}
      />  
     
    </div>
    {this.props.quesErr?this.props.quesErr.errors.length ? <span className="text-danger">{this.props.quesErr.errors[0].message}</span> : <></>:<></>}
    </div>
    
  );
}
}