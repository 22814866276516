import IGamesData from "../interfaces/IGamesData";
// import IScore from "../interfaces/IScore";
export default function updateScore(score:any, apiRes:IGamesData[]) {
    let gData=apiRes;    
       let locationTeamData=apiRes;
    let list=locationTeamData[0].info;
  
    let index = list.findIndex(
      element => element.roundId === score.roundId && element.teamId === score.teamId
    );
    
    list[index].correct_answers=score.correct_answers;
    list[index].is_jj_active=score.is_jj_active;
    list[index].is_pp_active=score.is_pp_active;
      //let scoreListRes = response.data.data;
      return gData;
    // return gData;
  }