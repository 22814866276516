import { BIONError, ValidationResponse } from '../interfaces/error';
/**
 * Function to validate value as per key provided
* @param {string} key   The unique key
* @param {any} value   The value to validate against
* @returns {ValidationResponse}
 */

export function Validate(key: string, value: any): ValidationResponse {
    var response: ValidationResponse = {
        key: key,
        errors: []
    };

    switch (key) {
        case 'locationName':
            if (!value) {
                let error: BIONError = {
                    message: 'Location name cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;
        case 'gameName':
            if (!value) {
                let error: BIONError = {
                    message: 'Game name cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;
        case 'questionSearch':
            if (value !== '' && value.length < 3) {
                let error: BIONError = {
                    message: 'Minimum 3 charcters are required to search a question',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            
            break;

        case 'question':
            if (!value) {

                let error: BIONError = {
                    message: 'Question cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            } else if (value === "<p></p>\n") {
                let error: BIONError = {
                    message: 'Question cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;
        case 'ppAnswer':
            if (!value) {

                let error: BIONError = {
                    message: 'Ponderous Puzzle Answer cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            } else if (value === "<p></p>\n") {
                let error: BIONError = {
                    message: 'Ponderous Puzzle Answer cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;
        case 'options':
            
            if (value.filter((e: any) => e.option === "<p></p>\n").length > 0) {
                let error: BIONError = {
                    message: 'Options cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length === 0) {
                let error: BIONError = {
                    message: 'Options cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }

            if (value.length === 1 && value[0].option === "<p></p>\n") {
                let error: BIONError = {
                    message: 'Options cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length === 1 && value[0].option === "") {
                let error: BIONError = {
                    message: 'Options cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length > 1) {
                for (let i = 0; i < value.length; i++) {
                    console.log("value[i]", value[i]);
                    console.log("value[i].length", value[i].length);
                    if (value[i] === undefined) {
                        response.errors.push({
                            message: 'Answers cannot be blank',
                            description: ''
                        });
                        return response;
                    }
                    else
                        if (value[i].length && value[i].option === "" && value[i].option === "<p></p>\n") {
                            response.errors.push({
                                message: 'Options cannot be blank',
                                description: ''
                            });
                            return response;
                        }
                }
            }
            break;
        case 'clue':
            if (!value) {
                let error: BIONError = {
                    message: 'Clue cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.filter((e: any) => e.option === "<p></p>\n").length > 0) {
                let error: BIONError = {
                    message: 'Clue cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length === 0) {
                let error: BIONError = {
                    message: 'Clue cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }

            if (value.length === 1 && value[0].option === "<p></p>\n") {
                let error: BIONError = {
                    message: 'Clue cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length === 1 && value[0].option === "") {
                let error: BIONError = {
                    message: 'Clue cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length > 1) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i].option === "") {
                        response.errors.push({
                            message: 'Clue cannot be blank',
                            description: ''
                        });
                        return response;
                    }
                }
            }
            break;
        case 'singleClue':
            if (!value) {

                let error: BIONError = {
                    message: 'Clue cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            } else if (value === "<p></p>\n") {
                let error: BIONError = {
                    message: 'Clue cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;
        case 'opt':
            if (!value) {
                let error: BIONError = {
                    message: 'Option cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;
        case 'listopt':
            if (!value) {
                let error: BIONError = {
                    message: 'Answer cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;

        case 'answer':
            console.log("value", value);
            console.log("value.length", value.length);

            if (value.length === 0) {
                let error: BIONError = {
                    message: 'Answers cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length === 1 && value[0].option === "") {

                let error: BIONError = {
                    message: 'Answers cannot be blank',
                    description: ''
                };

                response.errors.push(error);
                return response;
            }
            if (value.length > 1) {
                for (let i = 0; i < value.length; i++) {
                    console.log("value[i]", value[i]);
                    if (value[i] === undefined) {
                        response.errors.push({
                            message: 'Answers cannot be blank',
                            description: ''
                        });
                        return response;
                    }
                    else if (value[i].option === "" && value[i].option === "<p></p>\n") {
                        response.errors.push({
                            message: 'Answers cannot be blank',
                            description: ''
                        });
                        return response;
                    }
                }
            }
            break;
        case 'answerData':

            if (!value) {
                let error: BIONError = {
                    message: 'Answer cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            } else if (value === "<p></p>\n") {
                let error: BIONError = {
                    message: 'Answer cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;

        case 'clues':
            console.log("value", value);
            console.log("value.length", value.length);

            if (value.length === 0) {
                let error: BIONError = {
                    message: 'Clues cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            else if (value.length === 1 && value[0].clue === "") {


                let error: BIONError = {
                    message: 'Clues cannot be blank',
                    description: ''
                };

                response.errors.push(error);
                return response;
            }
            if (value.length === 1 && value[0].clue === "<p></p>\n") {


                let error: BIONError = {
                    message: 'Clues cannot be blank',
                    description: ''
                };

                response.errors.push(error);
                return response;
            }
            if (value.length > 1) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i] === undefined) {
                        response.errors.push({
                            message: 'Clues cannot be blank',
                            description: ''
                        });
                        return response;
                    }
                    if (value[i].clue === "") {
                        response.errors.push({
                            message: 'Clues cannot be blank',
                            description: ''
                        });
                        return response;
                    }
                    if (value[i].clue === "<p></p>\n") {
                        response.errors.push({
                            message: 'Clues cannot be blank',
                            description: ''
                        });
                        return response;
                    }
                }
            }
            break;

        case 'keyword':
            if (value.length > 20) {
                let error: BIONError = {
                    message: 'Maximum character limit is 20',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;
        case 'host':
            if (!value) {
                let error: BIONError = {
                    message: 'Host cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;
        default:
            break;
        case 'listoptions':
            if (value.filter((e: any) => e.option === "<p></p>\n").length > 0) {
                let error: BIONError = {
                    message: 'Answer cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length === 0) {
                let error: BIONError = {
                    message: 'Answer cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }

            if (value.length === 1 && value[0].option === "<p></p>\n") {
                let error: BIONError = {
                    message: 'Answer cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length === 1 && value[0].option === "") {
                let error: BIONError = {
                    message: 'Answer cannot be blank',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length > 1) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i].option === "") {
                        response.errors.push({
                            message: 'Answer cannot be blank',
                            description: ''
                        });
                        return response;
                    }
                }
            }
            break;
        case 'fileUploadType':
            if (value !== 'xlsx') {
                let error: BIONError = {
                    message: 'Please Select excel file',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;
            case 'fileSize':
              if(value>50000){
                let error: BIONError = {
                    message: 'Maximum file size should be less than 50 kb',
                    description: ''
                };
                response.errors.push(error);
                return response;
              }            
                break;
        case 'quizCode':
            console.log(value.length);
            if (value === '') {
                let error: BIONError = {
                    message: 'Please Type Numeric Value',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (!value) {
                let error: BIONError = {
                    message: 'Quiz code cannot be blank.',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            if (value.length !== 6) {
                let error: BIONError = {
                    message: 'Quiz code should be of 6 digit.',
                    description: ''
                };
                response.errors.push(error);
                return response;
            }
            break;
    }
    return response;

}