import { useEffect, useState } from "react";
import _ from "lodash";
import IGamesData from "../interfaces/IGamesData";
import { GetLeaderBoardData, ClaculateScore, GetTotalScoreOfAllRoundsOfParticularTeam } from "../Dummy-data/GameDataService";
import ScoreCardDisplayButton from "./ScoreCardDisplayButton";
import { SCORE_APIS_URL } from "../config/config";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from 'react-redux';


const axios = require('axios').default;
interface ITeamData {
  teamId: String,
  teamName: String
}
function TeamWise(props: any) {

  const { getAccessTokenSilently } = useAuth0();
  const [teams, setTeams] = useState<Array<String>>([]);
  const [teamNames, setTeamNames] = useState<Array<String>>([]);
  const [, setGamelist] = useState<IGamesData[]>([]);
  const [, setScoreData] = useState<any>();
  const scoreEditoReduxData: any = useSelector((state: any) => state.scoreEditorState);
  

  const getData = (gamelist: any, ele: any) => {
    if (gamelist.length > 0) {
      let totalscore: number = GetTotalScoreOfAllRoundsOfParticularTeam(gamelist, ele)
      console.log(totalscore);
      return <span id="teamName" className="fw-20">{totalscore}</span>
    }

  }
 
  /**
       * function to dispaly team wise score
       * @returns {void}
  */
  const showData = (i: any) => {
    let tieBreakRound: any = scoreEditoReduxData.gamesData[0].no_of_rounds + 1;

    if (scoreEditoReduxData.gamesData.length > 0) {
      return (
        scoreEditoReduxData.gamesData[0].info.sort((a: any, b: any) => a.teamName.localeCompare(b.teamName)).filter((item: any) => (item.teamId.toString() === (teams[i]))).map((score: any, index: any) => (
          <div>
            {(!props.roundCondition) && (<tr key={index} className="table-row accordion-item row align-items-center m-0 ">
              {index !== tieBreakRound ?
                <><td className="col-lg-2 col-xs-12 col-sm-12 col-md-2 text-truncate py-2 text-start">{score.roundId === 5 ? 'The List' : (score.roundId > 5) ? `Round ${index}` : `Round ${index + 1}`}</td>
                <td className="col-lg-9 col-sm-12 col-xs-12 col-md-9 d-flex text-center p-0 gap-4">
                <ScoreCardDisplayButton data={score} id={index} />
              </td>
              <td className="col-lg-1 col-sm-12 col-xs-12 col-md-1 td-right text-truncate fw-bold py-2 pc-1 fw-20">
                {ClaculateScore(score)}
              </td>
              </>
                :
               <> <td className="col-lg-2 col-xs-12 col-sm-12 col-md-2 text-truncate py-2 text-start">Tie-Break</td>
               <td className="col-lg-9 col-sm-12 col-xs-12 col-md-9 d-flex text-center p-0 gap-4">
             
              </td>
              <td className="col-lg-1 col-sm-12 col-xs-12 col-md-1 td-right text-truncate fw-bold py-2 pc-1 fw-20">
              {score.correct_answers.length>0?score.correct_answers[0] !== ''?score.correct_answers[0]:0:0}
              </td>
               </>
              }

              
              
            </tr>)
            }

            {(props.roundCondition && props.round === `${"" + (index + 1)}`) && (<tr key={index} className="table-row accordion-item row align-items-center m-0 ">

              <td className="col-lg-2 col-xs-12 col-sm-12 col-md-2 text-truncate py-2 text-start">{score.roundId === 5 ? 'The List' : (score.roundId > 5) ?score.roundId === tieBreakRound+1?'Tie-Break': `Round ${index}` : `Round ${index + 1}`}</td>
              <td className="col-lg-9 col-sm-12 col-xs-12 col-md-9 d-flex text-center p-0 gap-4">
              {index !== tieBreakRound ?<ScoreCardDisplayButton data={score} id={index} />:<></>}
              </td>
              <td className="col-lg-1 col-sm-12 col-xs-12 col-md-1 td-right text-truncate fw-bold py-2 pc-1 fw-20">
                {index !== tieBreakRound ?ClaculateScore(score):score.correct_answers.length>0?score.correct_answers[0] !== ''?score.correct_answers[0]:0:0}
              </td>
            </tr>)
            }
          </div>
        ))


      )
    }
  }


  /**
      * function to get headers
      * @returns {void}
  */
  const getHeader = async () => {
    const token = await getAccessTokenSilently();
    console.log(token);
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      }
    }
    return config;
  }

    /**
       * function to get team wise score data from api
       * @returns {void}
    */
  const getDataFromApi = async () => {
    let config = await getHeader();
    axios.get(`${SCORE_APIS_URL}/${props.locationId}/${props.gameId}`, config)
      .then(function (response: any) {
        // handle success
        let scoreListRes = response.data.data;
        console.log(scoreListRes);
        setGamelist(scoreListRes);
        
        let dataToDisplay = GetLeaderBoardData(scoreListRes);
        setScoreData(dataToDisplay);
        console.log(dataToDisplay);
        let scoreofList: any = scoreListRes[0].info;
        let teamIds = _.keys(_.countBy(scoreofList, (data) => { return data.teamId }));
        let teamNames: any = [];
        for (let index = 0; index < teamIds.length; index++) {
         let data:any=dataToDisplay.find((ele: any) => {
          return ele.teamId === teamIds[index]
        })
        teamNames?.push(data.teamName)     
        }

        let arr: Array<ITeamData> = [];
        for (let i = 0; i < teamIds.length; i++) {
          arr.push(
            {
              teamId: teamIds[i],
              teamName: teamNames[i]
            }
          )
        }
      
        let sortedArrayOfTeam = arr.sort((a: any, b: any) => a.teamName.localeCompare(b.teamName));
        let newteamIds: Array<String> = [];
        let newteamNames: Array<String> = [];
        sortedArrayOfTeam.map((ele, i) => (
          newteamIds.push(ele.teamId),
          newteamNames.push(ele.teamName)
        ))
        
        setTeams(newteamIds);
        setTeamNames(newteamNames);
        
      })
      .catch(function (error: any) {
        // handle error
        console.log(error);
      })
  }


  useEffect(() => {
    getDataFromApi();
  }, [])


  return (
    <div>
      <div className="row">
        <div className="col-lg-12 px-0">
          <div className="accordion" id="teams-data">
            {
              teamNames.map((ele, i) => (

                <div>
                  {(!props.teamCondition) && (
                    <div key={i} className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className={props.expandBtn} type="button" data-bs-toggle="collapse"
                          data-bs-target={"#collapseOne" + i} aria-expanded="false" aria-controls="collapseOne">

                          <div className="d-flex justify-content-between w-100 pe-5">
                            <span id="teamName" className="fw-normal">{ele} </span>
                            <span id="teamScore" className="fw-normal"> {getData(scoreEditoReduxData.gamesData, ele)}</span>
                          </div>
                        </button>
                      </h2>
                      <div id={"collapseOne" + i} className={props.collpaseClass} aria-labelledby="headingOne" data-bs-parent="#round-data">
                        <table className="accordion-body w-100">
                          <tbody>
                            {showData(i)}


                          </tbody>
                        </table>
                      </div>
                    </div>)
                  }
                  {(props.teamCondition && props.team === `${"" + (i + 1)}`) && (
                    <div key={i} className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className={props.expandBtn} type="button" data-bs-toggle="collapse"
                          data-bs-target={"#collapseOne" + i} aria-expanded="false" aria-controls="collapseOne">

                          <div className="d-flex justify-content-between w-100 pe-5">
                            <span id="teamName" className="fw-normal">{ele} </span>
                            <span id="teamScore" className="fw-normal"> {getData(scoreEditoReduxData.gamesData, ele)}</span>
                          </div>
                        </button>
                      </h2>
                      <div id={"collapseOne" + i} className={props.collpaseClass} aria-labelledby="headingOne" data-bs-parent="#round-data">
                        <table className="accordion-body w-100">
                          <tbody>
                            {showData(i)}

                          </tbody>
                        </table>
                      </div>
                    </div>)
                  }
                </div>

              ))
            }
          </div>

        </div>
      </div>
    </div>
  );
}

export default TeamWise;