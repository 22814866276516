import { Modal } from 'react-bootstrap';


function ValidateQuizModal(props: any) {
  return (
    <>
      <Modal show={props.isShowModal}>
        <Modal.Header className="quizCodeModal">
          <div className="text-center">
            <h3>Quiz Code</h3>
          </div>
          <div className="text-center">
            <span className="close-cross" onClick={props.onCancelBtn}>&times;</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-3 col-sm-3 p-2">
              <label>Enter quiz code:</label>
            </div>

            <div className="col-md-9 col-sm-9">
              <input id="quizCode" type="number" pattern="[0-9]*" className="form-control" placeholder="Quiz Code" onChange={(e) => {
                if (e.target.value.length < 7) {
                  props.onInputBtn(e)
                } else {
                  e.target.value = e.target.value.slice(0, 6)
                }
              }} />


              <p></p>
              {props.quizCodeError.errors.length ? <p><span className="text-danger text-left">{props.quizCodeError.errors[0].message}</span></p> : <></>}
              {props.quizCodeLenError ? <p><span className="text-danger">{props.quizCodeLenError}</span></p> : <></>}
            </div>
          </div>

          <div className="text-center">
            <button className="btn m-2" onClick={props.onHandleOkBtn} disabled={!props.validateOkBtnDisable} style={{
              backgroundColor: '#6a7075',
              color: 'white'
            }}>OK
            </button>
          </div>
        </Modal.Body>
      </Modal>

    </>

  );
}

export default ValidateQuizModal;