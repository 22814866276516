import * as yup from 'yup';
const passwordRules =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
export const basicSchema = yup.object().shape({
    email:yup.string().email('Please Enter Email').required("Required"),
    password:yup
            .string()
            .min(5)
            .matches(passwordRules,{message:"Please create a stronger Password"})
            .required("Required"),

// firstName:yup.string().required("Required"),
// lastName:yup.string().required("Required"),

})