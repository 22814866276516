import { Modal } from 'react-bootstrap';
import { useState} from 'react';
function ModalPipeAnswer(props: any) {

  const[showQuestions,setShowQuestions]=useState(false);
  const[btnText,setBtnText]=useState("Show questions");

  console.log(props)
 
  /**
   * Callback function to close modal message
   * @returns {void}
   */
  const cancelAnswerDetail = () => {
    props.cancelAnswerDetail();
  }

  /**
   * Callback function to close modal message
   * @returns {void}
   */
   const displayQuestions = () => {
    if(showQuestions === true){
      setShowQuestions(false);
    }else{
      setShowQuestions(true);
    }
  
    if(btnText === "Hide questions"){
      setBtnText("Show questions");
    }else{
      setBtnText("Hide questions");
    }
   
  }

  return (
    <Modal show={props.showModal} className="show-ans-modal">
      <Modal.Body className="text-center" style={{ overflowY: "auto" }} >
       <table className="table table-striped" style={{ height: "40px", overflowY: "scroll" }}>
          <thead style={{ height: "41px" }}>
            <tr>
              <th scope="col">#</th>
              <th scope="col"></th>
             {showQuestions === true?<th scope="col">Question</th>:<></>} 
              <th scope="col">Answer</th>
              <th scope="col">Host Info</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((ele: any, i: any) => {
              console.log("GGGGGGG",ele)
            return (
              < tr className={ele.quesType !== 'pp' ? "" : "pp-ans"} >
                   <td>{ele.quesType === 'Multimedia' ? <><i className="fas fa-photo-video"></i></> : ele.quesType === 'Cryptic Clue' ? <><i className="fa fa-user-secret"></i></> :<><i className="far fa-question-circle"></i></>}</td>
                <th scope="row">{i + 1}</th>
             
               {showQuestions === true?<td>{ele.question}</td>:<></>}
                <td>{ele.ans}</td>
                <td>{ele.quesType !== 'pp' ?ele.hostInfo:"Ponderous Puzzle"}</td>
              </tr>
            )})}
          </tbody>
        </table>
        <div>
         
          <button className="btn btn-success" onClick={displayQuestions} >{btnText}</button>
          <button className="btn btn-success" onClick={cancelAnswerDetail} style={{"marginLeft":"10px"}}>Close</button>
        </div>
      </Modal.Body>

    </Modal >
  );
}

export default ModalPipeAnswer;