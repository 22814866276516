import Layout from "./Layout";
import TeamRegistration from "./TeamRegistration";
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { GAME_APIS_URL, SCORE_APIS_URL } from "../config/config";
import { useDispatch } from 'react-redux';
import { actionCreators } from '../state/index';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "./common/LoadingSpinner";
import { ValidationResponse } from "../interfaces/error";
import { Validate } from "../utils/validation";
import ITeam from "../interfaces/ITeam";
import { useAuth0 } from "@auth0/auth0-react";
import ModalComponent from "./common/ModalComponent";
import _ from 'lodash'

const axios = require('axios').default;

interface IGamesInfo {
    locationId: number,
    locationName: String,
    gameId: number,
    gameName: String,
    noOfRounds: number,
}
const getCurrentDate = () => {
    const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let nameOfDay = dayName[date.getDay()];

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${nameOfDay}, ${day}/${month}/${year}`;
    return currentDate;
}
function CreateContest() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location: any = useLocation();
    let registerTeamRequest: Array<ITeam> = [];
    var apiResult = {
        gameName: '',
        locationName: '',
        noOfRounds: 8,
        registerTeamRequest
    };

    const { getAccessTokenSilently } = useAuth0();
    const [, setToken] = useState<any>();
    // const mainClass = "mainWithoutNav";;
    const populateOldTeams: any = useSelector((state: any) => state.populateOldTeamReducer);
    
    let jjRounds: Array<Array<String>> = [];
    const initialValues = {
        count: 1,
        noOfRounds: 8,
        quizId: 0,
        list: [''],
        gameId: '',
        location: '',
        jjRounds,
        showModal: false,
        apiResult
    }
    interface ILocationIdAndGameId{
        locationId:number|null;
        gameId:number|null;
    }

   

    
    let [data, setData] = useState(initialValues);

    const [locationIdAndGameId,setLocationIdAndGameId] = useState<ILocationIdAndGameId>(
        {
            locationId:null,
            gameId:null
        }
    );
     

    const [createOrUpdateLabel, setCreateOrUpdateLabel] = useState("Create Game");
    const [createOrUpdateBtnText, setcreateOrUpdateBtnText] = useState("Save Game");
    const [showModalOfRegistartionFailed, setShowModalOfRegistartionFailed] = useState(false);
    const [locationNameError, SetLocationNameError] = useState<ValidationResponse>({
        key: 'locationName',
        errors: []
    });
    const [gameNameError, SetGameNameError] = useState<ValidationResponse>({
        key: 'gameName',
        errors: []
    });
    const [registerOrUpdateModalMsg, setRegisterOrUpdateModalMsg] = useState("Teams successfully registered");
    const [clearCount, setClearCount] = useState(false);
    interface IteamInfo {
        teamName: String,
        teamId: number
    }
    let [teamInfo, setTeamInfo] = useState<Array<IteamInfo>>([]);
    const [addTeamsBtnClass, setAddTeamsBtnClass] = useState("fas fa-plus h-100 px-3 py-2 bg-primary text-white border border-dark");
    const [disable, setDisable] = useState(false);
    const [, setArray] = useState([0, 0, 0, 0]);
    const [, setRefresh] = useState(0);
    const [Rounds, setRounds] = useState(0);
    const [quizId, setQuizId] = useState(0);
    const [showDropdownOrTextbox, setShowDropdownOrTextbox] = useState({
        txtbox: "form-control display-none",
        dropdown: "form-select"
    });
    const[isLoaderVisible,setIsLoaderVisible]=useState(false);
    const [hasGameDeleted, setHasGameDeleted] = useState(false);
    const addTeamRef = useRef(null) as any;

    useEffect(() => {
        window.scrollBy(0, 300);
    }, [data.count]);

    const addTeams = () => { 
        setData({
            count: data.count + 1,
            noOfRounds: data.noOfRounds,
            quizId: data.quizId,
            list: data.list,
            gameId: data.gameId,
            location: data.location,
            jjRounds: data.jjRounds,
            showModal: false,
            apiResult: data.apiResult
        });
        dispatch(actionCreators.createContestState({
            count: data.count + 1,
            noOfRounds: data.noOfRounds,
            quizId: data.quizId,
            list: data.list,
            gameId: data.gameId,
            location: data.location,
            jjRounds: data.jjRounds,
            showModal: false,
            apiResult: data.apiResult
        }));
    }

    const handleInputChange = (val: any, id: any) => {
        var items: any = data.list;
        items[id] = val;
        if (teamInfo.length !== 0 && items.length === teamInfo.length) {
            let newteamInfo = teamInfo;
            newteamInfo[id].teamName = val;
            setTeamInfo(newteamInfo);
        }
        setData({
            count: data.count,
            noOfRounds: data.noOfRounds,
            quizId: data.quizId,
            list: items,
            gameId: data.gameId,
            location: data.location,
            jjRounds: data.jjRounds,
            showModal: false,
            apiResult: data.apiResult
        });
        dispatch(actionCreators.createContestState({
            count: data.count,
            noOfRounds: data.noOfRounds,
            quizId: quizId,
            list: items,
            gameId: data.gameId,
            location: data.location,
            jjRounds: data.jjRounds,
            showModal: false,
            apiResult
        }));
    }

    const handleItemDelete = (id: any) => {
        if (teamInfo.length !== 0) {
            let oldTeamInfo = [...teamInfo];
            let newTeanInfo = oldTeamInfo.filter((item, i) => i !== id);
            setTeamInfo(newTeanInfo);

        }

        var oldList = data.list;
        const newList = oldList.filter((item, i) => i !== id);
        var oldJJRounds = data.jjRounds;
        const newJjRounds = oldJJRounds.filter((item, i) => i !== id);
        
        let newData = { ...data };
        newData.count = data.count - 1;
        let newArray = [];
        for (let index = 0; index < newData.count; index++) {
            newArray[index] = 0;
        }

        setArray(newArray);
        newData.list = newList;
        newData.jjRounds = newJjRounds;
        
        if (newData.list.length < 3 && data.count < 4) {
            //show toast
            showToastOfTeamCount();
            return

        }
        setData(newData);
        dispatch(actionCreators.createContestState(newData));

    }

    const setSelectedOption = (e: any) => {
        let apiRes: any = { ...data.apiResult };
        apiRes.gameName = '';
        apiRes.locationName = '';
        
        setData({
            count: data.count,
            noOfRounds: e,
            quizId: data.quizId,
            list: data.list,
            gameId: data.gameId,
            location: data.location,
            jjRounds,
            showModal: false,
            apiResult: data.apiResult
        });
        setClearCount(true);

        dispatch(actionCreators.createContestState({
            count: data.count,
            noOfRounds: e,
            quizId: quizId,
            list: data.list,
            gameId: data.gameId,
            location: data.location,
            jjRounds: [[]],
            showModal: false,
            apiResult
        }));
    }

    

    const getValueFromInputGameId = (e: any) => {
        setData({
            count: data.count,
            noOfRounds: data.noOfRounds,
            quizId: quizId,
            list: data.list,
            gameId: e,
            location: data.location,
            jjRounds: data.jjRounds,
            showModal: false,
            apiResult: data.apiResult
        });
        let validationResponse: ValidationResponse = Validate('gameName', e);
        SetGameNameError(validationResponse);
        dispatch(actionCreators.createContestState({
            count: data.count,
            noOfRounds: data.noOfRounds,
            quizId: quizId,
            list: data.list,
            gameId: e,
            location: data.location,
            jjRounds: data.jjRounds,
            showModal: false,
            apiResult
        }));
        dispatch(actionCreators.openModal(true));

    }

    const getValueFromInputLocation = (e: any) => {
        setData({
            count: data.count,
            noOfRounds: data.noOfRounds,
            quizId: quizId,
            list: data.list,
            gameId: data.gameId,
            location: e,
            jjRounds: data.jjRounds,
            showModal: false,
            apiResult: data.apiResult
        });
        let validationResponse: ValidationResponse = Validate('locationName', e);
        SetLocationNameError(validationResponse);
        dispatch(actionCreators.createContestState({
            count: data.count,
            noOfRounds: data.noOfRounds,
            quizId: quizId,
            list: data.list,
            gameId: data.gameId,
            location: e,
            jjRounds: data.jjRounds,
            showModal: false,
            apiResult: data.apiResult
        }));
        dispatch(actionCreators.openModal(true));
    }

    const onSaveContest = async () => {

        let locationErrorResponse: ValidationResponse = Validate('locationName', data.location);
        if (locationErrorResponse.errors.length) {
            SetLocationNameError(locationErrorResponse);
            return;
        }
        if (data.list.length < 3) {
            //show toast
            showToastOfTeamCount();
            return

        }
        
        setIsLoaderVisible(true);
        if (createOrUpdateBtnText === "Save Game") {
            prepareCreateGameData();
        }
        else {
            prepareUpdateData();
        }


    }

    const showDuplicateGameToast = () => {
        toast('This Game is already registered on this location', {
            type: 'info',
            toastId: 'success1'
        });
    }

    const insertEmptyScoreIntoDb = async (finalData: any, message: any) => {
        let infoArray = [];
        let idx = 0;
        let tieBreakRoundNo = message.noOfRounds + 1;
        for (idx = 0; idx < message.noOfRounds + 2; idx++) {
            for (let jdx = 0; jdx < message.registerTeamRequest.length; jdx++) {
                if (idx === 4) {
                    let obj = {
                        roundId: (idx + 1),
                        roundType: 1,
                        teamId: message.registerTeamRequest[jdx].teamId,
                        teamName: message.registerTeamRequest[jdx].teamName,
                        correct_answers: [],
                        is_pp_active: false,
                        is_jj_active: false
                    }
                    infoArray.push(obj);
                } else {
                    //check given idx +1 is jj or not

                    if ((idx + 1) > 4) {
                        let isJJROund = finalData.registerTeamRequest[jdx].jjRounds.includes(idx);
                        let obj = {
                            roundId: (idx + 1),
                            roundType: idx === tieBreakRoundNo ? 2 : 0,
                            teamId: message.registerTeamRequest[jdx].teamId,
                            teamName: message.registerTeamRequest[jdx].teamName,
                            correct_answers: [],
                            is_pp_active: false,
                            is_jj_active: isJJROund
                        }
                        infoArray.push(obj);
                    } else {
                        let isJJROund = finalData.registerTeamRequest[jdx].jjRounds.includes(idx + 1);
                        let obj = {
                            roundId: (idx + 1),
                            roundType: 0,
                            teamId: message.registerTeamRequest[jdx].teamId,
                            teamName: message.registerTeamRequest[jdx].teamName,
                            correct_answers: [],
                            is_pp_active: false,
                            is_jj_active: isJJROund
                        }
                        infoArray.push(obj);
                    }


                }

            }
        }
        let createScoreReqData = {
            data: {
                info: infoArray
            }
        }
        //post data 
        // const locationId = location.state.locationId;
        // const gameId = location.state.gameId;
        const locationId = message.locationId;
        let config = await getHeader();
        const gameId = message.gameId;
        axios.post(`${SCORE_APIS_URL}/${locationId}/${gameId}`, createScoreReqData, config)
            .then(function (response: any) {
                // console.log(`Response Received${response}`);
            })
            .catch(function (error: any) {
                console.log('failure');
            });


    }

    const updateJJRoundsList = (id: any, list: any) => {
        let newObj = { ...data };
        newObj.jjRounds[id] = list;
        setData(newObj);
        dispatch(actionCreators.createContestState(newObj));
    }

    const closeModal = () => {
        let newState = { ...data };
        newState.showModal = false;
        setData(newState);
        setRefresh(c => -c);
        dispatch(actionCreators.createContestState(newState));
        dispatch(actionCreators.isActiveComponent('create-contest'));
    }

    const startGameRef = useRef() as any

    const onStartGame = async () => {
        
        let currentGame: any;
        let config = await getHeader();
        await axios.get(`${GAME_APIS_URL}`, config)
            .then(function (response: any) {
                startGameRef?.current?.classList.remove("loading");
                let allGame: IGamesInfo[] = response.data.message;
                currentGame = allGame.find((item) => item.locationName === data.location && item.gameName === data.gameId);
            })
            .catch(function (error: any) {
                console.log(`Response Failure ${error}`);

            });
        
        navigate("/score-editor", {
            state:
            {
                locationId: location.state.locationId ? location.state.locationId : currentGame.locationId,
                gameId: location.state.gameId ? location.state.gameId : currentGame.gameId,
                gameName: location.state.gameName ? location.state.gameName : currentGame.gameName,
                locationName: location.state.locationName ? location.state.locationName : currentGame.locationName,
                noOfRounds: location.state.noOfRounds ? location.state.noOfRounds : currentGame.noOfRounds,
                quizId:quizId !== 0?quizId:location.state.quizId
            }
        });
    }

    const showToast = () => {
        toast('Up to two Joker rounds can be selected', {
            type: 'info',
            toastId: 'success1'
        });
    }

    const showToastOfTeamCount = () => {
        toast('At least three teams required for a game.', {
            type: 'info',
            toastId: 'success1'
        });
    }

    // const OnTryAgain = () => {
    //     onSaveContest();
    // }

    const closeRegistartionFailModal = () => {
        setShowModalOfRegistartionFailed(false);
    }

    const prepareCreateGameData = async () => {
        let updateDataState = { ...data };

        updateDataState.apiResult.locationName = data.location;
        updateDataState.apiResult.gameName = data.gameId;
        let teamsArray: Array<ITeam> = [];
        // if there is no existing teams
        if (teamInfo.length !== 0) {


            for (let i = 0; i < teamInfo.length; i++) {
                let item: ITeam = {
                    teamId: teamInfo[i].teamId,
                    teamName: teamInfo[i].teamName,
                    isActive: false,
                    jjRounds: []
                }
                teamsArray.push(item);
            }


            //updating existing teamName
            for (let index = 0; index < teamsArray.length; index++) {
                teamsArray[index].teamName = teamInfo[index].teamName
            }
            for (let index = 0; index < teamsArray.length; index++) {
                const element = teamsArray[index].teamName;
                if (!data.list.includes(element.toString())) {
                    //set isActive false of that particular team
                    teamsArray[index].isActive = false;
                } else {
                    let i = data.list.indexOf(element.toString());
                    teamsArray[index].jjRounds = data.jjRounds[i] ? data.jjRounds[i] : [];
                }

            }
        }
        for (let index = 0; index < data.list.length; index++) {
            let teamEle = data.list[index];
            const found = teamsArray.some(el => el.teamName === teamEle);
            if (!found) {
                teamsArray.push({
                    teamId: -1,
                    teamName: teamEle,
                    jjRounds: data.jjRounds[index] ? data.jjRounds[index] : [],
                    isActive: true
                });
            }
        }
        updateDataState.apiResult.registerTeamRequest = teamsArray;
        let updatePostData = updateDataState.apiResult;
        updatePostData.noOfRounds = data.noOfRounds;
        // console.log(updatePostData, "UPDATE_POST_DATA");

        let locationName = (data.location !== "") ? data.location : location.state.locationName;
        let gameName = (data.gameId !== "") ? data.gameId : location.state.gameName;
        const createdByEmail: any = localStorage.getItem('email');
        const createdBy: any = localStorage.getItem('items');
        let finalData = {
            locationName,
            gameName,
            noOfRounds: data.noOfRounds,
            quizId:data.quizId,
            createdBy: JSON.parse(createdBy),
            createdByEmail: JSON.parse(createdByEmail),
            registerTeamRequest: teamsArray
        }
        console.log(JSON.stringify(finalData));

        let config = await getHeader();
        axios.post(`${GAME_APIS_URL}`, finalData, config)
            .then(function (response: any) {
                // console.log(`Response Received${response}`);
                setCreateOrUpdateLabel("Edit Game");
                setShowDropdownOrTextbox({
                    txtbox: "form-control",
                    dropdown: "form-select display-none"
                });
                setcreateOrUpdateBtnText("Update Game");
                setLocationIdAndGameId({"locationId":response.data.message.locationId, "gameId":response.data.message.gameId})
                //show Modal of successfully saved
                let newState = { ...data,apiResult:{...data.apiResult, registerTeamRequest:response.data.message.registerTeamRequest} };
                newState.showModal = true;
                setData(newState);
                dispatch(actionCreators.createContestState(newState));
                dispatch(actionCreators.openModal(false));
                setShowModalOfRegistartionFailed(false);
                //after getting response from api call the create score api
                insertEmptyScoreIntoDb(finalData, response.data.message);
                setIsLoaderVisible(false);

            })
            .catch(function (error: any) {

                if (error.response.status === 409) {
                    //show toast return
                    showDuplicateGameToast();
                    return;
                }
                setShowModalOfRegistartionFailed(true);
                setIsLoaderVisible(false);

                console.log('failure');
            });
    }

    const prepareUpdateData = async () => {
        let updateDataState = { ...data };
        updateDataState.apiResult.locationName = data.location;
        updateDataState.apiResult.gameName = data.gameId;
        let teamsArray: Array<ITeam> = data.apiResult.registerTeamRequest;

        //updating existing teamName
        for (let index = 0; index < teamsArray.length; index++) {
            //teamsArray[index].teamName = teamInfo[index].teamName
            let teamId = teamsArray[index].teamId;
            let isFound: any = teamInfo.findIndex((obj: any) => obj.teamId === teamId);
            if (isFound !== -1) {
                teamsArray[index].teamName = teamInfo[isFound].teamName;
            }
        }
        for (let index = 0; index < teamsArray.length; index++) {
            const element = teamsArray[index].teamName;
            if (!data.list.includes(element.toString())) {
                //set isActive false of that particular team
                teamsArray[index].isActive = false;
            } else {
                let i = data.list.indexOf(element.toString());
                teamsArray[index].jjRounds = data.jjRounds[i] ? data.jjRounds[i] : [];
            }

        }
        for (let index = 0; index < data.list.length; index++) {
            let teamEle = data.list[index];
            const found = teamsArray.some(el => el.teamName === teamEle);
            if (!found) {
                teamsArray.push({
                    teamId: -1,
                    teamName: teamEle,
                    jjRounds: data.jjRounds[index] ? data.jjRounds[index] : [],
                    isActive: true
                });
            }
        }
       
        updateDataState.apiResult.registerTeamRequest = teamsArray;
        let updatePostData = updateDataState.apiResult;
        updatePostData.noOfRounds = data.noOfRounds;
        // console.log(updatePostData, "UPDATE_POST_DATA");
        //post data 
        const locationId = location.state.locationId?location.state.locationId:locationIdAndGameId.locationId;
        const gameId = location.state.gameId?location.state.gameId:locationIdAndGameId.gameId;
        let config = await getHeader();
        // let obj = null;
        await axios.put(`${GAME_APIS_URL}/${locationId}/${gameId}`, updatePostData, config)
            .then(async function (response: any) {
                // console.log(`Response Received${response}`);
                // setRegisterOrUpdateModalMsg(response.data.message);
                if (response.data.description === "This game is deleted now") {
                    setHasGameDeleted(true);
                    return;
                  }
           
                setRegisterOrUpdateModalMsg("Data updated successfully");
                var newState;
                if (response.data.message.registerTeamRequest !== undefined) {
                    await prepareDataForCreateScore(response.data.message);
                    //obj = response.data.message;          
                }
                axios.get(`${GAME_APIS_URL}/` + locationId + "/" + gameId, config)
                        .then(function (response: any) {
                            
                            let res = response.data.message;
                            
                            newState = { ...data };
                            newState.apiResult = res;
                            newState.count = res.registerTeamRequest.length;
                            newState.noOfRounds = res.noOfRounds;
                            
                            let newList = [];
                            let newTeamData: Array<IteamInfo> = [];
                            for (let idx = 0; idx < res.registerTeamRequest.length; idx++) {
                                newList.push(res.registerTeamRequest[idx].teamName);
                                newTeamData?.push({
                                    teamName: res.registerTeamRequest[idx].teamName,
                                    teamId: res.registerTeamRequest[idx].teamId,
                                })
                            }
                            setTeamInfo(newTeamData);
                            let newJJList = [[]];
                            newJJList.pop();
                            for (let idx = 0; idx < res.registerTeamRequest.length; idx++) {
                                newJJList.push(res.registerTeamRequest[idx].jjRounds);
                            }
                           
                            newState.jjRounds = newJJList;
                            newState.list = newList;
                            newState.gameId = res.gameName;
                            newState.location = res.locationName;
                            newState.showModal = true;
                        
                            setData(newState);
                           
                            dispatch(actionCreators.createContestState(newState));
                            dispatch(actionCreators.openModal(false));
                            setShowModalOfRegistartionFailed(false);
                            setIsLoaderVisible(false);
    
                        })
                        .catch(function (error: any) {
                            setRegisterOrUpdateModalMsg("Data Updation Failed");
                            setShowModalOfRegistartionFailed(true);
                            console.log('failure');
                            setIsLoaderVisible(false);
                        });   
            })
            .catch(function (error: any) {
                setRegisterOrUpdateModalMsg("Data Updation Failed");
                setShowModalOfRegistartionFailed(true);
                console.log('failure');
                setIsLoaderVisible(false);
            });
        // if(obj){
        //     prepareDataForCreateScore(obj);
        // }


    }
    const prepareDataForCreateScore = async (message: any) => {

        let infoArray = [];
        let idx = 0;
        let newlyAddedTeam = message.registerTeamRequest;
        let tieBreakRoundNo = data.apiResult.noOfRounds + 1;
        for (idx = 0; idx < data.apiResult.noOfRounds + 2; idx++) {
            for (let jdx = 0; jdx < newlyAddedTeam.length; jdx++) {
                if (idx === 4) {
                    let obj = {
                        roundId: (idx + 1),
                        roundType: 1,
                        teamId: message.registerTeamRequest[jdx].teamId,
                        teamName: message.registerTeamRequest[jdx].teamName,
                        correct_answers: [],
                        is_pp_active: false,
                        is_jj_active: false
                    }
                    infoArray.push(obj);
                } else {
                    //check given idx +1 is jj or not

                    if ((idx + 1) > 4) {
                        let isJJROund = message.registerTeamRequest[jdx].jjRounds.includes(idx);
                        let obj = {
                            roundId: (idx + 1),
                            roundType: idx === tieBreakRoundNo ? 2 : 0,
                            teamId: message.registerTeamRequest[jdx].teamId,
                            teamName: message.registerTeamRequest[jdx].teamName,
                            correct_answers: [],
                            is_pp_active: false,
                            is_jj_active: isJJROund
                        }
                        infoArray.push(obj);
                    } else {
                        let isJJROund = message.registerTeamRequest[jdx].jjRounds.includes(idx + 1);
                        let obj = {
                            roundId: (idx + 1),
                            roundType: 0,
                            teamId: message.registerTeamRequest[jdx].teamId,
                            teamName: message.registerTeamRequest[jdx].teamName,
                            correct_answers: [],
                            is_pp_active: false,
                            is_jj_active: isJJROund
                        }
                        infoArray.push(obj);
                    }
                }
            }
        }
        // console.log(JSON.stringify(infoArray), "INFO_ARRAY");
        let createScoreReqData = {
            data: {
                info: infoArray
            }
        }
        const locationId = message.locationId;
        let config = await getHeader();
        const gameId = message.gameId;
        await axios.post(`${SCORE_APIS_URL}/${locationId}/${gameId}`, createScoreReqData, config)
            .then(function (response: any) {
                // console.log(`Response Received${response}`);
            })
            .catch(function (error: any) {
                console.log('failure');
            });
    }

    const getHeader = async () => {
        const email: any = localStorage.getItem('email');
        const token = await getAccessTokenSilently();
        let config = {
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            }
            ,
            params: {
                createdByEmail: JSON.parse(email?.toString())
            }
        }
        return config;
    }

    const showData = () => {


        var lis = [];
        for (var i = 4; i <= Rounds; i++) {
            lis.push(<option selected={Rounds === i} >{i}</option>);
        }

        let tableDash = <select id="selectRound" className={showDropdownOrTextbox.dropdown} defaultValue={data.noOfRounds} onChange={e => setSelectedOption(parseInt(e.target.value))} >

            {lis}

        </select>
        return (
            tableDash
        )
    }
 
    async function apiCallingOfUseEffect() {
        let config = await getHeader();
        setToken(config);
        if (location.state) {
            if (location.state.locationId !== undefined && location.state.gameId !== undefined) {
                setCreateOrUpdateLabel("Edit Game");
                setShowDropdownOrTextbox({
                    txtbox: "form-control",
                    dropdown: "form-select display-none"
                });
                setAddTeamsBtnClass("fas fa-plus h-100 px-3 py-2 bg-primary text-white border border-dark btn-disabled-update-screen btn btn-add-team");
                setcreateOrUpdateBtnText("Update Game");
                const loc = location.state.locationId;
                const gm = location.state.gameId;
                let config = await getHeader();
                axios.get(`${GAME_APIS_URL}/` + loc + "/" + gm, config)
                .then(function (response: any) {
                    
                    let res = response.data.message;
                    
                    let newState = { ...data };
                    newState.apiResult = res;
                    newState.count = res.registerTeamRequest.length;
                    newState.noOfRounds = res.noOfRounds;
                    
                    let newList = [];
                    let newTeamData: Array<IteamInfo> = [];
                    for (let idx = 0; idx < res.registerTeamRequest.length; idx++) {
                        newList.push(res.registerTeamRequest[idx].teamName);
                        newTeamData?.push({
                            teamName: res.registerTeamRequest[idx].teamName,
                            teamId: res.registerTeamRequest[idx].teamId,
                        })
                    }
                    setTeamInfo(newTeamData);
                    let newJJList = [[]];
                    newJJList.pop();
                    for (let idx = 0; idx < res.registerTeamRequest.length; idx++) {
                        newJJList.push(res.registerTeamRequest[idx].jjRounds);
                    }
                    // console.log(newJJList);
                    newState.jjRounds = newJJList;
                    newState.list = newList;
                    newState.gameId = res.gameName;
                    newState.location = res.locationName;
                    // alert("line 793");
                    setData(newState);
        
                    dispatch(actionCreators.createContestState(newState));
        
                })
                .catch(function (error: any) {
                    setShowModalOfRegistartionFailed(true);
                    console.log('failure');
                });
            } else {
                
                // if (populateOldTeams.length !== 0) {
                //     populateOldTeamsData()

                // } else {
                    let newData = { ...data };

                    newData.gameId = getCurrentDate();
                    newData.apiResult.gameName = getCurrentDate();
                    newData.noOfRounds = location.state.quizData.noOfRounds;
                    setData(newData);
                // }

            }
        } else {
            navigate("/dashboard");

        }
    }

    function populateOldTeamsData() {
        console.log("line 825")
        console.log(data)
        let newState = { ...data } as any

        console.log("bfore:", newState.list)
        newState.list = _.compact(newState.list)
        console.log("after: ", newState.list)
        newState.gameId = getCurrentDate();
        newState.apiResult.gameName = getCurrentDate();
        console.log("populateOldTeams", populateOldTeams);
        newState.count = populateOldTeams.length + newState.list.length;
        let newList = newState.list;
        let newTeamData: Array<IteamInfo> = [];
    
        for (let idx = 0; idx < populateOldTeams.length; idx++) {
            newList.push(populateOldTeams[idx].teamName);
            newTeamData?.push({
                teamName: populateOldTeams[idx].teamName,
                teamId: populateOldTeams[idx].teamId,
            })
        }
        setTeamInfo(newTeamData);
        let newJJList = [[]];
        newJJList.pop();
        for (let idx = 0; idx < populateOldTeams.length; idx++) {
            newJJList.push([]);
        }
        newState.jjRounds = newJJList;
        newState.noOfRounds = location.state.quizData.noOfRounds;
        newState.list = newList;
        // alert("line 847");
        setData(newState);

        console.log("line 851")
        console.log(data)
    }

    useEffect(() => {
        

        setRounds(0);
        setTeamInfo([]);
        setData({
            count: 4,
            noOfRounds: 8,
            quizId: 0,
            list: [''],
            gameId: '',
            location: '',
            jjRounds,
            showModal: false,
            apiResult
        });
        if (!location.state) {
            navigate("/dashboard");
        }
        if (location.state.quizData) {
            setRounds(location.state.quizData.noOfRounds);
            setQuizId(location.state.quizData.quizId);
            setSelectedOption(Rounds);
            let nd = { ...data };
            nd.noOfRounds = location.state.quizData.noOfRounds;
            setData(nd);
        }


        setDisable(false);
        setAddTeamsBtnClass("fas fa-plus h-100 px-3 py-2 bg-primary text-white border border-dark btn-disabled-update-screen btn btn-add-team-game");
        setRefresh(1);
        apiCallingOfUseEffect();
    }, [location])

    const hasGameDeletedOkBtn=()=>{
        setHasGameDeleted(false);
        navigate('/dashboard');
    }

    return (
        <>
        <div className="">
                <ToastContainer />
                <ModalComponent
                isShowModal={hasGameDeleted}
                text="This Game is deleted now"
                icon="fas fa-window-close team-red-modal-icon"
                onHandleOkBtn={hasGameDeletedOkBtn}
      />
                <Modal show={data.showModal} >
                    <Modal.Body className="text-center">
                        <i className="fas fa-check-square team-reg-modal-icon"></i>
                        <p className="p-txt">{registerOrUpdateModalMsg}</p>
                        <div>
                            {/* {registerOrUpdateModalMsg ==="Teams successfully registered" ?
                                <button ref={startGameRef} className="btn btn-secondary" onClick={()=>{startGameRef?.current?.classList.add("loading"); setTimeout(onStartGame, 2000)}}>{startGameRef?.current?.classList.contains("loading") ? "Starting" : "Start Game"}</button>
                            :
                            <> */}
                                <button className="btn btn-secondary text-left m-2" onClick={closeModal}>Stay Here</button>
                                <button ref={startGameRef} className="btn btn-success" onClick={()=>{startGameRef?.current?.classList.add("loading"); setTimeout(onStartGame, 2000)}}>{startGameRef?.current?.classList.contains("loading") ? "Starting" : "Start Game"}</button>
                            {/* </>
                            } */}
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={showModalOfRegistartionFailed} >
                    <Modal.Body className="text-center">
                        <i className="fas fa-window-close team-red-modal-icon"></i>
                        <p className="p-txt">Teams Registration Failed</p>
                        <div>
                            
                            <button className="btn bg-primary text-white text-left m-2" onClick={closeRegistartionFailModal}>ok</button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Layout finalValue={data} teamInfo={teamInfo} createOrUpdateContext={createOrUpdateLabel} locationIdAndGameId={locationIdAndGameId} />
                <div id="main" className="withoutSideBarNav">
                    <div className="pagetitle d-flex flex-wrap justify-content-between">

                        <div>
                            <h1>{createOrUpdateLabel}</h1>

                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="breadcrumb-item active">{createOrUpdateLabel}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-flex gap-8 justify-content-end action-button page-title-button">
                            <button type="button" className="btn btn-warning py-1" onClick={onSaveContest}>{createOrUpdateBtnText}</button>
                        </div>
                    </div>

                    {/* <!-- End Page Title --> */}
                    {isLoaderVisible?<LoadingSpinner/>:
                    <section className="section">
                        <div className="row">
                            <div className="col-lg-12 p-0">
                                <div className="card m-0 card-1">
                                    <div className="card-body">
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 py-1">
                                                    <div className="form-group">
                                                        <label htmlFor="locationName">Location Name</label>
                                                        <input id="locationName" type="text" defaultValue={Object.keys(data.apiResult).length === 0 ? "" : data.apiResult.locationName} className="form-control" placeholder="Enter Location" onKeyUp={e => getValueFromInputLocation(e.currentTarget.value)} />
                                                    </div>
                                                    {locationNameError.errors.length ? <span className="text-danger">{locationNameError.errors[0].message}</span> : <></>}
                                                </div>
                                                <div className="col-lg-4 col-md-6 py-1">
                                                    <div className="form-group">
                                                        <label htmlFor="gameId">Game Name</label>
                                                        <input type="text" defaultValue={Object.keys(data.apiResult).length === 0 ? "getCurrentDate()" : data.apiResult.gameName} className="form-control" onKeyUp={e => getValueFromInputGameId(e.currentTarget.value)} disabled />
                                                    </div>
                                                    {gameNameError.errors.length ? <span className="text-danger">{gameNameError.errors[0].message}</span> : <></>}
                                                </div>
                                                <div className="col-lg-4 col-md-6 py-1">
                                                    <div className="form-group">
                                                        <label htmlFor="NoOfRounds">Number of Rounds</label>
                                                        <input type="text" value={data.noOfRounds} className={showDropdownOrTextbox.txtbox} disabled />
                                                        {showData()}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <p className="text-primary px-4 info-txt"> {data.noOfRounds} Question Rounds + 1 PP + 1 THE LIST </p>
                                </div>
                            </div>
                        </div>
                        <div className="create-teams-section">
                            <div className="d-md-flex create-teams-title">
                                <h1>Teams </h1><label className="text-primary" style={{paddingTop: 4}}>&nbsp;(Up to two Joker rounds can be selected) &nbsp;&nbsp;</label>
                                <button className={addTeamsBtnClass} onClick={addTeams} disabled={disable}></button>
                                {createOrUpdateLabel === "Create Game" ? 
                                <button className="btn btn-load-teams" onClick={populateOldTeamsData} disabled={disable}>Load Previous teams</button>
                                : null}
                            </div>
                            <div className="">
                            {[...Array(data.count)].map((e, i) =>
                                <span className="busterCards" key={i}>
                                    <TeamRegistration serialNo={i + 1} noOFRounds={data.noOfRounds} elementId={i} default={data.list[i]} handleInputChange={handleInputChange} handleItemDelete={handleItemDelete} updateJJRoundsList={updateJJRoundsList} jjRounds={data.jjRounds[i]} showToast={showToast} clearCount={clearCount} />
                                </span>
                            )}
                            </div>
                            {[...Array(data.count)].length > 4 ?
                            <button className={addTeamsBtnClass + " bottom-button"} onClick={addTeams} disabled={disable}></button>
                            : <></>}
                        </div>
                    </section>
                    }
                </div>
                <div className="d-flex gap-8 justify-content-start action-button page-title-button">
                    &nbsp;<button type="button" className="btn btn-warning py-1" onClick={onSaveContest}>{createOrUpdateBtnText}</button>
                </div>
            </div>
            <div className="h-[200px]" ref={addTeamRef}><br/><br/></div>
        </>
    );
}

export default CreateContest;