import gamesData from "./FakeData.json";
import _ from "lodash";
import { APIS_URL } from "../config/config";
const axios = require('axios').default;
export function getGamesData(locationId, gameId) {
  // let gamesData=[{no_of_rounds:10,
  //   location_id:"",
  //   no_of_questions:10,
  //   game_id:"",
  //   info:[{
  //     roundId:1,
  //     teamName:"",
  //   }
  // ]}];
 //return axios.get('http://192.168.1.4:5000/scores/list/5/11')
 return axios.get(`${APIS_URL}scores/list/${locationId}/${gameId}`)
  
   //return gamesData;
}
export function getRoundWiseGameData(round){
    return gamesData[0].info.filter(item =>item.round_id===round)
}
export function getGamesDataAccToGameAndLocation(game,location){
return gamesData.filter(data=>data.game_id ===game && data.location_id===location)
}
export function updateScore(score) {
  let gData;
  getGamesData(score.location_id,score.game_id)
  .then(function (response) {
    // handle success
     gData=response.data.data
     let locationTeamData=_.find(gData, { game_id: score.game_id, location_id: score.location_id});
  let list=locationTeamData.info;

  let index = list.findIndex(
    element => element.round_id === score.round_id && element.team_id === score.team_id
  );
  
  list[index].correct_answers=score.correct_answers;
  list[index].is_jj_active=score.is_jj_active;
  list[index].is_pp_active=score.is_pp_active;
    //let scoreListRes = response.data.data;
    return gData;
  })
  .catch(function (error) {
    // handle error
    console.log(error);
    // return gamesData;
  });;
  
  // return gData;
}

export //Get leaderboard
function GetLeaderBoardData(gameData) {
  //Filter records based on game_id & location_id
// getGamesData(location.state.locationId, location.state.gameId)
//       .then(function (response) {
      
//       })
//       .catch(function (error) {
    
//         console.log(error);
       
//       });
  // const gameData = _.find(getGamesData(), {
  //   game_id: game.game_id,
  //   location_id: game.location_id,
  // });
  let scores = [];
 for(let i =0;i<gameData[0].no_of_rounds+1;i++){
  const data = gameData[0].info.filter(item=>item.roundId===(i+1));

  data.map((d) => {
    const index = scores.findIndex((e) => e.teamId === d.teamId);

    if (index < 0) {
      scores.push({
        teamId: d.teamId,
        teamName: d.teamName,
        scores: [AddRoundWiseScore(d)],
        total: ClaculateScore(d),
      });
    } else {
      scores[index].scores.push(AddRoundWiseScore(d));
      scores[index].total = CalculateTotalScore(scores[index].scores);
    }

    return null
  });
}

  scores=_.orderBy(scores, ["total"], ['desc'])
  return scores;
}
export function GetTotalScoreOfAllRoundsOfParticularTeam(gameData,teamName){
  let scores = [];
  const data = gameData[0].info.filter(item=>item.teamName===teamName);
  data.map((d) => {
    const index = scores.findIndex((e) => e.roundId=== d.roundId);

    if (index < 0) {
      scores.push({
        teamId: d.teamId,
        teamName: d.teamName,
        scores: [AddRoundWiseScore(d)],
        total: ClaculateScore(d),
      });
    } else {
      scores[index].scores.push(AddRoundWiseScore(d));
      scores[index].total = CalculateTotalScore(scores[index].scores);
    }
    return null
  });
let total=0;
  scores.map((item)=>{
total+=item.total;
return null
  })
return total;
}

function AddRoundWiseScore(d) {
  //correct_answers: d.correct_answers.length,
  let correct_answers = d.correct_answers.length===1 && d.correct_answers.includes('') ?0:d.correct_answers.length;
  let total =0;
  if(d.is_pp_active){
  switch(d.roundId){
    case 1 : total+=10;
    break;
    case 2: total+=9;
    break;
    case 3: total+=8;
    break;
    case 4: total+=6;
    break;
    case 5+1: total+=5;
    break;
    case 6+1: total+=4;
    break;
    case 7+1: total+=2;
    break;
    case 8+1: total+=1;
    break;
    default:total+=0;
  }
}
  // return {
  //   roundId: d.roundId,
  //   correct_answers,
  //   pp: d.is_pp_active ? 10 - d.roundId + 1 : 0,
  //   jj: d.is_jj_active ? d.correct_answers.length * 2 : 0,
  //   total: ClaculateScore(d),
  // };
  return {
    roundId: d.roundId,
    correct_answers,
    pp: total,
    jj: d.is_jj_active ? d.correct_answers.length * 2 : 0,
    total: ClaculateScore(d),
  };
}

export function ClaculateScore(score) {
  let total = 0;
  //check and add number of correct answers
  if(score.correct_answers.length === 1 && score.correct_answers.includes('')){
    total=0;
  }else{
    total += score.correct_answers.length;
  }

  //If is JJ=true
  if (score.is_jj_active) {
    total *= 2;
  }

  //If is PP=true
  if (score.is_pp_active) {
    //total += 10 - score.roundId + 1;
    switch(score.roundId){
      case 1 : total+=10;
      break;
      case 2: total+=9;
      break;
      case 3: total+=8;
      break;
      case 4: total+=6;
      break;
      case 5+1: total+=5;
      break;
      case 6+1: total+=4;
      break;
      case 7+1: total+=2;
      break;
      case 8+1: total+=1;
      break;
      default:total+=0;
    }
  }
//The LIst
if(score.roundType === 1){
 total = calculateTheListRoundScore(score.correct_answers);
}
  return total;
}

function CalculateTotalScore(data) {
  let sum = 0;
  data.map((d) => (sum += d.total));
  return sum;
}
function calculateTheListRoundScore(correct_answers) {
  const arrOfNum = correct_answers.map(str => {
    return Number(str);
  });
  let sum = 0;
  if (correct_answers.length === 0) return sum;
  if (arrOfNum[0] === 1) {
    for (let index = 0; index < correct_answers.length; index++) {
      if (arrOfNum[index] === index + 1) sum++;
      else break;
    }
  }
  return sum;
}
