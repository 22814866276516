let initState ={
    gameName:'',
    gameId:-1,
    locationId:-1,
    locationName:''

}
 const gameInfoReducer =(state:any=initState,action:any)=>{
    if(action.type==='GAME_INFO'){
        return state = action.payload;
    }
    return state;
    }
    export default gameInfoReducer;