import ModalTextEditor from "./OptionWithModal";
import { Modal } from 'react-bootstrap';
import { useState } from 'react';

function Options(props: any) {
    const [showModal, setShowModal] = useState(false);
    const [optionText, setOptionText] = useState("");
    
    const deleteOption = () => {
        props.onDeleteItemFromOptions(props.elementId, props.id, props.opt, props.createdBy);
    }
    const showTextEditor = () => {
        setShowModal(true);
    }
    const onHandleOkBtn = () => {
        setShowModal(false);
    }
    const getOptionInputHandler = (data: any) => {
        if (data.trim() !== "<p></p>") {
            setOptionText(data);
            props.handleInputChange(data, props.elementId, props.id, props.createdBy);
        }
    }
    return (


        <div key={props.elementId} className="row g-0 mb-2">

            <Modal show={showModal} size="lg"
                aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="text-center">
                    <ModalTextEditor getOptionInputHandler={getOptionInputHandler} opt={props.opt ? props.opt : '<p></p>'} />
                    <div>
                        <button className="btn btn-success m-2" onClick={onHandleOkBtn} style={{ "width": "12%" }}>ok</button>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="col-12 d-flex p-0">
                <div className="form-control w-50  col-6 standard-option rounded-0" onClick={showTextEditor}  >
                    <div dangerouslySetInnerHTML={{ __html: props.opt ? props.opt : optionText }} ></div>

                    
                </div>
               
                <div className="col-1 py-2 px-2">
                    <i className="fas fa-trash trash-hover text-center" onClick={deleteOption}></i>
                </div>
            </div>
        </div>

    );
}

export default Options;