import { useState, useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';

// const axios = require('axios').default;
function TextEditorAnswer(props: any) {

    const html = props.answerData.question;

    const contentBlock = htmlToDraft(html);

    const _contentState = convertToRaw(ContentState.createFromBlockArray(contentBlock.contentBlocks));
    const [contentState, setContentState] = useState(_contentState)

    const [, setEditorState] = useState(EditorState.createEmpty())

    useEffect(() => {
        setEditorState(props.answerData.question);
    }, [])

    const onEditorStateChange = (editorState: any) => {

        props.onAnswerInputHandler(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );


    };


    return (
        <div>
            <div className="border-textEditor">
                <Editor
                    defaultContentState={contentState}
                    onContentStateChange={setContentState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={onEditorStateChange}

                    toolbar={{
                        options: ['inline', 'fontSize', 'fontFamily', 'textAlign', 'history']
                    }}
                />
               
            </div>
        </div>
    );
}

export default TextEditorAnswer; 