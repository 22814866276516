import { useState, useEffect } from 'react';
import { convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';

function TextEditor(props: any) {

  const html = props.question.question;

  const contentBlock = htmlToDraft(html);

  const _contentState = convertToRaw(ContentState.createFromBlockArray(contentBlock.contentBlocks));
  const [contentState, setContentState] = useState(_contentState)


  useEffect(() => {


  }, [])

  const onEditorStateChange = (editorState: any) => {



    props.onQuestionInputHandler(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );


  };


  return (
    <div>
      <div className="border-textEditor">
        <Editor
          defaultContentState={contentState}
          onContentStateChange={setContentState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={onEditorStateChange}

          toolbar={{
            options: ['inline', 'fontSize', 'fontFamily', 'textAlign', 'history'],
            fontFamily: {
              options: ['Arial', 'AlternateGothic', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            }
          }}
        />
        {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(EditorState.getCurrentContent()))}
        ></textarea> */}
      </div>
    </div>
  );
}

export default TextEditor; 