import _ from "lodash";

const emptyRound = <span className="empty-number">—</span>; // round marker

function Score(props:any) {
  // console.log(props);
    return (
    <>{prepareTr(props.round)}</>
  );
}

function prepareTr(round:any){
    let trList:any=[];
    round.map((data:any, index:any)=>{
        trList.push(<tr key={index} className="leaderBoard-list m-1">{prepareTd(data, ++index)}</tr>);
        return null
    })

    return trList;
}

function prepareTd(data:any, index:any){
    
    // const no_of_rounds = 10;//data.scores.size
    // const mid = (no_of_rounds - 2) / 2;

    const no_of_rounds = data.scores.length > 8  ? 10 : data.scores.length+1
    const mid = no_of_rounds > 8 ? ((no_of_rounds - 2) / 2) : 4;

    let i = 0;
    let tdList:any = [];
    tdList.push(<td className="rank" key={++i}>{index}</td>);
    tdList.push(<td className="leader-team-name text-start" key={++i}><div className="d-block px-2" style={{overflow:'hidden'}}>{data.teamName}</div></td>);

    // data.scores.map((s:any)=>{
    //     tdList.push(<td className="col pp-bg-color points" key={++i}>{s.pp}</td>);
    //     tdList = [...tdList, prepareRounds( 1, mid, i, data.scores)];
    //     tdList.push(<td className="col list-bg-color points" key={++i}>0</td>);
    //     tdList = [...tdList, prepareRounds(mid + 1, no_of_rounds - 2, i, data.scores)];
    // })

    //tdList.push(<td className="col pp-bg-color points" key={++i}>{data.scores[0].pp}</td>);

    tdList[2]=<td className="col pp-bg-color points" key={++i}>{data.scores[0].pp > 0 ? data.scores[0].pp : emptyRound}</td>;
    for(let m=1;m<data.scores.length;m++){
      //if(m!=3){
      if(data.scores[m].pp===0){
      // break;
      } else {
        tdList[2]=<td className="col pp-bg-color points" key={++i}>{data.scores[m].pp}</td>;
      }
    //}
    }
    //tdList[2]=<td className="col pp-bg-color points" key={++i}>{data.scores[0].pp}</td>;
    tdList = [...tdList, prepareRounds( 1, mid, i, data.scores)];
    tdList.push(<td className="col list-bg-color points" key={++i}>{data.scores[4].total > 0 ? data.scores[4].total : emptyRound}</td>);
    tdList = [...tdList, prepareRounds(mid + 2, no_of_rounds - 2+1, i, data.scores)];
  
    tdList.push(<td className="total-active total-score fw-27" key={++i}>{data.total > 0 ? data.total : emptyRound}</td>);
    
    return tdList;
}

function prepareRounds(start:any, end:any, key:any, scores:any) {
  // console.log(scores);
  
  let list = [];
  for (let index = start; index <= end; index++) {
    let classes="col question"
    const roundScore=_.find(scores, {roundId: index});
    let s=0;
    if(roundScore){
        s=roundScore.correct_answers;
        if(roundScore.jj){
            s*=2;
            classes="jj_active"
        }
        // console.log(roundScore)
    }
    list.push(<td className={classes} key={index * 10 + key}>{s > 0 ? s : emptyRound}</td>);
  }
  return list;
}
export default Score;