import { useState, useEffect,useRef } from 'react';
import {  ValidationResponse } from "../interfaces/error";
import { useSelector } from 'react-redux';
import { Validate } from "../utils/validation";
// import { useAuth0 } from "@auth0/auth0-react";
import Ikeyword from '../interfaces/IKeyword';
function TagsInput(props:any) {
    const keywordRedux: Array<Ikeyword> = useSelector((state: any) => state.keywords);
    const [tags, setTags] = useState<Array<string>>([]);
    const [keywords, setKeywords] = useState<Array<string>>(["hii", "hello", "rishabh", "aman", "geography", "science", "maths", "isthmus", "year"]);
    const [suggestions, setSuggestions] = useState<Array<string>>([]);
    const [inputBoxKeywordValue, setInputBoxKeywordValue] = useState<string>();
    // To show error
    const [keywordErr, setKeywordErr] = useState<ValidationResponse>({
        key: 'keyword',
        errors: []
    });
    const keywordInputRef = useRef<HTMLInputElement>(null);
    const handlekeyDown = (e: any) => {
        if (e.key !== 'Enter') {
            return
        }
        let keywordErrorResp: ValidationResponse = Validate('keyword', e.target.value);
        if (keywordErrorResp.errors.length) {
            setKeywordErr(keywordErrorResp);
            return;
        }
        const value = e.target.value;

        if (!value.trim()) return
        let newTags=[...tags, value];
        setTags([...tags, value])
        props.onChangeKeywords(newTags);
        e.target.value = "";
        setInputBoxKeywordValue('');


    }
    const removeTag = (index: number) => {
        let newTags=tags.filter((el, i) => i !== index)
        setTags(tags.filter((el, i) => i !== index));
        props.onChangeKeywords(newTags);
    }
    const onchangekeywordHandler = (text: any) => {
        let keywordErrorResp: ValidationResponse = Validate('keyword', text);
        if (keywordErrorResp.errors.length) {
            setKeywordErr(keywordErrorResp);
            return;
        }
        setKeywordErr({
            key: 'keyword',
            errors: []
        });
        let matches: Array<string> = [];
        if (text.length > 0) {
            matches = keywords.filter(word => {
                const regex = new RegExp(`${text}`, "gi");
                return word.match(regex);
            })
        }
        console.log(matches);
        setSuggestions(matches);
        setInputBoxKeywordValue(text);
    }


    const onClickOfSuggestedKeyowrd = (suggestedKeyword: string) => {
        let newTags = [...tags];
        newTags.push(suggestedKeyword);
        setTags(newTags);
        props.onChangeKeywords(newTags);
        // keywordInputRef.current;
      setInputBoxKeywordValue('');
      setSuggestions([]);
    }
 
    useEffect(()=>{
        
        let keywordArr:Array<string>=[];
        for(let i=0;i<keywordRedux.length;i++){
            keywordArr.push(keywordRedux[i].keywordName);
        }
        setKeywords(keywordArr);

    },[])
    
    return (
        // Parent container
        <div>
            <div className="tags-input-container w-50" ref={keywordInputRef}>
                {
                    tags.map((tags, index) => (
                        <div className="tag-item" key={index}>
                            <span className="text">
                                {tags}
                            </span>
                            {/* <span className="close" onClick={() => removeTag(index)}>
                                &times;
                            </span> */}
                            <i className="fas fa-times-circle close" onClick={() => removeTag(index)}></i>
                        </div>

                    ))
                }
                <input onKeyDown={(e) => handlekeyDown(e)} onChange={(e) => onchangekeywordHandler(e.target.value)} type="text" className='tags-input' placeholder='Type Something' value={inputBoxKeywordValue} />
                 
                {/* Error to be shown */}
                {keywordErr.errors.length ? <span className="text-danger">{keywordErr.errors[0].message}</span> : <></>}
                
            </div>
            {suggestions && suggestions.map((suggestion, i) =>
                <div key={i} id="input-box-keyword" className="suggestions" onClick={() => onClickOfSuggestedKeyowrd(suggestion)}>{suggestion}</div>
            )
            }
        </div>
    );
}

export default TagsInput;