let initState =[{
    isVisible:true,
    teamName:''
}]
const ppVisibilityReducer =(state:any=initState,action:any)=>{
    if(action.type==='PP_BTN_VISIBILITY'){
        return state = action.payload;
    }
    return state;
    }
    export default ppVisibilityReducer;