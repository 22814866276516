import IGamesData from "../../interfaces/IGamesData";
let gamesData: IGamesData[] = [];
const initialState ={
    showModal: false,
    showModalOfDataSaved: false,
    messageOFDataSaveModal: "Data has successfully saved",
    gamesData
  }

const scoreEditorReducer =(state=initialState,action:any)=>{
if(action.type==='SCORE_EDITOR_STATE'){
   return state = action.payload;
}
return state;
}
export default scoreEditorReducer;