import Ikeyword from "../../interfaces/IKeyword"
import IQuestionTypesResponse from "../../interfaces/IQuestionTypesResponse"

export const openModal:any = (modalState: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'OPEN_MODAL',
            payload: modalState
        })
    }
}

export const ppCheck:any = (ispp: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'PP_CHECK',
            payload: ispp
        })
    }
}
export const ppVisiblity:any = (ispp: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'PP_BTN_VISIBILITY',
            payload: ispp
        })
    }
}

export const isActiveComponent:any = (component: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'ACTIVE_COMPONENT',
            payload: component
        })
    }
}

export const scoreEditorState:any = (component: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'SCORE_EDITOR_STATE',
            payload: component
        })
    }
}
export const createContestState:any = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'CREATE_CONTEST_STATE',
            payload: data
        })
    }
}

export const gameInfo:any = (info: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'GAME_INFO',
            payload: info
        })
    }
}
export const showOrHideSideNavBar:any = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'IS_VISIBLE_SIDENAV_BAR',
            payload: data
        })
    }
}

export const mainClass:any = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'MAIN_CLASS',
            payload: data
        })
    }
}
export const userDetail:any = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'USER_DETAIL',
            payload: data
        })
    }
}
export const configForHeader:any = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'HEADER_CONFIG',
            payload: data
        })
    }
}


export const questionSearchState:any = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: 'QUESTION_SEARCH_STATE',
            payload: data
        })
    }
}

export const keywords:any = (data: Array<Ikeyword>) => {
    
    return (dispatch: any) => {
        dispatch({
            type: 'KEYWORDS',
            payload: data
        })
    }
}


export const questionTypes:any = (data: Array<IQuestionTypesResponse>) => {
    return (dispatch: any) => {
        dispatch({
            type: 'QUESTION_TYPES',
            payload: data
        })
    }
}

/**
 * Function to dispatch the teams to store into redux
 * @param { Array<any>} data   
 * @returns {any}
 */
export const populateOldTeams:any = (data: Array<any>) => {
    return (dispatch: any) => {
        dispatch({
            type: 'OLD_TEAMS',
            payload: data
        })
    }
}