// import Layout from "./Layout";
import BionLogo from '../content/images/bion-logo.png';
import BionLogoVert from '../content/images/bion-vertical-logo.png';
//  import '../content/css/leaderboard-style.css';
import '../content/css/leaderBoard-res.css';
import { useEffect,useState, useRef } from "react";
//import { GetLeaderBoardData } from "../Dummy-data/DummyGamesDataService";
import { GetLeaderBoardData } from "../Dummy-data/GameDataService";
import Score from "./Score";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import ILeaderBoardScore from "../interfaces/ILeaderBoardScore";
import { SCORE_APIS_URL } from "../config/config";
import { useAuth0 } from "@auth0/auth0-react";
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import LoadingSpinner from "./common/LoadingSpinner"
import Confetti from 'react-confetti'
// import { Textfit } from 'react-textfit';
// import useFitText from "use-fit-text";
import ScaleText from "react-scale-text";

const axios = require('axios').default;


// import IGamesData from "../interfaces/IGamesData";
// import Iinfo from "../interfaces/Iinfo";




function LeaderBoard() {
    const location: any = useLocation();
    const {getAccessTokenSilently} = useAuth0();
    const [showModalOfScoreboardFailed,setshowModalOfScoreboardFailed]=useState(false);
    
    const config: any = useSelector((state: any) => state.headerConfigreducer);
    let gamesData: ILeaderBoardScore[] = [];
    const [value, setValue] = useState(gamesData);
    const [gettingData,setgettingData] = useState(true);
    const [, setGameData] = useState(null);
    const [rounds, setRounds] = useState(0);
    const [gameEnded, setGameEnded] = useState(false);
    const [windowWidth, setWindowWidth] = useState(null) as any;
    const scoreCardRef = useRef() as any;
    const [trophies, setTrophies] = useState("") as any;
    const [allscores, setAllscores] = useState("")

    const navigate = useNavigate();
    const handleBack = () => {
        navigate("/layout");
    }

    const [ searchParams ] = useSearchParams();

    const show_jj = searchParams.get('jj')

    /**
  * function to refresh scoreboard page
 * @returns {void}
  */
    
    const onhandleRefreshBtn = () => {

        apiCallingForUseEffect();
        
        // if (location.state) {
        //     axios.get(`${SCORE_APIS_URL}/${location.state.locationId}/${location.state.gameId}`,config)
        //         .then(function (response: any) {
        //             console.log(`Response Received onhandleRefreshBtn${response}`,response);
        //             let dataOfGames = response.data.data;
        //             setGameEnded(response.data.data[0].isGameEnded);
        //             let dataToDisplay = GetLeaderBoardData(dataOfGames);
        //             setValue(dataToDisplay);
        //         })
        //         .catch(function (error: any) {
        //             console.log(`Response Failure ${error}`);
        //         });
        // }
    }

    /**
  * function to show trophies in scoreboard ui
 * @returns {void}
  */
    const showTrophies = () => {    
        setTimeout(()=>{
        setTrophies("show-trophy-"); 
        scoreCardRef.current.classList.add("show-board");
    }, 100)

        return (
            <div className="row trophies">
                <div className="col-xl-10 offset-xl-1">
                    <div className="row h-100 justify-content-space-between">
                        <div className={"top-scorer-team col-lg-4 col-md-4 col-4 "+trophies+"2"}>
                            <div className="position h-100 position-relative">
                                <div className="h-100 top-scorer-data">
                                    <div className="top-teamNames second-position">
                                        <div>{value[1].teamName}<span className="team-mobile-score">{value[1].total}</span></div>
                                    </div>
                                    <div className="team-trophy second-position">
                                        <div className="top-scorer second-position">{value[1].total}</div>
                                    </div>
                                    <div className="second trophy-label">2</div>
                                </div>
                            </div>
                        </div>
                        <div className={"top-scorer-team col-lg-4 col-md-4 col-12 "+trophies+"1"}>
                            <div className="position h-100 position-relative">
                                <div className="h-100 top-scorer-data">
                                    <div className="top-teamNames first-position">
                                        <div className="teamText">
                                            <ScaleText>
                                                {value[0].teamName}
                                            </ScaleText>
                                        </div>
                                        <span className="team-mobile-score">{value[0].total}</span>
                                    </div>
                                    <div className="team-trophy first-position">
                                        <div className="top-scorer first-position">{value[0].total}</div>
                                    </div>
                                    <div className="first trophy-label">1</div>
                                </div>
                            </div>
                        </div>
                        <div className={"top-scorer-team col-lg-4 col-md-4 col-4 "+trophies+"3"}>
                            <div className="position h-100 position-relative">
                                <div className="h-100 top-scorer-data">
                                    <div className="top-teamNames third-position">
                                        <div>{value[2].teamName}<span className="team-mobile-score">{value[2].total}</span></div>
                                    </div>
                                    <div className="team-trophy third-position">
                                        <div className="top-scorer third-position">{value[2].total}</div>
                                    </div>
                                    <div className="third trophy-label">3</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**
  * function set headers
 * @returns {void}
  */
    const getHeader = async ()=>{
        const token = await getAccessTokenSilently();
        let config = {
          headers: {
            Authorization: "Bearer "+token,
            "content-type": "application/json",
          }
        }
        return config;
    }

    /**
  * function to navligate on dashboard page
 * @returns {void}
  */
    const navigateToDashboard=()=>{
        navigate('/dashboard');
    }

    const apiCallingForUseEffect=async()=>{
        let config = await getHeader();
        if (location.state) {
            setgettingData(true);

            await axios.get(`${SCORE_APIS_URL}/${location.state.locationId}/${location.state.gameId}`,config)
                .then(function (response: any) {
                    console.log(`Response Received apiCallingForUseEffect`,response.data.data[0]);
                    // console.log(JSON.stringify(response.data.data[0]));
                    let dataOfGames = response.data.data;
                    setGameEnded(response.data.data[0].isGameEnded);
                    let dataToDisplay = GetLeaderBoardData(dataOfGames);
                    if(dataToDisplay.length===0){
                        setshowModalOfScoreboardFailed(true);
                        return;
                    }
                    setGameData(response.data.data[0].is_active);
                    setRounds(response.data.data[0].no_of_rounds+1);
                    setValue(dataToDisplay);
                })
                .catch(function (error: any) {
                    console.log(`Response Failure ${error}`);
                    setshowModalOfScoreboardFailed(true);
                }).finally(function ()
                {
                    console.log("finally");
                    setgettingData(false);
                });
        }
        const interval = setInterval(async() => {
            console.log('Logs every minute');
            let config = await getHeader();
            if (location.state) {
               await axios.get(`${SCORE_APIS_URL}/${location.state.locationId}/${location.state.gameId}`,config)
                    .then(function (response: any) {
                        console.log(`Response Received in every second${response}`, response);
                        console.log("response.data.isGameEameEndedresponse.data.isGameEnded",response.data.data[0].isGameEnded);
                        setGameEnded(response.data.data[0].isGameEnded);
                        let dataOfGames = response.data.data;
                        let dataToDisplay = GetLeaderBoardData(dataOfGames);
                        setValue(dataToDisplay);
                    })
                    .catch(function (error: any) {
                        console.log(`Response Failure ${error}`);
                    });
            }
        }, 15000);

        return () => clearInterval(interval);

 
    }

    useEffect(() => {

        apiCallingForUseEffect();
        setWindowWidth(window.innerWidth)            
    }, []) as any

    const displayData = () => {

        if (value.length > 0) {
            return (
                <div className={"scoreboard-body " + allscores}>
                    {/* <!-- Top Scorer --> */}
                    { gameEnded === true ?
                        <div className="w-100">
                            <Confetti
                                width={windowWidth}
                                height={windowWidth*.5625}
                            />
                            {showTrophies()}
                        </div>
                        : <></>
                    }

                    {/* <!-- LeaderBoard --> */}
                    <div className="table-responsive w-100" style={{fontSize: '14px'}}>
                        <table id="leaderBoard" className={gameEnded === true ? "scores-area" : "scores-area show-fast"} ref={scoreCardRef}>
                            <thead>
                                <tr id="leaderBoard-head">
                                    <th>&nbsp;</th>
                                    <th className="p-2 leader-team-name text-start px-2">Teams</th>
                                    <th className="col roundWise-score">PP</th>
                                    <th className="col roundWise-score">R1</th>
                                    <th className="col roundWise-score">R2</th>
                                    <th className="col roundWise-score">R3</th>
                                    <th className="col roundWise-score">R4</th>
                                    <th className="col text-center roundWise-score">List</th>
                                    {rounds > 5 ? <th className="col roundWise-score">R5</th> : ""}
                                    {rounds > 6 ? <th className="col roundWise-score">R6</th> : ""}
                                    {rounds > 7 ? <th className="col roundWise-score">R7</th> : ""}
                                    {rounds > 8 ? <th className="col roundWise-score">R8</th> : ""}
                                    <th className="text-center header-radius-right">Total</th>
                                </tr>
                            </thead>
                            <tbody id="leaderBoard-body" className={show_jj === "false" ? "jj_hide" : "" }>
                                <Score round={value} />
                            </tbody>
                        </table>
                    </div>

                    <div className="d-flex leaderBoard-footer">
                        <button type="button" className="btn bg-1 me-auto text-white refresh-btn" onClick={handleBack}><span><i className="fas fa-chevron-left me-2"></i></span> Back </button>
                        <img src={BionLogo} alt="logo" className="logos" onClick={()=>setAllscores("all-scores")}/>
                        <button type="button" className="btn btn-warning ms-auto refresh-btn" onClick={onhandleRefreshBtn}><span><i className="fas fa-sync-alt me-2"></i></span> Refresh </button>
                    </div>
                    <div className="allscore-buttons">
                        <button type="button" className="btn bg-1 text-white refresh-btn top-button-small" onClick={handleBack}><span><i className="fas fa-chevron-left"></i></span></button>
                        <img src={BionLogoVert} alt="logo" className="logos" onClick={()=>setAllscores("")}/>
                        <button type="button" className="btn btn-warning refresh-btn bottom-button-small" onClick={onhandleRefreshBtn}><span><i className="fas fa-sync-alt"></i></span></button>
                    </div>
                </div>
            )
        } else {
            return (<div>
                <Modal show={showModalOfScoreboardFailed} >
                    <Modal.Body className="text-center">
                    <i className="fas fa-window-close team-red-modal-icon"></i>
                        <p className="p-txt">Network Error</p>
                        <div>
                            <button className="btn bg-primary text-white text-left m-2" onClick={navigateToDashboard}>Go To Dashboard</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>)
        }
    }
    return (
        <>
            {gettingData ? <LoadingSpinner/> : displayData()}
        </>
    );
}

export default LeaderBoard;