import { Button, Col, Container, Form, Row } from "react-bootstrap";
import BionLogo from '../content/images/bion-logo.png';
import { Link } from 'react-router-dom';
import '../content/css/Login.css';
import { basicSchema } from '../schemas';
import { useFormik } from 'formik';

function Register() {
    const { values, errors, touched, handleBlur, handleChange } = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: ''
        },
        validationSchema: basicSchema,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));

        },
    });
    return (
        <>
            <Container>

                <Row className="m-auto">


                    <Col lg={5} md={6} sm={12} className="p-5 m-auto shadow-sm rounded-lg bg-white">
                        <div className="text-center">
                            <img src={BionLogo} className="Bion-logo" alt="logo" />
                            <h1 className="text-success mt-2 p-3 text-center rounded text-info"> Registration</h1>
                        </div>
                        <Form>
                            <Form.Group controlId="formBasicFirstName" className='label'>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    value={values.firstName}
                                    onChange={handleChange}
                                    id="firstName"
                                    type='text'
                                    placeholder='First Name'
                                    onBlur={handleBlur}
                                    className={errors.firstName && touched.firstName ? "input-error" : ""} />
                                {errors.firstName && touched.firstName && <p className='error text-danger'><i className="fas fa-exclamation-circle pe-2"></i>{errors.firstName}</p>}
                            </Form.Group>
                            <Form.Group controlId="formBasicLastName" className='label'>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    value={values.lastName}
                                    onChange={handleChange}
                                    id="lastName"
                                    type='text'
                                    placeholder='Last Name'
                                    onBlur={handleBlur}
                                    className={errors.lastName && touched.lastName ? "input-error" : ""} />
                                {errors.lastName && touched.lastName && <p className='error text-danger'><i className="fas fa-exclamation-circle pe-2"></i>{errors.lastName}</p>}
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail" className='label'>
                                <Form.Label>Email address</Form.Label>
                                {/* <Form.Control type="email" placeholder="Enter email" required /> */}
                                <Form.Control
                                    value={values.email}
                                    onChange={handleChange}
                                    id="email"
                                    type='email'
                                    placeholder='Enter your Email'
                                    onBlur={handleBlur}
                                    className={errors.email && touched.email ? "input-error" : ""} />
                                {errors.email && touched.email && <p className='error text-danger'><i className="fas fa-exclamation-circle pe-2"></i>{errors.email}</p>}
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword" className='label'>
                                <Form.Label>Password</Form.Label>
                                {/* <Form.Control type="password" placeholder="Password" required/> */}
                                <Form.Control
                                    value={values.password}
                                    onChange={handleChange}
                                    id="password"
                                    type='password'
                                    placeholder='Enter your password'
                                    onBlur={handleBlur}
                                    className={errors.password && touched.password ? "input-error" : ""} />
                                {errors.password && touched.password && <p className='error text-danger'><i className="fas fa-exclamation-circle pe-2"></i>{errors.password}</p>}
                            </Form.Group>
                            {/* <Form.Group controlId="formBasicEmail" className='label'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="First Name" />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword" className='label'>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="password" placeholder="Last Name" />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className='label'>
                            <Form.Label>Email address</Form.Label >
                            <Form.Control type="email" placeholder="something@gmail.com" />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword" className='label'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Alphanumeric Password" />
                        </Form.Group> */}


                            <Button className="mt-3 btn-block success col-md-12 text-center" type="submit">
                                Register
                            </Button>
                            <div className='Register-link'>    <Link to="/login"><u>Already Registered?</u></Link></div>
                        </Form>

                    </Col>
                </Row>
            </Container>
        </>

    );
}

export default Register;
