// import Iinfo from "../interfaces/Iinfo";

function ScoreCardDisplayButton(props: any) {
  let buttonList = [];
  for (let i = 0; i < 10; i++) {
    let title = `${(i + 1)}`;

    let classForbtn = "m-1 col-1 question-box";
    if (props.data.correct_answers) {
      if (props.data.correct_answers.includes(title)) {
        classForbtn = "m-1 scorecard-display-btn-success col-1 question-box1 rounded-5";
      }

    }

    buttonList.push(
      <div className={classForbtn}>{title}</div>

    );
  }
  let normalclass = "col-1 question-box1 m-1 rounded-5";
  let jjclass = "col-1 question-box1 m-1 scorecard-display-btn-primary rounded-5";
  let ppclass = "col-1 question-box1 m-1 scorecard-display-btn-pp rounded-5"
  if (props.id !== 4) {
    buttonList.push(
      <div className={props.data.is_pp_active ? ppclass : normalclass}>PP</div>
    );
    buttonList.push(
      <div className={props.data.is_jj_active ? jjclass : normalclass}>JJ</div>
    );
  } else {
    buttonList.push(
      <div className={props.data.is_pp_active ? ppclass : normalclass}></div>
    );
    buttonList.push(
      <div className={props.data.is_jj_active ? jjclass : normalclass}></div>
    );
  }
  console.log("In ScaoreCardDisplayButtons");
  return (

    <div className="row">{buttonList}</div>
  );

}

export default ScoreCardDisplayButton;