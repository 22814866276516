import { useState } from "react";
import "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../state/index";
import IGamesData from "../../interfaces/IGamesData";
import { useAuth0 } from "@auth0/auth0-react";
import ITeam from "../../interfaces/ITeam";
import {
  GAME_APIS_URL,
  SCORE_APIS_URL,
  VALIDATE_QUIZ_CODE_API_URL,
} from "../../config/config";
import { Validate } from "../../utils/validation";
import { ValidationResponse } from "../../interfaces/error";
import ValidateQuizModal from "../common/ValidateQuizModal";



const axios = require("axios").default;

let jjRounds: Array<Array<String>> = [];
let registerTeamRequest: Array<ITeam> = [];
var apiResult = {
  gameName: "",
  locationName: "",
  noOfRounds: 8,
  registerTeamRequest,
};

const initialValues = {
  count: 4,
  noOfRounds: 8,
  list: [""],
  gameId: "",
  location: "",
  jjRounds,
  showModal: false,
  apiResult,
};

function SideNavbar(props: any) {
  const location: any = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const [, setUpdateComp] = useState(true);

  interface IteamInfo {
    teamName: String;
    teamId: number;
  }

  const modal = useSelector((state: any) => state.modal);
  const activeComp = useSelector((state: any) => state.activeComp);

  const navigate = useNavigate();
  const [, setTeamInfo] = useState<Array<IteamInfo>>([]);
  const [showModal, setShowModal] = useState(false);
  const [navLocation, setNavLocation] = useState("/dashboard");
  const [, setRegisterOrUpdateModalMsg] = useState(
    "Teams successfully registered"
  );
  const [, setShowModalOfRegistartionFailed] =
    useState(false);

  let [, setData] = useState(initialValues);
  const [, setTargetloc] = useState<any>();
  const dispatch = useDispatch();
  // const isSideNavbarVisible = useSelector(
  //   (state: any) => state.sideNavbarVisibilty
  // );
  // const mainClass = "mainWithoutNav";;
  let gamesData: IGamesData[] = [];
  const [, setValue] = useState({
    showModal: false,
    showModalOfDataSaved: false,
    messageOFDataSaveModal: "Data has successfully saved",
    gamesData,
  });
  const [validateCodeModal, showValidateCodeModal] = useState(false);
  const [quizCode, setQuizCode] = useState<number>();
  const [quizCodeError, setQuizCodeError] = useState<ValidationResponse>({
    key: "quizCode",
    errors: [],
  });
  const [quizCodeLenError, setQuizCodeLenError] = useState("");
  const [validateOkBtnDisable, setValidateOkBtnDisable] = useState(false);

  const createdByEmail: any = localStorage.getItem("email");

  /**
   * function to set headers 
   * @returns {void}
   */
  const getHeader = async () => {
    const email: any = localStorage.getItem('email');
    const token = await getAccessTokenSilently();
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      }

    };
    return config;
  };


  /**
   * function to save scores 
   * @returns {void}
   */
  const saveScore = async () => {

    if (activeComp === "score-editor") {
      let locationId = location.state.locationId;
      let gameId = location.state.gameId;

      let scoreDataPacket = {
        info: props.finalValue.gamesData[0].info,
      };

      let newScoreDataPacket = {
        data: scoreDataPacket,
      };

      let config = await getHeader();
      axios
        .put(
          `${SCORE_APIS_URL}/${locationId}/${gameId}`,
          newScoreDataPacket,
          config
        )
        .then(function (response: any) {
          console.log(`Response Received${response}`);
          let newState = { ...props.finalValue };
          newState.showModalOfDataSaved = true;
          setValue(newState);
          dispatch(actionCreators.scoreEditorState(newState));

          dispatch(actionCreators.openModal(false));
          forceUpdateComp();
          setShowModal(false);
          if (navLocation === "/create-contest") {
            proceedWithQuizCode();
            return;
          }
          navigate(navLocation);
        })
        .catch(function (error: any) {
          let newState = { ...props.finalValue };
          newState.showModalOfDataSaved = true;
          newState.messageOFDataSaveModal = "Failed to Saved the Data";
          setValue(newState);
          dispatch(actionCreators.scoreEditorState(newState));
          console.log("failure");
        });

      const forceUpdateComp = () => {
        setUpdateComp((current) => !current);
      };
    } else {
      let createOrUpdateContext = props.createOrUpdateContext;
      let data = props.finalValue;
      if (createOrUpdateContext === "Edit Game") {

        let updateDataState = { ...data };


        updateDataState.apiResult.locationName = data.location;
        updateDataState.apiResult.gameName = data.gameId;
        let teamsArray: Array<ITeam> = data.apiResult.registerTeamRequest;
        setTeamInfo(props.teamInfo);
        let teamInfo = props.teamInfo;
        //updating existing teamName
        for (let index = 0; index < teamsArray.length; index++) {
          // teamsArray[index].teamName = teamInfo[index].teamName;
          let teamId = teamsArray[index].teamId;
          let isFound: any = teamInfo.findIndex((obj: any) => obj.teamId === teamId);
          if (isFound !== -1) {
            teamsArray[index].teamName = teamInfo[isFound].teamName;
          }
        }
        for (let index = 0; index < teamsArray.length; index++) {
          const element = teamsArray[index].teamName;
          if (!data.list.includes(element.toString())) {
            //set isActive false of that particular team
            teamsArray[index].isActive = false;
          } else {
            let i = data.list.indexOf(element.toString());
            teamsArray[index].jjRounds = data.jjRounds[i]
              ? data.jjRounds[i]
              : [];
          }
        }
        for (let index = 0; index < data.list.length; index++) {
          let teamEle = data.list[index];
          const found = teamsArray.some((el) => el.teamName === teamEle);
          if (!found) {
            teamsArray.push({
              teamId: -1,
              teamName: teamEle,
              jjRounds: data.jjRounds[index] ? data.jjRounds[index] : [],
              isActive: true,
            });
          }
        }
        updateDataState.apiResult.registerTeamRequest = teamsArray;
        let updatePostData = updateDataState.apiResult;
        // console.log(updatePostData, "UPDATE_POST_DATA");
        //post data
        const locationId = location.state.locationId?location.state.locationId:props.locationIdAndGameId.locationId;
        const gameId = location.state.gameId?location.state.gameId:props.locationIdAndGameId.gameId;
        let config = await getHeader();
        // let obj = null;
        await axios
          .put(
            `${GAME_APIS_URL}/${locationId}/${gameId}`,
            updatePostData,
            config
          )
          .then(async function (response: any) {
            // console.log(`Response Received${response}`);
            // setRegisterOrUpdateModalMsg(response.data.message);
            setRegisterOrUpdateModalMsg("Data updated successfully");
            if (response.data.message.registerTeamRequest !== undefined) {
              await prepareDataForCreateScore(response.data.message);
              //obj = response.data.message;
            }
            let newState = { ...data };
            newState.showModal = true;
            setData(newState);
            dispatch(actionCreators.createContestState(newState));
            dispatch(actionCreators.openModal(false));
            setShowModalOfRegistartionFailed(false);
          })
          .catch(function (error: any) {
            setRegisterOrUpdateModalMsg("Data Updation Failed");
            setShowModalOfRegistartionFailed(true);
            // console.log('failure');
          });
        // if(obj){
        //     prepareDataForCreateScore(obj);
        // }
        dispatch(actionCreators.openModal(false));
        setShowModal(false);
        if (navLocation === "/create-contest") {
          proceedWithQuizCode();
          return;
        }
        navigate(navLocation);
        window.location.reload();
      } else {
        //for create
        let updateDataState = { ...data };

        updateDataState.apiResult.locationName = data.location;
        updateDataState.apiResult.gameName = data.gameId;
        let teamsArray: Array<ITeam> = [];
        // if there is no existing teams
        if (props.teamInfo.length !== 0) {

          for (let i = 0; i < props.teamInfo.length; i++) {
            let item: ITeam = {
              teamId: props.teamInfo[i].teamId,
              teamName: props.teamInfo[i].teamName,
              isActive: false,
              jjRounds: []
            }
            teamsArray.push(item);
          }


          //updating existing teamName
          for (let index = 0; index < teamsArray.length; index++) {
            teamsArray[index].teamName = props.teamInfo[index].teamName
          }
          for (let index = 0; index < teamsArray.length; index++) {
            const element = teamsArray[index].teamName;
            if (!data.list.includes(element.toString())) {
              //set isActive false of that particular team
              teamsArray[index].isActive = false;
            } else {
              let i = data.list.indexOf(element.toString());
              teamsArray[index].jjRounds = data.jjRounds[i] ? data.jjRounds[i] : [];
            }

          }
        }
        for (let index = 0; index < data.list.length; index++) {
          let teamEle = data.list[index];
          const found = teamsArray.some(el => el.teamName === teamEle);
          if (!found) {
            teamsArray.push({
              teamId: -1,
              teamName: teamEle,
              jjRounds: data.jjRounds[index] ? data.jjRounds[index] : [],
              isActive: true
            });
          }
        }
        updateDataState.apiResult.registerTeamRequest = teamsArray;
        let updatePostData = updateDataState.apiResult;
        updatePostData.noOfRounds = data.noOfRounds;
        // console.log(updatePostData, "UPDATE_POST_DATA");

        let locationName = (data.location !== "") ? data.location : location.state.locationName;
        let gameName = (data.gameId !== "") ? data.gameId : location.state.gameName;
        const createdByEmail: any = localStorage.getItem('email');
        const createdBy: any = localStorage.getItem('items');
        let finalData = {
          locationName,
          gameName,
          noOfRounds: data.noOfRounds,
          quizId: data.quizId,
          createdBy: JSON.parse(createdBy),
          createdByEmail: JSON.parse(createdByEmail),
          registerTeamRequest: teamsArray
        }
        console.log(JSON.stringify(finalData));
        let config = await getHeader();
        axios
          .post(`${GAME_APIS_URL}`, finalData, config)
          .then(function (response: any) {
            // console.log(`Response Received${response}`);
            // setCreateOrUpdateLabel("Edit Game");
            // setcreateOrUpdateBtnText("Update Game");
            //show Modal of successfully saved
            let newState = { ...data };
            newState.showModal = true;
            setData(newState);
            dispatch(actionCreators.createContestState(newState));
            dispatch(actionCreators.openModal(false));
            setShowModalOfRegistartionFailed(false);
            //after getting response from api call the create score api
            insertEmptyScoreIntoDb(finalData, response.data.message);
          })
          .catch(function (error: any) {
            console.log("errr", error)
            //show Modal of Error
            // let newState ={...data};
            // newState.showModal = true;
            // setData(newState);
            if (error.response.status === 409) {
              //show toast return
              return;
            }
            setShowModalOfRegistartionFailed(true);
            console.log("failure");
          });
        dispatch(actionCreators.openModal(false));
        setShowModal(false);
        if (navLocation === "/create-contest") {
          proceedWithQuizCode();
          return;
        }
        navigate(navLocation);
      }
    }
  };

  /**
   * function to save empty score data into db  
   * @returns {void}
   */
  const insertEmptyScoreIntoDb = async (finalData: any, message: any) => {
    let infoArray = [];
    let idx = 0;
    for (idx = 0; idx < message.noOfRounds + 1; idx++) {
      for (let jdx = 0; jdx < message.registerTeamRequest.length; jdx++) {
        if (idx === 4) {
          let obj = {
            roundId: idx + 1,
            roundType: 1,
            teamId: message.registerTeamRequest[jdx].teamId,
            teamName: message.registerTeamRequest[jdx].teamName,
            correct_answers: [],
            is_pp_active: false,
            is_jj_active: false,
          };
          infoArray.push(obj);
        } else {
          //check given idx +1 is jj or not

          if (idx + 1 > 4) {
            let isJJROund =
              finalData.registerTeamRequest[jdx].jjRounds.includes(idx);
            let obj = {
              roundId: idx + 1,
              roundType: 0,
              teamId: message.registerTeamRequest[jdx].teamId,
              teamName: message.registerTeamRequest[jdx].teamName,
              correct_answers: [],
              is_pp_active: false,
              is_jj_active: isJJROund,
            };
            infoArray.push(obj);
          } else {
            let isJJROund = finalData.registerTeamRequest[
              jdx
            ].jjRounds.includes(idx + 1);
            let obj = {
              roundId: idx + 1,
              roundType: 0,
              teamId: message.registerTeamRequest[jdx].teamId,
              teamName: message.registerTeamRequest[jdx].teamName,
              correct_answers: [],
              is_pp_active: false,
              is_jj_active: isJJROund,
            };
            infoArray.push(obj);
          }
        }
      }
    }
    let createScoreReqData = {
      data: {
        info: infoArray,
      },
    };
    //post data

    const locationId = message.locationId;
    let config = await getHeader();
    const gameId = message.gameId;
    axios
      .post(
        `${SCORE_APIS_URL}/${locationId}/${gameId}`,
        createScoreReqData,
        config
      )
      .then(function (response: any) {
        console.log(`Response Received${response}`);
      })
      .catch(function (error: any) {
        console.log("failure");
      });
  };


  /**
   * function to prepare data for crating score 
   * @returns {void}
   */
  const prepareDataForCreateScore = async (message: any) => {
    let data = props.finalValue;
    let infoArray = [];
    let idx = 0;
    let newlyAddedTeam = message.registerTeamRequest;

    for (idx = 0; idx < data.apiResult.noOfRounds + 1; idx++) {
      for (let jdx = 0; jdx < newlyAddedTeam.length; jdx++) {
        if (idx === 4) {
          let obj = {
            roundId: idx + 1,
            roundType: 1,
            teamId: message.registerTeamRequest[jdx].teamId,
            teamName: message.registerTeamRequest[jdx].teamName,
            correct_answers: [],
            is_pp_active: false,
            is_jj_active: false,
          };
          infoArray.push(obj);
        } else {
          //check given idx +1 is jj or not

          if (idx + 1 > 4) {
            let isJJROund =
              message.registerTeamRequest[jdx].jjRounds.includes(idx);
            let obj = {
              roundId: idx + 1,
              roundType: 0,
              teamId: message.registerTeamRequest[jdx].teamId,
              teamName: message.registerTeamRequest[jdx].teamName,
              correct_answers: [],
              is_pp_active: false,
              is_jj_active: isJJROund,
            };
            infoArray.push(obj);
          } else {
            let isJJROund = message.registerTeamRequest[jdx].jjRounds.includes(
              idx + 1
            );
            let obj = {
              roundId: idx + 1,
              roundType: 0,
              teamId: message.registerTeamRequest[jdx].teamId,
              teamName: message.registerTeamRequest[jdx].teamName,
              correct_answers: [],
              is_pp_active: false,
              is_jj_active: isJJROund,
            };
            infoArray.push(obj);
          }
        }
      }
    }

    let createScoreReqData = {
      data: {
        info: infoArray,
      },
    };


    const locationId = message.locationId;
    let config = await getHeader();
    const gameId = message.gameId;
    await axios
      .post(
        `${SCORE_APIS_URL}/${locationId}/${gameId}`,
        createScoreReqData,
        config
      )
      .then(function (response: any) {
        console.log(`Response Received${response}`);
      })
      .catch(function (error: any) {
        console.log("failure");
      });
  };

  /**
   * function to check data is changed or not 
   * @returns {void}
   */
  const checkIsDataChange = (loc: any) => {
    setNavLocation(loc);
    if (!modal) {
      if (loc === "/create-contest") {
        proceedWithQuizCode();
      } else {
        navigate(loc);
      }
    } else {
      setTargetloc(loc);
      setShowModal(true);
      displayModal();
    }
  };

  /**
   * function to hide modal from ui 
   * @returns {void}
   */
  const cancelModal = () => {

    setShowModal(false);
    if (navLocation === "/create-contest") {
      proceedWithQuizCode();
      return;
    }
    navigate(navLocation);

    dispatch(actionCreators.openModal(false));
  };

  /**
   * function to show modal in ui 
   * @returns {void}
   */
  const displayModal = () => {
    let modall = (
      <Modal show={showModal}>
        <Modal.Body className="text-center">
          <i className="fas fa-question-circle quit-modal"></i>
          <p className="p-txt">Do you want to save your changes? </p>
          <div>
            <button
              className="btn btn-success text-left m-2"
              onClick={() => saveScore()}
            >
              Yes
            </button>
            <button className="btn btn-secondary" onClick={cancelModal}>
              NO
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
    return modall;
  };

  /**
   * function to show modal for quiz code validation 
   * @returns {void}
   */
  const proceedWithQuizCode = () => {
    showValidateCodeModal(true);
  };

  /**
   * function to check validity od quiz code in database 
   * @returns {void}
   */
  const validateQuizCode = async () => {
    const token = await getAccessTokenSilently();


    let configForQues = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      },
    };
    axios
      .get(`${VALIDATE_QUIZ_CODE_API_URL}/${quizCode}`, configForQues)
      .then(function (response: any) {
        console.log("quiz code", JSON.stringify(response));
        var quizData = response.data.message;
        if (response.data.description === "Invalid quiz code") {
          setQuizCodeLenError(response.data.description);
          return;
        }
        if (response.data.description === "QuizCode Validated") {
          setQuizCodeLenError("");
          showValidateCodeModal(false);
          if (
            activeComp === "create-contest" &&
            navLocation === "/create-contest"
          ) {
            navigate("/create-contest", { state: { quizData } });
            window.location.reload();
            return;
          }
          navigate("/create-contest", { state: { quizData } });
        }
      })
      .catch(function (error: any) {
        dispatch(actionCreators.populateOldTeams([]));
        console.log(error);
      });
  };


  /**
   * function to hide modal of validate code   
   * @returns {void}
   */
  const cancelValidateCodeModal = (quizId: any) => {
    showValidateCodeModal(false);
  };

  /**
   * function to get value of quiz code field 
   * @returns {void}
   */
  const getValueFromquizCode = (e: any) => {
    setQuizCodeError({
      key: "quizCode",
      errors: [],
    });

    setQuizCodeLenError("");

    let quizErrorResp: ValidationResponse = Validate(
      "quizCode",
      e.target.value
    );

    console.log(quizErrorResp.errors.length);

    if (quizErrorResp.errors.length) {
      setQuizCodeError(quizErrorResp);
      setValidateOkBtnDisable(false);
      return;
    }

    setValidateOkBtnDisable((c) => !c);
    setQuizCode(e.target.value);
    setQuizCodeLenError("");
  };


  /**
   * function to display quiz code modal 
   * @returns {void}
   */
  const displayquizCodeModal = () => {
    let modall = (
      <ValidateQuizModal isShowModal={validateCodeModal} onHandleOkBtn={validateQuizCode} onCancelBtn={cancelValidateCodeModal}
        onInputBtn={getValueFromquizCode} quizCodeError={quizCodeError} quizCodeLenError={quizCodeLenError} validateOkBtnDisable={validateOkBtnDisable} />
    );
    return modall;
  };


  /**
   * function to display and hide nav items 
   * @returns {void}
   */
  const showHiddenNavItem = () => {
    if (
      JSON.parse(createdByEmail) === "rishabhs@yamaha-motor-india.com" ||
      JSON.parse(createdByEmail) === "andrej@believeitornot.co.nz" ||
      JSON.parse(createdByEmail) === "brendan@believeitornot.co.nz" ||
      JSON.parse(createdByEmail) === "adua@yamaha-motor-india.com" ||
      JSON.parse(createdByEmail) === "RICHAS@YAMAHA-MOTOR-INDIA.COM" ||
      JSON.parse(createdByEmail) === "admin@believeitornot.co.nz" ||
      JSON.parse(createdByEmail) === "matt@believeitornot.co.nz" ||
      JSON.parse(createdByEmail) === "jonathan@believeitornot.co.nz" ||
      JSON.parse(createdByEmail) === "jake@believeitornot.co.nz" ||
      JSON.parse(createdByEmail) === "imogen.staines@believeitornot.co.nz"
    ) {
      return (
        <li className="nav-item">
          <a
            className="nav-link collapsed fw-normal"
            data-bs-target="#tables-nav-ques"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="fas fa-question-circle"></i>
            <span>Question Database</span>
            {/* <i className="fas fa-chevron-down ms-auto"></i> */}
            <span className="down-icon"></span>
          </a>
          <ul
            id="tables-nav-ques"
            className="nav-content collapse"
            data-bs-parent="#sidebar-navbar"
          >
            <li className="nav-item">
              <a
                className="nav-link collapsed fw-normal"
                data-bs-target="#tables-nav1"
                data-bs-toggle="collapse"
                href="#"
              >
                <i className="fas fa-plus-circle fs-6"></i>
                <span>Create Questions</span>
                {/* <i
                  className="fas fa-chevron-down ms-auto"
                  style={{ fontSize: "15px", marginRight: "25px" }}
                ></i> */}
                <span className="down-icon"></span>
              </a>
              <ul id="tables-nav1" className="nav-content collapse">
                <li>
                  <Link to="/create-new-question" className="fw-normal mx-5">
                    Standard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/create-new-the-list-question"
                    className="fw-normal mx-5"
                  >
                    The List
                  </Link>
                </li>
                <li>
                  <Link to="/create-new-pp-question" className="fw-normal mx-5">
                    Ponderous Puzzles
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/search-question" className="fw-normal">
                <i className="fas fa-search fs-6"></i>
                <span>Search</span>
              </Link>
            </li>
          </ul>
          <ul
            id="tables-nav-ques"
            className="nav-content collapse"
            data-bs-parent="#sidebar-navbar"
          >
            <li className="nav-item">
              <a
                className="nav-link collapsed fw-normal"
                data-bs-target="#tables-nav2"
                data-bs-toggle="collapse"
                href="#"
              >
                <i className="fas fa-question-circle fs-6"></i>
                <span>Quiz</span>
                <i
                  className="fas down-icon ms-auto"
                  style={{ fontSize: "15px", marginRight: "25px" }}
                ></i>
              </a>
              <ul id="tables-nav2" className="nav-content collapse">
                <li>
                  <Link to="/quiz" className="fw-normal mx-5">
                    Manage Quizzes
                  </Link>
                </li>

                <li>
                  <Link to="/file-upload" className="fw-normal mx-5">
                    Import quiz
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      );
    } else {
      <></>;
    }
  };

  const toggleSideNavbar = () => {
    const mainDiv = document.getElementById("main");
    const sideBar = document.getElementById("navMain");
    if (mainDiv?.classList.contains("main")) {
      mainDiv?.classList.remove("main");
      sideBar?.classList.add("inactive");
      mainDiv?.classList.add("mainWithoutSideNavBar");
    } else {
      mainDiv?.classList.add("main");
      sideBar?.classList.remove("inactive");
      mainDiv?.classList.remove("mainWithoutSideNavBar");
    }
  }

  return (
    <div>
      {displayModal()}
      {displayquizCodeModal()}
      <aside id="sidebar" className="sidebar ">
        <span className="sidebar-close-icon" onClick={() => toggleSideNavbar()}>&times;</span>

        <ul className="sidebar-nav" id="sidebar-navbar">
          <li className="nav-item">
            <a
              onClick={() => { toggleSideNavbar(); checkIsDataChange("/dashboard") }}
              className="nav-link fw-normal"
            >
              <span className="dashboard-icon"></span>
              <span>Dashboard</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed fw-normal games-link"
              data-bs-target="#tables-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <span className="games-icon"></span>
              <span>Games</span>
              <span className="down-icon"></span>
            </a>
            <ul
              id="tables-nav"
              className="nav-content collapse"
              data-bs-parent="#sidebar-navbar"
            >
              <li>
                <a
                  onClick={() => checkIsDataChange("/create-contest")}
                  className="fw-normal"
                >
                  <span>New Game </span>
                </a>
              </li>
            </ul>
          </li>
          {showHiddenNavItem()}
        </ul>
      </aside>
    </div>
  );
}

export default SideNavbar;
