import { QUES_APIS_URL } from "../config/config";
import { actionCreators } from "../state/index";

const axios = require("axios").default;

// Function to store keyword data in redux
export default async function SaveKeywords(token: any) {

    let config = {
        headers: {
            Authorization: "Bearer " + token,
            "content-type": "application/json",
        },
    };


    return axios.get(`${QUES_APIS_URL}`, config)
        .then(function (response: any) {
            // console.log(JSON.stringify(response.data.message));
            return actionCreators.keywords(response.data.message);
        })
        .catch(function (error: any) {
            console.log(error);
        });


}