import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import { useSelector } from "react-redux";
import Layout from "./Layout";
import LoadingSpinner from "./common/LoadingSpinner";
import { ValidationResponse } from "../interfaces/error";
import { Validate } from "../utils/validation";
import { IMPORT_QUIZ_URL } from "../config/config";
import SaveKeywords from "../utils/SaveKeywords";
import { useDispatch } from "react-redux";

//axios for api calling
const axios = require("axios").default;
function FileUpload() {

  const dispatch = useDispatch();


  const { getAccessTokenSilently } = useAuth0();
  const [, setimgUpload] = useState<FileReader>();
  // const mainClass = "mainWithoutNav";;
  //State variable for file object
  const [file, setFile] = useState<any>("");
  //State variable for File name
  const [fileName, setFileName] = useState("");
  //State variable to handle loader
  const [showLoader, setShowLoader] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [response, setResponse] = useState<Array<string>>([]);
  const [btnDisable, setDisable] = useState(true);
  //State variable to handle validation errors
  const [fileTypeError, SetFileTypeError] = useState<ValidationResponse>({
    key: "fileUploadType",
    errors: [],
  });

  const openInNewTab = (url: any) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  /**
   * Function to upload excel file on upload button click
   */
  const uploadFile = async (e: any) => {
    setShowResult(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    try {
      let config = await getHeader();

      const token = await getAccessTokenSilently();

      //api calling to post data
      setShowLoader(true);
      const res = await axios.post(IMPORT_QUIZ_URL, formData, config);

      const keywordsData = await SaveKeywords(token);
      // Function to store keyword data in redux
      dispatch(keywordsData);

      setShowLoader(false);

      console.log(res.data.message);
      setResponse(res.data.message);
    } catch (ex) {
      setShowLoader(false);
      console.log(ex);
    }
  };

  /**
   * Function execute on choosing of excel file to be uploaded
   * @param {any} e
   */
  function getBase64(e: any) {
    var file = e.target.files[0];
    let extension = file.name.split(".")[1];

    //validation for maximum size of uploaded file
    let validationResponseForFileSize: ValidationResponse = Validate(
      "fileSize",
      file.size
    );
    if (validationResponseForFileSize.errors.length) {
      SetFileTypeError(validationResponseForFileSize);
      return;
    }

    //validation for uploaded  extension
    let validationResponse: ValidationResponse = Validate(
      "fileUploadType",
      extension
    );
    if (validationResponse.errors.length) {
      SetFileTypeError(validationResponse);
      return;
    }
    SetFileTypeError({
      key: "fileUploadType",
      errors: [],
    });
    setFile(file);
    setFileName(e.target.files[0].name);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setimgUpload(reader);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    //disable the upload button
    setDisable(false);
  }

  /**
   * Function to get header for api calling
   */
  const getHeader = async () => {
    const user: any = localStorage.getItem("items"); //user name from local storage
    const token = await getAccessTokenSilently();
    console.log(token);
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      },
      params: {
        createdBy: JSON.parse(user?.toString()),
      },
    };
    return config;
  };

  /**
   * Function to display result after uploading excel
   */
  const displayResult = () => {
    return (
      <ul className="list-group">
        {response.map((ele: string, idx: number) =>
          ele.includes("successfully") ? (
            <li
              className="list-group-item list-group-item-success"
              style={{ fontSize: "16px" }}
            >
              {ele}
            </li>
          ) : !ele.includes("Quiz Already Exists") ? (
            <li
              className="list-group-item list-group-item-danger"
              style={{ fontSize: "16px" }}
            >
              {ele}
            </li>
          ) : (
            <li
              className="list-group-item list-group-item-warning"
              style={{ fontSize: "16px" }}
            >
              {ele}
            </li>
          )
        )}
      </ul>
    );
  };
  return (
    <div id="main"  className="withoutSideBarNav">
      <div className="pagetitle">
        <h1>Import Quiz</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Home</a>
            </li>
            <li className="breadcrumb-item active">Import Quiz</li>
          </ol>
        </nav>
      </div>

      <Layout />
      <div className="card" style={{ width: "100%" }}>
        <div className="card-body">
          <div className="col-12">
            <div className="row justify-content-center">
              <div className="row">
                <label
                  htmlFor="KeywordName"
                  className="col-sm-12 col-lg-1 col-form-label text-left"
                >
                  Upload File :
                </label>
                <div className="col-sm-12 col-lg-6">
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control col-6"
                      name="file"
                      accept=".xlsx"
                      onChange={(e) => getBase64(e)}
                    />
                  </div>
                </div>
                <div className="text-start col-lg-2">
                  <button
                    className="btn btn-success"
                    disabled={btnDisable}
                    onClick={uploadFile}
                  >
                    Upload File
                  </button>
                </div>
                <div className="text-center col-lg-2 col-form-label text-left"></div>

                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-6 p-1">
                    {fileTypeError.errors.length ? (
                      <span className="text-danger">
                        {fileTypeError.errors[0].message}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div className="row m-3">
                <div className="col-lg-6 uploadFile">
                  <i className="fas fa-star-of-life"></i>&nbsp; To view sample upload file&nbsp;
                  <a
                    className="text-decoration-underline"
                    onClick={() =>
                      openInNewTab(
                        process.env.PUBLIC_URL + "/Files/SampleFile.xlsx"
                      )
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Click here
                  </a>
                </div>
                <div className="col-lg-6 p-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showResult && (
        <div className="card" style={{ width: "100%" }}>
          <div className="card-body">
            <div className="col-12">
              {showLoader ? <LoadingSpinner /> : displayResult()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FileUpload;
