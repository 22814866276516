import { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';



export default class TextEditorForPpHostInfo extends Component<any> {
    // constructor(props: any) {
    //     super(props);
    // }

    state = {
        editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
                htmlToDraft(this.props.opt).contentBlocks,
                htmlToDraft(this.props.opt).entityMap
            )
        )

    };


    updateAndNotify = () => {

        const editorState = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                htmlToDraft(this.props.opt).contentBlocks,
                htmlToDraft(this.props.opt).entityMap
            )
        );
        const editorStateWithFocusOnTheEnd = EditorState.moveFocusToEnd(editorState)
        this.setState({ editorState: editorStateWithFocusOnTheEnd });
    }


    componentDidUpdate(prevProps: any) {
        if (prevProps.opt !== this.props.opt) {
            this.updateAndNotify();

        }

    }

    onEditorStateChange = (editorState: any) => {
        this.setState({
            editorState,
        });

        //this.updateFocus();
        // console.log("TD", convertToRaw(editorState.getCurrentContent()).blocks[0].text);
        // this.props.onQuestionInputHandler(convertToRaw(editorState.getCurrentContent()).blocks[0].text);
        this.props.onQuestionInputHandler(draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        ), this.props.id, this.props.elementId , this.props.createdBy);
    };


    render() {
        const { editorState } = this.state;
        
        return (
            <div>
                <div className="border-textEditor clueInfo">
                   
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                            options: ['inline', 'fontSize', 'fontFamily', 'textAlign', 'history'],
                            fontFamily: {
                                options: ['Arial', 'AlternateGothic', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                            }
                        }}
                    />
                    
                </div>
                
            </div>

        );
    }
}