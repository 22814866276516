import { useState, useEffect } from 'react';
import "react-bootstrap";
import TextEditor from "./TextEditor";
import Options from "./Options";
import { useAuth0 } from "@auth0/auth0-react";
import { ValidationResponse } from "../../interfaces/error";
import { Validate } from "../../utils/validation";
import { IKeywordReq, IOptions, IQuesId, IQuesDetails } from "../../interfaces/update_question/IQuesDetails";
import { QUESTION_SEARCH_APIS_URL } from "../../config/config";
import { IUpdateQuesReqBody } from "../../interfaces/update_question/IUpdateQuesReqBody";
import TagsInput from './TagsInput';
import TextEditorHost from './TextEditorHost';
import { useSelector } from 'react-redux';
import { ISaveQues } from "../../interfaces/others/ISaveQues";
import Ikeyword from "../../interfaces/IKeyword";
import IQuestionTypesResponse from "../../interfaces/IQuestionTypesResponse";
import ModalComponent from "../common/ModalComponent";


const axios = require('axios').default;


function QuestionEdit(props: any) {

  interface IOption {
    key: number;
    option: string;
    isActive: boolean;
    createdBy: string;
  }

  interface IDelOption {
    key: number;
    option: string;
    isActive: boolean;
    createdBy: string;
  }

  // interface IQuestionType {
  //   questionTypeId: number;
  // }

  const [saveQuesModal, setSaveQuesModal] = useState<ISaveQues>({
    showModal: false,
    message: 'Question not updated in database',
    icon: 'fas fa-window-close team-red-modal-icon fa-2x'
  });
  const { getAccessTokenSilently } = useAuth0();
  const [type,] = useState<Array<string>>([
    "Single Choice", "Multiple Choice", "Round 1", "Jackpot", "Tie-break", "Last Man Standing", "Multimedia", "Cryptic Clue"
  ]);
  const [PPType,] = useState<Array<string>>([
    "Ponderous Puzzle"
  ]);
  const [TLType,] = useState<Array<string>>([
    "The List"
  ]);
  const keywordRedux: any = useSelector((state: any) => state.keywords);
  const questionTypesRedux: any = useSelector((state: any) => state.questionTypesReducer);

  const [textAreaNo, setTextAreaNo] = useState(0);
  const [options, setOptions] = useState<Array<IOption>>([]);
  const [deletedOptions, setDeletedOptions] = useState<Array<IDelOption>>([]);

  const [tags, setTags] = useState<Array<string>>([]);
  const [newQuestionType, setNewQuestionType] = useState<Array<string>>([]);
  const [questText, setQuesText] = useState("");
  const [hostInfo, setHostInfo] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [keywords, setKeywords] = useState<Array<string>>([]);
  const [, setPPDisable] = useState(false);
  const [, setTLDisable] = useState(false);
  const [, setOtherDisable] = useState(false);
  const [tagIds, setTagIds] = useState<Array<string>>([]);
  const userDetailReduxdata: any = useSelector((state: any) => state.userDetailReducer);
  const [, setShowLoader] = useState(false);

  const [quesErr, setQuesErr] = useState<ValidationResponse>({
    key: 'question',
    errors: []
  });
  const [optionsErr, setOptErr] = useState<ValidationResponse>({
    key: 'options',
    errors: []
  });


  useEffect(() => {

    setQuesText(props.DisplayDetail.question);
    setHostInfo(props.DisplayDetail.hostInfo);

    setTextAreaNo(props.DisplayDetail.options.length);
    setDifficulty(props.DisplayDetail.difficulty)

    let newOptionArr = [...options];

    props.DisplayDetail.options.map((val: any, i: number) => {
      newOptionArr[i] = {
        key: val.optionId,
        option: val.optionDescription,
        isActive: val.isActive,
        createdBy: val.createdBy,
      }
      return null
    }
    )

    let oldOptions = [...newOptionArr];
    let updatedOptions = oldOptions.filter((item, i) => item.isActive === true);
    setOptions(updatedOptions);

    let delOptions = [...newOptionArr];
    let delOptionArr = delOptions.filter((item, i) => item.isActive === false);
    setDeletedOptions(delOptionArr);
    setTextAreaNo(updatedOptions.length);

    let tagArray: Array<string> = []
    props.DisplayDetail.keywords.map((val: any, i: number) => {
      tagArray.push(val.keywordName)
      return null
    })

    setTags([...tags, ...tagArray]);

    let keywordArr: Array<string> = [];
    for (let i = 0; i < keywordRedux.length; i++) {
      keywordArr.push(keywordRedux[i].keywordName);
    }
    setKeywords([...keywords, ...tagArray])

    let tagIdArray: Array<string> = []

    props.DisplayDetail.keywords.map((val: any, i: number) => (
      tagIdArray.push(val.keywordId)
    ))
    setTagIds([...tagIds, ...tagIdArray])

    let TypeIdArray: Array<string> = []
    props.DisplayDetail.questionTypes.map((val: any, i: number) => (
      TypeIdArray.push(val.questionTypeName)

    ))
    setNewQuestionType([...newQuestionType, ...TypeIdArray])


    if (props.DisplayDetail.questionTypes.length > 0 && type.includes(props.DisplayDetail.questionTypes[0].questionTypeName)) {
      setPPDisable(true);
      setTLDisable(true);

    }

    else if (props.DisplayDetail.questionTypes.length > 0 && TLType.includes(props.DisplayDetail.questionTypes[0].questionTypeName)) {
      setPPDisable(true);
      setOtherDisable(true);

    }

    else if (props.DisplayDetail.questionTypes.length > 0 && PPType.includes(props.DisplayDetail.questionTypes[0].questionTypeName)) {
      setTLDisable(true);
      setOtherDisable(true);

    }
  }, [])



  const handleInputChange = (val: string, elementId: number, id: any, createdBy: any) => {

    let optionsErrorResp: ValidationResponse = Validate('opt', val);
    setOptErr(optionsErrorResp);


    let newOptionArr = [...options];
    newOptionArr[id] = {
      key: elementId,
      option: val,
      isActive: true,
      createdBy: createdBy,
    }


    setOptions(newOptionArr);
  }

/**
   * function to delete a item in options 
   * @returns {void}
   */
  const onDeleteItemFromOptions = (elementId: number, id: number, val: string, createdBy: any) => {
    let oldOptions = [...options];
    let updatedOptions = oldOptions.filter((item, i) => i !== id);
    console.log("deletedOptions", deletedOptions);

    setOptions(updatedOptions);
    if (elementId !== -1) {
      let delOptionArr = [...deletedOptions];
      delOptionArr[deletedOptions.length] = {
        key: elementId,
        option: val,
        isActive: false,
        createdBy: createdBy,
      }

      setDeletedOptions(delOptionArr);
    }
    let newTextAreaCount = textAreaNo;
    newTextAreaCount--;
    if (newTextAreaCount !== 0) {
      setTextAreaNo(newTextAreaCount);
    }

    console.log("deletedOptions", deletedOptions);
  }

/**
   * function to set headers 
   * @returns {void}
   */
  const getHeader = async () => {
    const token = await getAccessTokenSilently();
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      }
    }
    return config;
  }

/**
   * function to get type of question 
   * @returns {void}
   */
  const getQuestionType = (e: any) => {
    // to find out if it's checked or not; returns true or false
    const checked = e.target.checked;

    // to get the checked value
    const checkedValue = e.target.value;

    // to get the checked name
    // const checkedName = e.target.name;
    if (checked) {


      let newQuesType = newQuestionType;

      if (!newQuesType.includes(checkedValue)) {
        setNewQuestionType([...newQuestionType, checkedValue]);
      }

    } else {

      let newState = newQuestionType;

      let newArr = newState.filter((i) => i !== checkedValue);

      setNewQuestionType(newArr);

    }
  }


  /**
   * function to set difficulty 
   * @returns {void}
   */
  const setDifficultyFn = (val: any) => {

    setDifficulty("")

    if (val) {
      setDifficulty(val)
    }

  }


  /**
   * function to update question 
   * @returns {void}
   */
  const onUpdateQuestion = async () => {
    let questionErrorResp: ValidationResponse = Validate('question', questText);

    let optionsErrorResp: ValidationResponse = Validate('options', options);

    if (questionErrorResp.errors.length && optionsErrorResp.errors.length) {
      setQuesErr(questionErrorResp);
      setOptErr(optionsErrorResp);
      return
    }

    if (optionsErrorResp.errors.length && optionsErrorResp.errors.length) {
      setOptErr(optionsErrorResp);
      return
    }

    //question-text-validation
    if (questionErrorResp.errors.length) {
      setQuesErr(questionErrorResp);
      return;
    }
    //option validation

    console.log("options submit", options);
    console.log("deletedOptions", deletedOptions);

    const combinedArray = [...options, ...deletedOptions]
    console.log("combinedArray", combinedArray);


    let optionsForReq: Array<IOptions> = [];
    //iterate over the options array and prepare options for req body
    for (let index = 0; index < combinedArray.length; index++) {
      if (combinedArray[index] !== undefined) {
        if (index === 0) {
          optionsForReq.push({
            optionId: combinedArray[index].key,
            questionId: props.DisplayDetail.questionId,
            optionNo: index + 1,
            optionDescription: combinedArray[index].option,
            correctOption: true,
            createdBy: combinedArray[index].createdBy,
            updatedBy: userDetailReduxdata,
            isActive: combinedArray[index].isActive,
          })
        } else {
          optionsForReq.push({
            optionId: combinedArray[index].key,
            questionId: props.DisplayDetail.questionId,
            optionNo: index + 1,
            optionDescription: combinedArray[index].option,
            correctOption: false,
            createdBy: combinedArray[index].createdBy,
            updatedBy: userDetailReduxdata,
            isActive: combinedArray[index].isActive,
          })
        }
      }


    }

    let quesTypeForReq: Array<IQuesId> = [];
    for (let index = 0; index < questionTypesRedux.length; index++) {
      let quesTypeFound = questionTypesRedux.find((o: IQuestionTypesResponse) => o.questionTypeName === newQuestionType[index]);
      if (quesTypeFound) {
        quesTypeForReq.push({
          questionTypeId: quesTypeFound.questionTypeId
        })
      }
    }
    let keywordReq: Array<IKeywordReq> = [];
    //iterate over the keywords store in the redux to get the id
    //if keyword found then set its id ,otherwise set id is -1
    for (let i = 0; i < keywords.length; i++) {
      let keywordFound = keywordRedux.find((o: Ikeyword) => o.keywordName === keywords[i]);
      console.log("keywordFound", keywordFound);
      if (keywordFound) {
        keywordReq.push({
          keywordId: keywordFound.keywordId,
          keywordName: keywords[i],
          createdBy: userDetailReduxdata
        });
      } else {
        keywordReq.push({
          keywordId: -1,
          keywordName: keywords[i],
          createdBy: userDetailReduxdata
        });
      }
    }



    let quesDetails: IQuesDetails = {
      questionId: props.DisplayDetail.questionId,
      question: questText,
      hostInfo: hostInfo,
      difficulty: difficulty,
      updatedBy: userDetailReduxdata,
      questionTypes: quesTypeForReq,
      options: optionsForReq,
      keywords: keywordReq,

    }
    let updateQuesPpReqBody: IUpdateQuesReqBody = {
      updateQuestionDetails: quesDetails,
    };

    console.log("Standard_QUES_update", updateQuesPpReqBody);
    let config = await getHeader();
    setShowLoader(true);
    //api calling to post data
    axios.put(`${QUESTION_SEARCH_APIS_URL}`, updateQuesPpReqBody, config)
      .then(function (response: any) {
        setShowLoader(false);
        //get the response
        console.log("Update APi Result Succesfully Received", response);
        let saveModal = { ...saveQuesModal };
        saveModal.showModal = true;
        saveModal.icon = 'fas fa-check-square fa-2x team-reg-modal-icon';
        saveModal.message = response.data.description;
        setSaveQuesModal(saveModal);
        props.onQuestionUpdate(true);

      })
      .catch(function (error: any) {
        setShowLoader(false);
        //catch the response
        console.log("UPDATE_API_ERROR", error.message);
        let saveModal = { ...saveQuesModal };
        saveModal.showModal = true;
        setSaveQuesModal(saveModal);
      })

  }

/**
   * function to get type of question 
   * @returns {void}
   */
  const questionTypeShow = () => {
    var tableDash;
    tableDash = '';

    tableDash =

      <div className="mb-4 row">
        <label htmlFor="questionType" className="col-sm-12 col-lg-1 col-form-label">Question Type:</label>
        <div className="col-lg-11 col-sm-12 d-flex">
          <div className="radio-btn-create-question">

            <div className="me-2">
              <input type="checkbox" className="me-1" id="standard" name="standard" value="Single Choice" checked={newQuestionType.includes("Single Choice")} onChange={(e) => getQuestionType(e)} />
              <label htmlFor="standard">Single Choice</label>
            </div>
            <div className="me-2">
              <input type="checkbox" id="mc" className="me-1" name="Multiple Choice" value="Multiple Choice" checked={newQuestionType.includes("Multiple Choice")} onChange={(e) => getQuestionType(e)} />
              <label htmlFor="mc">Multiple Choice</label>
            </div>

            <div className="me-2">
              <input type="checkbox" id="round1" className="me-1" name="standard" value="Round 1" checked={newQuestionType.includes("Round 1")} onChange={(e) => getQuestionType(e)} />
              <label htmlFor="round1">Round 1</label>
            </div>
            <div className="me-2">
              <input type="checkbox" id="pp" className="me-1" name="standard" value="Ponderous Puzzle" checked={newQuestionType.includes("Ponderous Puzzle")} onChange={(e) => getQuestionType(e)} disabled />
              <label htmlFor="pp">Ponderous Puzzle</label>
            </div>
            <div className="me-2">
              <input type="checkbox" id="jj" className="me-1" name="standard" value="Jackpot" checked={newQuestionType.includes("Jackpot")} onChange={(e) => getQuestionType(e)} />
              <label htmlFor="jj">Jackpot</label>
            </div>
            <div className="me-2">
              <input name="standard" type="checkbox" className="me-1" value="Tie-break" checked={newQuestionType.includes("Tie-break")} onChange={(e) => getQuestionType(e)} />
              <label htmlFor="tie-break">Tie-Break</label>
            </div>
            <div className="me-2">
              <input type="checkbox" id="list" className="me-1" name="standard" value="The List" checked={newQuestionType.includes("The List")} onChange={(e) => getQuestionType(e)} disabled />
              <label htmlFor="list">The List</label>
            </div>
            <div className="me-2">
              <input type="checkbox" id="lms" className="me-1" name="standard" value="Last Man Standing" checked={newQuestionType.includes("Last Man Standing")} onChange={(e) => getQuestionType(e)} />
              <label htmlFor="lms">Last Man Standing</label>
            </div>
            <div className="me-2">
              <input type="checkbox" id="multi" className="me-1" name="standard" value="Multimedia" checked={newQuestionType.includes("Multimedia")} onChange={(e) => getQuestionType(e)} />
              <label htmlFor="multi"> Multimedia</label>
            </div>
            <div className="">
              <input type="checkbox" id="cc" className="me-1" name="standard" value="Cryptic Clue" checked={newQuestionType.includes("Cryptic Clue")} onChange={(e) => getQuestionType(e)} />
              <label htmlFor="cc"> Cryptic Clue</label>
            </div>
          </div>

        </div>

      </div>

    return (
      tableDash
    )
  }


  /**
   * function to set question on input
   * @returns {void}
   */
  const onQuestionInputHandler = (data: any) => {
    let questionErrorResp: ValidationResponse = Validate('question', questText);
    setQuesErr(questionErrorResp);
    setQuesText(data);
  }


  /**
   * function to set host information on input
   * @returns {void}
   */
  const onHostInfoInputHandler = (data: any) => {
    setHostInfo(data);
    console.log("hostInfo  hostInfo", hostInfo);
  }


  /**
   * function to set keywords on change
   * @returns {void}
   */
  const onChangeKeywords = (tags: Array<string>) => {
    setKeywords(tags);
  }

  /**
   * function to save modal data
   * @returns {void}
   */
  const onSaveBtnValidModal = () => {
    let saveModal = { ...saveQuesModal };
    saveModal.showModal = false;
    setSaveQuesModal(saveModal);
  }

  return (

    <div className="mt-3 card m-0">
      <div className="card-body">
        <ModalComponent isShowModal={saveQuesModal.showModal} onHandleOkBtn={onSaveBtnValidModal} text={saveQuesModal.message} icon={saveQuesModal.icon} />

        <div className="mb-4 row">
          <div className="col-sm-12 col-lg-1 col-form-label">
            <label htmlFor="QuestionName">Question:</label>
          </div>
          <div className="col-sm-12 col-lg-11">
            <div className="form-group res-border">
              <TextEditor question={props.DisplayDetail} onQuestionInputHandler={onQuestionInputHandler} quesErr={quesErr} />

            </div>
            {quesErr.errors.length ? <span className="text-danger">{quesErr.errors[0].message}</span> : <></>}

          </div>
        </div>


        <div className="col-12 mt-2 ">
          <div className="row">
            <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Options:</label>
            <div className="col-lg-11 col-sm-12">
              {[...Array(textAreaNo)].map((e, i) => (

                <Options id={i} elementId={options[i] ? options[i].key : -1} onDeleteItemFromOptions={onDeleteItemFromOptions}
                  createdBy={options[i] ? options[i].createdBy : userDetailReduxdata} handleInputChange={handleInputChange} opt={options[i] ? options[i].option : ""} />
              ))}
              {optionsErr.errors.length ? <span className="text-danger">{optionsErr.errors[0].message}</span> : <></>}
            </div>

          </div>

        </div>

        <div className="mb-4 row">
          <div className="col-1"></div>
          <div className="col-11">
            <button className="btn btn-success" onClick={() => setTextAreaNo(textAreaNo + 1)}>Add Option</button>
          </div>
        </div>


        <div className="mb-4 row">
          <label htmlFor="KeywordName" className="col-sm-12 col-lg-1 col-form-label">Host Info:</label>
          <div className="col-sm-12 col-lg-11">
            <div className="form-group">
              <TextEditorHost hostInfo={props.DisplayDetail.hostInfo} onHostInfoInputHandler={onHostInfoInputHandler} />
            </div>
          </div>
        </div>


        <div className="mb-4 row">
          <label htmlFor="QuestionName" className="col-sm-12 col-lg-1 col-form-label">Keyword:</label>
          <div className="col-sm-12 col-lg-11">
            <div className="form-group">

              <TagsInput onChangeKeywords={onChangeKeywords} tags={tags} />

            </div>
          </div>
        </div>


        <div className="mb-4 row">
          <label htmlFor="difficulty" className="col-sm-12 col-lg-1 col-form-label">Difficulty:</label>
          <div className="col-sm-12 col-lg-5">
            <select className="form-select" id="difficulty" name="difficulty" onChange={(e) => setDifficultyFn(e.target.value)} defaultValue={difficulty}>
              <option selected>Select Difficulty Level</option>
              <option selected={difficulty === "Easy"} value="Easy">Easy</option>
              <option selected={difficulty === "Medium"} value="Medium">Medium</option>
              <option selected={difficulty === "Hard"} value="Hard">Hard</option>
            </select>
          </div>
        </div>

        <div>
          {(questionTypeShow())}
        </div>


        <div className="mb-4 row">
          <div className="col-lg-12 d-flex justify-content-end action-button">
            <button type="button" className="m-1 btn btn-success" onClick={onUpdateQuestion}>
              Update Question
            </button>
          </div>
        </div>



      </div>

    </div>

  );
}

export default QuestionEdit; 