import IGamesData from "../interfaces/IGamesData";
// import IScore from "../interfaces/IScore";
// import {useSelector} from 'react-redux';
export default function checkForPpVisiblity( data:IGamesData[],teamName:any) {
   //check isPP if true for previous index if yes
   if(teamName==="UP1") {
       console.log("UPPPPP2");
       
   }
   let arr =    data[0].info.filter(item => (item.teamName === teamName));

   for(let i =0;i<arr.length;i++){
       if(arr[i].is_pp_active){
           return true;
       }
   }
   return false;
//    data[0].info.filter(item => (item.teamName === teamName)).map((score:any)=>{
//        if(teamName ==="UP1" && score.roundId===2){
//            console.log("hhhhh");
//        }
// if(score.is_pp_active === true){
//     return true; //yes disable
//     break;
// }
//    }) 
//    return false;
  }