/**
 * Function to get the data of old teams from populateOldTeamReducer
 * @param {any} state   
 * @param {any} action   
 * @returns {any}
 */
const populateOldTeamReducer = (state: any = [], action: any) => {
    if (action.type === 'OLD_TEAMS') {
        return state = action.payload;
    }
    return state;
}
export default populateOldTeamReducer;