import { useState, useEffect } from 'react';
import "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { ValidationResponse } from "../../interfaces/error";
import { Validate } from "../../utils/validation";
import TagsInput from './TagsInput';
import TextEditorAnswer from './TextEditorAnswer'
import { IClue } from "../../interfaces/update_question/IClue";
import TextEditorForCreatePp from './TextEditorForCreatePp';
import TextEditorForPpHostInfo from './TextEditorForPpHostInfo';
import { IKeywordReq, IOptions, IQuesId } from "../../interfaces/update_question/IQuesDetails";
import Ikeyword from "../../interfaces/IKeyword";
import { useSelector } from 'react-redux';
import { IPpClue, IQuesDetails } from "../../interfaces/update_question/PP/IQuesDetails";
import { ISaveQues } from "../../interfaces/others/ISaveQues";
import { QUESTION_SEARCH_APIS_URL } from "../../config/config";
import { IUpdateQuesReqBody } from "../../interfaces/update_question/PP/IUpdateQuesReqBody";
import ModalComponent from "../common/ModalComponent";

const axios = require('axios').default;


function PpAnswerEdit(props: any) {

    interface IOptionns {
        key: number;
        option: string;
    }
    const [saveQuesModal, setSaveQuesModal] = useState<ISaveQues>({
        showModal: false,
        message: 'Question not saved in database',
        icon: 'fas fa-window-close team-red-modal-icon fa-2x'
    });
    const [clueInfo, setClueInfo] = useState<Array<IClue>>([]);
    const [deletedOptions, setDeletedOptions] = useState<Array<IClue>>([]);

    const { getAccessTokenSilently } = useAuth0();

    const [tags, setTags] = useState<Array<string>>([]);
    const [tagIds, setTagIds] = useState<Array<string>>([]);

    const [keywords, setKeywords] = useState<Array<string>>([]);
    const [cluesErr, setclueErr] = useState<ValidationResponse>({
        key: 'clues',
        errors: []
    });


    const [answerErr, setAnswerErr] = useState<ValidationResponse>({
        key: 'answer',
        errors: []
    });
    const [textAreaNo, setTextAreaNo] = useState(1);
    const [optionsErr, setOptErr] = useState<ValidationResponse>({
        key: 'clue',
        errors: []
    });
    const [, setRefresh] = useState(false);

    const [options,] = useState<Array<IOptionns>>([]);

    const [answerText, setAnswerText] = useState("");
    const userDetailReduxdata: any = useSelector((state: any) => state.userDetailReducer);
    const keywordRedux: any = useSelector((state: any) => state.keywords);
    const [, setShowLoader] = useState(false);

    useEffect(() => {
        console.log("props.DisplayDetail", props.DisplayDetail)

        let tagArray: Array<string> = []
        props.DisplayDetail.keywords.map((val: any, i: number) => {
            tagArray.push(val.keywordName)
            return null
        })

        setTags([...tags, ...tagArray]);

        let keywordArr:Array<string>=[];
        for(let i=0;i<keywordRedux.length;i++){
            keywordArr.push(keywordRedux[i].keywordName);
        }
        setKeywords([...keywords, ...tagArray]);

        props.DisplayDetail.keywords.map((val: any, i: number) => (
            setTagIds([...tagIds, val.keywordId])
        ))

    

        let newClueArr = [...clueInfo];

        console.log("props.DisplayDetail.ppClueMaster", props.DisplayDetail.ppClueMaster);
        props.DisplayDetail.ppClueMaster.map((val: any, i: number) => (
            newClueArr[i] = {
                key: val.clueId,
                clue: val.clueDescription,
                hostInfo: val.hostInfo,
                isActive: val.isActive,
                createdBy: val.createdBy,
            }
        ))



        let oldOptions = [...newClueArr];
        let updatedOptions = oldOptions.filter((item, i) => item.isActive === true);
        setClueInfo(updatedOptions);

        let delOptions = [...newClueArr];
        let delOptionArr = delOptions.filter((item, i) => item.isActive === false);
        setDeletedOptions(delOptionArr);
        setTextAreaNo(updatedOptions.length);

        setAnswerText(props.DisplayDetail.question);


    }, [])


    const onChangeKeywords = (tags: Array<string>) => {
        setKeywords(tags);
    }

    /**
       * function to set the headers
      * @returns {void}
       */
    const getHeader = async () => {
        // const user: any = localStorage.getItem('items');//user name from local storage
        const token = await getAccessTokenSilently();

        let config = {
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            }
        }
        return config;
    }

    const onUpdateAnswer = async () => {
        let answerErrorResp: ValidationResponse = Validate('answerData', answerText);
        let clueErrorResp: ValidationResponse = Validate('clues', clueInfo);

        if (optionsErr.errors.length) {
            return
        }

        


        if (answerErrorResp.errors.length) {
            setAnswerErr(answerErrorResp);
            return
        }
        

        //question-text-validation
        if (clueErrorResp.errors.length) {
            setclueErr(clueErrorResp);
            return;
        }

        let ansArr: Array<string> = [];
        for (let i = 0; i < options.length; i++) {
            ansArr.push(options[i].option);
        }


        //get keywords from redux 
        let keywordReq: Array<IKeywordReq> = [];
        //iterate over the keywords store in the redux to get the id
        //if keyword found then set its id ,otherwise set id is -1
        for (let i = 0; i < keywords.length; i++) {
            let keywordFound = keywordRedux.find((o: Ikeyword) => o.keywordName === keywords[i]);
            if (keywordFound) {
                keywordReq.push({
                    keywordId: keywordFound.keywordId,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
            } else {
                keywordReq.push({
                    keywordId: -1,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
            }
        }

        let optionsForReq: Array<IOptions> = [];
        optionsForReq.push({
            optionId: props.DisplayDetail.options[0].optionId,
            questionId: props.DisplayDetail.questionId,
            optionNo: 1,
            optionDescription: answerText,
            correctOption: true,
            createdBy: props.DisplayDetail.options[0].createdBy,
            updatedBy: userDetailReduxdata,
            isActive: true,
        })

        let quesTypeForReq: Array<IQuesId> = [];
        quesTypeForReq.push({
            questionTypeId: 4
        });

        console.log("clueInfo", clueInfo);

        console.log("clueInfo submit", clueInfo);
        console.log("deletedOptions", deletedOptions);

        const combinedArray = [...clueInfo, ...deletedOptions]
        console.log("combinedArray", combinedArray);


        let ppClues: Array<IPpClue> = [];
        combinedArray.map((value, idx) => {
            ppClues.push({
                clueNo: `${idx + 1}`,
                clueDescription: value.clue,
                hostInfo: value.hostInfo,
                createdBy: value.createdBy,
                updatedBy: userDetailReduxdata,
                clueId: value.key,
                questionId: props.DisplayDetail.questionId,
                isActive: value.isActive

            })
            return null
        });
        let quesDetails: IQuesDetails = {
            questionId: props.DisplayDetail.questionId,
            question: answerText,
            updatedBy: userDetailReduxdata,
            questionTypes: quesTypeForReq,
            options: optionsForReq,
            keywords: keywordReq,
            ppClueMaster: ppClues

        }
        let updateQuesPpReqBody: IUpdateQuesReqBody = {
            updateQuestionDetails: quesDetails,
        };

        console.log("PP_QUES_updateeeeeeeeeeeeee", updateQuesPpReqBody);
        let config = await getHeader();
        setShowLoader(true);
        //api calling to post data
        axios.put(`${QUESTION_SEARCH_APIS_URL}`, updateQuesPpReqBody, config)
            .then(function (response: any) {
                setShowLoader(false);
                //get the response
                console.log("Update APi Result Succesfully Received", response);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                saveModal.icon = 'fas fa-check-square fa-2x team-reg-modal-icon';
                saveModal.message = response.data.description;
                setSaveQuesModal(saveModal);
                props.onQuestionUpdate(true);

                setOptErr({
                    key: 'clue',
                    errors: []
                });
                setAnswerErr({
                    key: 'answer',
                    errors: []
                });
                setclueErr({
                    key: 'clues',
                    errors: []
                });
            })
            .catch(function (error: any) {
                setShowLoader(false);
                //catch the response
                console.log("CREATE_API_ERROR", error.message);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                setSaveQuesModal(saveModal);
            })

    }

    const onAnswerInputHandler = (data: any) => {

        let answerErrorResp: ValidationResponse = Validate('answerData', data);
        setAnswerErr(answerErrorResp);
        setAnswerText(data);
    }



    const onClueInfoChange = (val: string, id: any, elementId: any, createdBy: any) => {
        let clueErrorResp: ValidationResponse = Validate('singleClue', val);
        setOptErr(clueErrorResp);
        let newClueArr = [...clueInfo];
        newClueArr[id] = {
            key: elementId,
            clue: val,
            hostInfo: clueInfo[id] ? clueInfo[id].hostInfo : "",
            createdBy: createdBy,
            isActive: true
        }
        setClueInfo(newClueArr);
        setclueErr({
            key: 'clues',
            errors: []
        });
    }



    const onHostInfoChange = (val: string, id: any, elementId: any, createdBy: any) => {

        clueInfo[id] = {
            key: elementId,
            clue: clueInfo[id] ? clueInfo[id].clue : "",
            hostInfo: val,
            createdBy: createdBy,
            isActive: true
        }

    }

    const addOptions = (e: any) => {
        e.preventDefault();
    }

    const onDeleteClue = (elementId: number, id: number, createdBy: any, clueVal: string, hostVal: string) => {
        
        let oldClues = [...clueInfo];
        console.log("id", id);
        let updatedClues = oldClues.filter((item, i) => i !== id);
        setClueInfo(updatedClues);
        console.log("clueInfo", clueInfo);

        if (elementId !== -1) {
            let delOptionArr = [...deletedOptions];
            delOptionArr[deletedOptions.length] = {
                key: elementId,
                clue: clueVal ? clueVal : "",
                hostInfo: hostVal,
                createdBy: createdBy,
                isActive: false
            }

            setDeletedOptions(delOptionArr);
           
        }

        setOptErr({
            key: 'clue',
            errors: []
        });

        console.log("optionsErr",optionsErr);

        let newTextAreaCount = textAreaNo;
        newTextAreaCount--;
        if (newTextAreaCount !== 0) {
            setTextAreaNo(newTextAreaCount);
        }
        setRefresh(c => !c);
    }

    const onClickOkBtnOfSaveBtnValidModal = () => {
        let saveModal = { ...saveQuesModal };
        saveModal.showModal = false;
        setSaveQuesModal(saveModal);
    }


    return (

        <div className="mt-3 card m-0">
            <div className="card-body">
                <ModalComponent isShowModal={saveQuesModal.showModal} onHandleOkBtn={onClickOkBtnOfSaveBtnValidModal} text={saveQuesModal.message} icon={saveQuesModal.icon} />

                <div className="mb-4 row">
                    <div className="col-sm-12 col-lg-1 col-form-label">
                        <label htmlFor="QuestionName">Answer:</label>
                    </div>
                    <div className="col-sm-12 col-lg-11">
                        <div className="form-group">
                            <TextEditorAnswer answerData={props.DisplayDetail} onAnswerInputHandler={onAnswerInputHandler} />
                        </div>
                        {answerErr.errors.length ? <span className="text-danger">{answerErr.errors[0].message}</span> : <></>}

                    </div>
                </div>



                {[...Array(textAreaNo)].map((e, i) => (
                    <>

                        <div className="mb-4 row">

                            <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">{`Clue${i + 1}:`} </label>
                            <div className="col-lg-6 col-sm-12" onChange={e => addOptions(e)}>
                                <TextEditorForCreatePp onQuestionInputHandler={onClueInfoChange} elementId={clueInfo[i] ? clueInfo[i].key : -1}
                                    createdBy={clueInfo[i] ? clueInfo[i].createdBy : userDetailReduxdata} id={i} opt={clueInfo[i] ? clueInfo[i].clue : '<p></p>'} />
                            </div>


                            <div className="col-lg-5 col-sm-12">
                                <div className="row g-0">
                                    <label className="col-lg-2 col-sm-12 text-start fs-6 col-form-label">{`Host Info:`}</label>
                                    <div className="col-lg-9 col-sm-12">
                                        <TextEditorForPpHostInfo onQuestionInputHandler={onHostInfoChange} elementId={clueInfo[i] ? clueInfo[i].key : -1}
                                            createdBy={clueInfo[i] ? clueInfo[i].createdBy : userDetailReduxdata} id={i} opt={clueInfo[i] ? clueInfo[i].hostInfo : '<p></p>'} />
                                    </div>
                                    <div className="col-lg-1 py-2 px-2 text-end" >
                                        <i className="fas fa-trash trash-hover text-end" onClick={() => onDeleteClue(clueInfo[i].key, i, clueInfo[i].createdBy, clueInfo[i].clue, clueInfo[i].hostInfo)}></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                ))}

                <div className="mb-4 row">
                    <div className="col-lg-1 col-sm-12"></div>
                    <div className="col-lg col-sm-12">
                        {optionsErr.errors.length ? <span className="text-danger">{optionsErr.errors[0].message}</span> : <></>}
                        {cluesErr.errors.length ? <span className="text-danger">{cluesErr.errors[0].message}</span> : <></>}

                    </div>
                </div>

                <div className="mb-4 row">
                    <div className="col-1"></div>
                    <div className="col-11">
                        <button className="btn btn-success" onClick={() => setTextAreaNo(textAreaNo + 1)}>Add Clue</button>
                    </div>
                </div>


                <div className="mb-4 row">
                    <label htmlFor="QuestionName" className="col-sm-12 col-lg-1 col-form-label">Keyword:</label>
                    <div className="col-sm-12 col-lg-11">
                        <div className="form-group">

                            <TagsInput onChangeKeywords={onChangeKeywords} tags={tags} />

                        </div>
                    </div>
                </div>


                <div className="mb-4 row">
                    <div className="col-lg-12 d-flex justify-content-end action-button">
                        <button type="button" className="m-1 btn btn-success" onClick={onUpdateAnswer}>
                            Update Question
                        </button>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default PpAnswerEdit; 