import { useState } from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import BionLogo from '../content/images/bion-logo.png';
import '../content/css/Login.css';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { basicSchema } from '../schemas';
import { Modal } from 'react-bootstrap';
// import { useAuth0 } from "@auth0/auth0-react";
const axios = require('axios').default;
function Login() {


    // const {
    //     isAuthenticated,
    // } = useAuth0();

    const [loginState, setLoginState] = useState(false);
    const navigate = useNavigate();

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: basicSchema,
        onSubmit: async (values) => {
            axios.post('http://localhost:3000/users', {
                email: values.email,
                password: values.password
            })
                .then(function (response: any) {
                    console.log(`Response Received${response}`);
                    return navigate("/layout")
                })
                .catch(function (error: any) {
                    console.log(error);
                });

        },
    });

    /**
  * function to display login modal
 * @returns {void}
  */
    const showLoginModal = () => {
        setLoginState(true);
    }

    /**
  * function to close login modal
 * @returns {void}
  */
    const closeModal = () => {
        setLoginState(false);
    }


    return (<>

        <div>
            <Modal show={loginState}>
                <Modal.Header className="modal-header">
                    <h3 className='info-txt'>Forget Password?</h3><br></br>
                    <img src={BionLogo} alt="logo" className="logos" />
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className='label'>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                value={values.email}
                                onChange={handleChange}
                                id="email"
                                type='email'
                                placeholder='something@gmail.com'
                            />
                        </Form.Group>

                        <Form.Group className='label'>
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                value={values.password}
                                onChange={handleChange}
                                id="password"
                                type='password'
                                placeholder='alphanumeric' />

                        </Form.Group>

                        <Button className="mt-3 btn-block success col-md-12 text-center" type="submit" >
                            Reset Passoword
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-success">close</button>
                </Modal.Footer>
            </Modal>
            <Container>
                <Row className="m-auto">
                    <Col lg={5} md={6} sm={12} className="card p-5 m-auto shadow-sm rounded-lg login-card">
                        <div className="text-center">
                            <img src={BionLogo} className="Bion-logo" alt="logo" />
                            <h1 className="text-success mt-2 p-3 rounded text-info"> Login</h1>
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className='label'>
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    value={values.email}
                                    onChange={handleChange}
                                    id="email"
                                    type='email'
                                    placeholder='something@gmail.com'
                                    onBlur={handleBlur}
                                    className={errors.email && touched.email ? "input-error" : ""} />
                                {errors.email && touched.email && <p className='error text-danger'><i className="fas fa-exclamation-circle pe-2"></i>{errors.email}</p>}
                            </Form.Group>

                            <Form.Group className='label'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    value={values.password}
                                    onChange={handleChange}
                                    id="password"
                                    type='password'
                                    placeholder='alphanumeric'
                                    onBlur={handleBlur}
                                    className={errors.password && touched.password ? "input-error" : ""} />
                                {errors.password && touched.password && <p className='error text-danger'><i className="fas fa-exclamation-circle pe-2"></i>{errors.password}</p>}
                            </Form.Group>

                            <Button className="mt-3 btn-block success col-md-12 text-center" type="submit" >
                                Login
                            </Button>

                            <div className='Register-link'> <Link to="/register"><u>Registration</u></Link> <br></br></div>
                            <div className='Register-link'> <a onClick={showLoginModal}><u>Forget Password?</u></a></div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
    );
}

export default Login;