import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './content/css/style.css'
import './content/css/theme-style.css'
// import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from 'react-redux';
// import {store} from './state/store';
import store,{persistor} from './state/store';
import { PersistGate } from 'redux-persist/integration/react'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <BrowserRouter>
  
    <Auth0Provider
      audience='https://api.sky.co.nz'
      domain="login.dev.skydev.co.nz"
      clientId="rGUbJItGXWsKc5K0gLPoYqxhfXS86khQ"
      redirectUri={window.location.origin}
      useRefreshTokens={true}

    >
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <App />
      </PersistGate>
      </Provider>
    </Auth0Provider>

  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
