import ModalTextEditor from "./common/ModalTextEditor";
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
// import TextEditor from "./TextEditor";

function Options(props: any) {
    const [showModal, setShowModal] = useState(false);
    const [optionText, setOptionText] = useState("");
    // const handleDataChange = (evt: any) => {
    //     evt.preventDefault();
    //     console.log(`${evt.target.value}${props.elementId}`);
    //     props.handleInputChange(evt.target.value, props.elementId);
    // }
    const deleteOption = () => {
        props.onDeleteItemFromOptions(props.elementId);
    }
    const showTextEditor = () => {
        setShowModal(true);
    }
    const onHandleOkBtn = () => {
        setShowModal(false);
    }
    const getOptionInputHandler = (data: any) => {
        console.log("OPT_TXT",data);
        
        setOptionText(data);
        props.handleInputChange(data, props.elementId);
    }
    return (


        <div key={props.elementId} className="row g-0 mb-2">

            <Modal show={showModal} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body className="text-center">
                    {/* <i className={props.icon}></i> */}
                    <ModalTextEditor getOptionInputHandler={getOptionInputHandler} opt={props.opt?props.opt:'<p></p>'}/>
                    {/* <ModalTextEditor getOptionInputHandler={getOptionInputHandler} opt='<p><ins>op2</ins> <em>op2</em></p>\n'/> */}
                    <div>
                        {/* <button className="btn bg-primary text-white text-left m-2" onClick={props.onHandleOkBtn}>ok</button> */}
                        <button className="btn btn-success m-2" onClick={onHandleOkBtn} style={{ "width": "12%" }}>ok</button>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="col-12 d-flex p-0">
                {/* <input type="text" className="form-control w-50  col-6 i-box"  value={props.opt?props.opt:""} placeholder="Option" onChange={evt => handleDataChange(evt)} /> */}
                <div className="form-control w-50  col-6 standard-option rounded-0" onClick={showTextEditor}  >
                    <div dangerouslySetInnerHTML=  {{__html: props.opt?props.opt:optionText}} ></div>
            
                    {/* {optionText} */}
                    {/* {{__html: optionText}} */}
                </div>
                {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button> */}
                <div className="col-1 py-2 px-2" >
                    <i className="fas fa-trash trash-hover"   onClick={deleteOption}></i>
                </div>
            </div>
        </div>

    );
}

export default Options;