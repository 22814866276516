import Layout from "./Layout";
import TextEditor from "./TextEditor";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Options from "./Options";
import TagsInput from "./TagsInput";
import { ValidationResponse } from "../interfaces/error";
import { Validate } from "../utils/validation";
import { ICreateQuesReqBody } from "../interfaces/create-new-questions/ICreateQuesReqBody";
import { IKeywordReq, IOptions, IQuesDetails, IQuesId } from "../interfaces/create-new-questions/IQuesDetails";
import { useAuth0 } from "@auth0/auth0-react";
import ModalComponent from "./common/ModalComponent";
import Ikeyword from "../interfaces/IKeyword";
import { useDispatch } from 'react-redux';
import { actionCreators } from '../state/index';
import IQuestionTypesResponse from "../interfaces/IQuestionTypesResponse";
import { CREATE_QUES_APIS_URL } from "../config/config";
import { ISaveQues } from "../interfaces/others/ISaveQues";
import LoadingSpinner from "./common/LoadingSpinner";
import SaveKeywords from "../utils/SaveKeywords";

interface IOption {
    key: number;
    option: string;
}

//axios for api calling
const axios = require('axios').default;

function CreateNewQuestion() {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    // const mainClass = "mainWithoutNav";;
    //get data of keywords From Redux
    const keywordRedux: any = useSelector((state: any) => state.keywords);
    const [textAreaNo, setTextAreaNo] = useState(1);
    // const [options, setOptions] = useState<Array<string>>([]);
    const [options, setOptions] = useState<Array<IOption>>([]);
    // const [ansOpt, setansOpt] = useState([]);
    const [questText, setOuesText] = useState("");
    const [hostInfo, setHostInfo] = useState("");
    const [diffculty, setDifficulty] = useState("");
    const [quesType, setQuesType] = useState<Array<string>>([]);
    const [keywords, setKeywords] = useState<Array<string>>([]);
    const [quesErr, setQuesErr] = useState<ValidationResponse>({
        key: 'question',
        errors: []
    });
    const [optionsErr, setOptErr] = useState<ValidationResponse>({
        key: 'options',
        errors: []
    });
    const [otherDisable,] = useState(false);
    //get data of quesType From Redux
    const quesTypeReduxData: any = useSelector((state: any) => state.questionTypesReducer);
    //get username from userDetail Redux Data
    const userDetailReduxdata: any = useSelector((state: any) => state.userDetailReducer);

    //state to show modal on save button click
    const [saveQuesModal, setSaveQuesModal] = useState<ISaveQues>({
        showModal: false,
        message: 'Question not saved in database',
        icon: 'fas fa-window-close team-red-modal-icon fa-2x'
    });
    const [showLoader, setShowLoader] = useState(false);
    const addOptions = (e: any) => {
        e.preventDefault();
        // let newOptions = [...options];
        // newOptions.push(e.target.value);
        // setOptions(newOptions);
    }
    const onChangeKeywords = (tags: Array<string>) => {
        setKeywords(tags);
    }
    const onDeleteItemFromOptions = (id: number) => {
        let oldOptions = [...options];
        let updatedOptions = oldOptions.filter((item, i) => i !== id);
        setOptions(updatedOptions);
        let newTextAreaCount = textAreaNo;
        newTextAreaCount--;
        if (newTextAreaCount !== 0) {
            setTextAreaNo(newTextAreaCount);
        }

    }
    const handleInputChange = (val: string, id: any) => {

        let optionsErrorResp: ValidationResponse = Validate('opt', val);
        setOptErr(optionsErrorResp);
        let newOptionArr = [...options];
        newOptionArr[id] = {
            key: id,
            option: val
        }
        setOptions(newOptionArr);
    }
    const onQuestionInputHandler = (data: any) => {
        // let questionErrorResp: ValidationResponse = Validate('question', questText);
        let questionErrorResp: ValidationResponse = Validate('question', data);
        setQuesErr(questionErrorResp);

        setOuesText(data);
    }
    const onHostInfoChange = (e: any) => {

        // setHostInfo(e.target.value);
        setHostInfo(e);
    }
    const setSelectedDifficultyLevel = (e: string) => {
        if (e === "Select Difficulty level") {
            setDifficulty("");
        } else {
            setDifficulty(e);
        }

    }
    const getQuestionType = (e: any) => {
        // to find out if it's checked or not; returns true or false
        const checked = e.target.checked;
        // to get the checked value
        const checkedValue = e.target.value;

        // to get the checked name
        // const checkedName = e.target.name;
        if (checked) {
            let newQuesType = [...quesType];
            newQuesType.push(checkedValue);
            setQuesType(newQuesType);


        } else {

            const types = quesType;
            const index = types.indexOf(checkedValue);
            if (index > -1) { // only splice array when item is found
                types.splice(index, 1); // 2nd parameter means remove one item only
            }
            setQuesType(types);
        }
    }


    const onSaveQuestion = async () => {
        //alert(questText);
        let questionErrorResp: ValidationResponse = Validate('question', questText);
        let optionsErrorResp: ValidationResponse = Validate('options', options);
        if (questionErrorResp.errors.length && optionsErrorResp.errors.length) {
            setQuesErr(questionErrorResp);
            setOptErr(optionsErrorResp);
        }
        //question-text-validation
        if (questionErrorResp.errors.length) {
            setQuesErr(questionErrorResp);
            return;
        }
        //option validation
        if (optionsErrorResp.errors.length) {
            setOptErr(optionsErrorResp);
            return
        }

        let ansArr: Array<string> = [];
        for (let i = 0; i < options.length; i++) {
            ansArr.push(options[i].option);
        }

        //get keywords from redux 
        let keywordReq: Array<IKeywordReq> = [];
        let keywordInRedux = [...keywordRedux];
        //iterate over the keywords store in the redux to get the id
        //if keyword found then set its id ,otherwise set id is -1
        for (let i = 0; i < keywords.length; i++) {
            let keywordFound = keywordRedux.find((o: Ikeyword) => o.keywordName === keywords[i]);
            if (keywordFound) {
                keywordReq.push({
                    keywordId: keywordFound.keywordId,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
            } else {
                keywordInRedux.push({
                    keywordId: -1,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
                keywordReq.push({
                    keywordId: -1,
                    keywordName: keywords[i],
                    createdBy: userDetailReduxdata
                });
            }
        }
        dispatch(actionCreators.keywords(keywordInRedux));
        let optionsForReq: Array<IOptions> = [];
        //iterate over the options array and prepare options for req body
        for (let index = 0; index < ansArr.length; index++) {
            if (index === 0) {
                optionsForReq.push({
                    optionNo: index + 1,
                    optionDescription: ansArr[index],
                    correctOption: true,
                    createdBy: userDetailReduxdata,
                    updatedBy: userDetailReduxdata
                })
            } else {
                optionsForReq.push({
                    optionNo: index + 1,
                    optionDescription: ansArr[index],
                    correctOption: false,
                    createdBy: userDetailReduxdata,
                    updatedBy: userDetailReduxdata
                })
            }

        }
        let quesTypeForReq: Array<IQuesId> = [];
        for (let index = 0; index < quesTypeReduxData.length; index++) {

            let quesTypeFound = quesTypeReduxData.find((o: IQuestionTypesResponse) => o.questionTypeName === quesType[index]);
            if (quesTypeFound) {
                quesTypeForReq.push({
                    questionTypeId: quesTypeFound.questionTypeId
                })
            } else {

            }
        }

        let quesDetails: IQuesDetails = {
            question: questText,
            difficulty: diffculty,
            hostInfo: hostInfo,
            createdBy: userDetailReduxdata,
            updatedBy: userDetailReduxdata,
            questionTypes: quesTypeForReq,
            keywords: keywordReq,
            options: optionsForReq,

        }


        let createNewQuesReqBody: ICreateQuesReqBody = {
            questionDetails: quesDetails,
        };

        console.log("CREATE_QUES_REQ", createNewQuesReqBody);
        let config = await getHeader();
        setShowLoader(true);
        //api calling to post data
        axios.post(`${CREATE_QUES_APIS_URL}`, createNewQuesReqBody, config)
            .then(async function (response: any) {
                setShowLoader(false);
                //get the response
                console.log("Create APi Result Succesfully Received", response);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                saveModal.icon = 'fas fa-check-square fa-2x team-reg-modal-icon';
                saveModal.message = response.data.description;
                setSaveQuesModal(saveModal);

                const token = await getAccessTokenSilently();
                // Function to store keyword data in redux
                const keywordsData = await SaveKeywords(token);

                dispatch(keywordsData);


            })
            .catch(function (error: any) {
                setShowLoader(false);
                //catch the response
                console.log("CREATE_API_ERROR", error.message);
                let saveModal = { ...saveQuesModal };
                saveModal.showModal = true;
                setSaveQuesModal(saveModal);
            })


    }


    const getHeader = async () => {
        const token = await getAccessTokenSilently();
        let config = {
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            }
        }
        return config;
    }

    const onClickOkBtnOfSaveBtnValidModal = () => {
        let saveModal = { ...saveQuesModal };
        saveModal.showModal = false;
        setSaveQuesModal(saveModal);
        window.location.reload();
    }
    const showData = () => {
        return (
            <div className="card-body">
                <div className="col-12 mt-2">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Question:</label>
                        <div className="col-lg-11 col-sm-12">
                            <TextEditor onQuestionInputHandler={onQuestionInputHandler} quesErr={quesErr} />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-2 ">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Options:</label>
                        <div className="col-lg-11 col-sm-12" onChange={e => addOptions(e)}>
                            {[...Array(textAreaNo)].map((e, i) => (

                                <Options elementId={i} onDeleteItemFromOptions={onDeleteItemFromOptions} handleInputChange={handleInputChange} opt={options[i] ? options[i].option : ""} />
                            ))}
                            {optionsErr.errors.length ? <span className="text-danger">{optionsErr.errors[0].message}</span> : <></>}
                        </div>

                    </div>

                </div>

                <div className="col-12 mt-2">
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-11">
                            <button className="btn btn-success" onClick={() => setTextAreaNo(textAreaNo + 1)}>Add Option</button>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-2 ">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Host Info:</label>
                        <div className="col-lg-11 col-sm-12">
                            <TextEditor onQuestionInputHandler={onHostInfoChange} />
                        </div>
                    </div>

                </div>
                <div className="col-12 mt-2">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Keyword:</label>
                        <div className="col-lg-11 col-sm-12">

                            <TagsInput onChangeKeywords={onChangeKeywords} />
                        </div>
                    </div>

                </div>
                <div className="col-12 mt-2">
                    <div className="row">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Difficulty:</label>
                        <div className="col-lg-11 col-sm-12">
                            <select id="selectRound" className="form-select w-50" onChange={e => setSelectedDifficultyLevel(e.target.value)}>
                                <option value="">Select Difficulty level</option>
                                <option value="Easy">Easy</option>
                                <option value="Medium">Medium</option>
                                <option value="Hard">Hard</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div className="col-12">
                    <div className="row mt-2">
                        <label className="col-lg-1 col-sm-12 text-start fs-6 col-form-label">Question Type:</label>
                        <div className="col-lg-11 col-sm-12 d-flex">
                            <div className="radio-btn-create-question">
                                <div className="me-2">
                                    <input type="checkbox" className="me-1" id="standard" name="standard" value="Single Choice" onChange={getQuestionType} disabled={otherDisable} />
                                    <label htmlFor="standard">Single Choice</label>
                                </div>
                                <div className="me-2">
                                    <input type="checkbox" id="mc" className="me-1" name="Multiple Choice" value="Multiple Choice" onChange={getQuestionType} disabled={otherDisable} />
                                    <label htmlFor="mc">Multiple Choice</label>
                                </div>

                                <div className="me-2">
                                    <input type="checkbox" id="round1" className="me-1" name="standard" value="Round 1" onChange={(e) => getQuestionType(e)} disabled={otherDisable} />
                                    <label htmlFor="round1">Round 1</label>
                                </div>
                                {/* <div className="me-2">
                                <input type="checkbox" id="pp" className="me-1" name="standard" value="Ponderous Puzzle" onChange={(e) => getQuestionType(e)} disabled={PPDisable} />
                                <label htmlFor="pp">Ponderous Puzzle</label>
                            </div> */}
                                <div className="me-2">
                                    <input type="checkbox" id="jj" className="me-1" name="standard" value="Jackpot" onChange={(e) => getQuestionType(e)} disabled={otherDisable} />
                                    <label htmlFor="jj">Jackpot</label>
                                </div>
                                <div className="me-2">
                                    <input type="checkbox" id="tie-break" className="me-1" name="standard" value="Tie-Break" onChange={(e) => getQuestionType(e)} disabled={otherDisable} />
                                    <label htmlFor="tie-break">Tie-Break</label>
                                </div>
                                {/* <div className="me-2">
                                <input type="checkbox" id="list" className="me-1" name="standard" value="The List" onChange={(e) => getQuestionType(e)} disabled={TLDisable} />
                                <label htmlFor="list">The List</label>
                            </div> */}
                                <div className="me-2">
                                    <input type="checkbox" id="lms" className="me-1" name="standard" value="Last Man Standing" onChange={(e) => getQuestionType(e)} disabled={otherDisable} />
                                    <label htmlFor="lms">Last Man Standing</label>
                                </div>
                                <div className="me-2">
                                    <input type="checkbox" id="multi" className="me-1" name="standard" value="Multimedia" onChange={(e) => getQuestionType(e)} disabled={otherDisable} />
                                    <label htmlFor="multi"> Multimedia</label>
                                </div>
                                <div className="">
                                    <input type="checkbox" id="cc" className="me-1" name="standard" value="Cryptic Clue" onChange={(e) => getQuestionType(e)} disabled={otherDisable} />
                                    <label htmlFor="cc"> Cryptic Clue</label>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>

                <div className="text-end">
                    <button className="btn btn-success" onClick={onSaveQuestion}>Save Question</button>
                </div>
            </div>
        )
    }
    return (
        <div id="main" className="withoutSideBarNav">
            <ModalComponent isShowModal={saveQuesModal.showModal} onHandleOkBtn={onClickOkBtnOfSaveBtnValidModal} text={saveQuesModal.message} icon={saveQuesModal.icon} />
            <div className="pagetitle">
                <h1>Create Question</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                        <li className="breadcrumb-item active">Standard</li>
                    </ol>
                </nav>
            </div>

            <Layout></Layout>
            <div className="card p-2">
                {showLoader ? <LoadingSpinner /> : showData()}
            </div>

        </div>

    );
}

export default CreateNewQuestion;