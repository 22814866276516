import { useEffect, useState } from "react";
// import {actionCreators} from '../../state/index';
// import {useSelector} from 'react-redux';

function RoundButton(props:any) {

  const [isActive, setIsActive] = useState(false);
  const [flag,setFlag] = useState(0);
    const handleClick = async (e:any) => {
        e.preventDefault();
        setFlag(1);
        if(isActive===true){
        setIsActive(false);
          props.decJJCount(props.id);
          return
        }
        if((await props.onJJCount(props.id))){
            setIsActive(true);
        }
 
      };

      useEffect(()=>{
      
      if(props.jjlist){
        if(props.jjlist.includes(props.id+1) && flag ===0){
          setIsActive(true);
        } else if(props.jjlist.length===0 || !props.jjlist.includes(props.id+1) ){
          setIsActive(false);
        }
      }
      
      },[props.jjlist])
    return ( <>
    
       <button className={ isActive ? "col-1 m-1 btn btn-sm btn-success" : (props.id!==0) ? "col-1 m-1 btn btn-sm btn-question-box secondary" :"col-1 m-1 btn btn-sm disabled" }  onClick={(e)=>handleClick(e)} id={props.id}>
      {props.title}
    </button>
   
    </> );
}

export default RoundButton;